@tailwind base;
@tailwind components;
@tailwind utilities;
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}


/* For IE, Edge and Firefox */

.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.flex_bt button {
    display: flex;
    white-space: nowrap;
}