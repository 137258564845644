.rond {
    align-items: center;
    column-gap: 10px;
    width: 220px;
}

.rond label {
    color: #fff;
}

.rond button {
    border-radius: 50px !important;
}