.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.data-deletion-page-logo {
  margin-bottom: 10px;
  margin-top: 10px;
  height: 100px;
  
}


.data-deletion-page-logo {
  margin-bottom: 10px;
  margin-top: 10px;
  height: 100px;
  
}



.data-deletion-box {
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  width: 100%;
  margin: auto;
}
.data-deletion-box>h1 {
  font-size: 20px;
  color: var(--text-black);
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  border-top: 1px solid #212529;
  border-bottom: 1px solid #212529;
  padding: 10px 0px;
}


.data-deletion {
  width: 476px;
  margin: auto;
  position: relative;
  z-index: 9;
  margin-bottom: 20px;
}

@media only screen and (min-width: 212px)and (max-width: 640px) {
  .headbx,
  .btn_fixed,
  .navigation_bx {
      width: 100%;
  }
  .whtbg {
      background-size: cover;
  }
  .loginbox {
      width: 100%;
  }
  .data-deletion {
      width: 100%;
  }
}

.bglog {
  width: 100%;
}


