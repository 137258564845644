
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #c5cae9;
  }
  
  .loader-box {
    width: 100%;
    height: 100%;
    background: rgb(45, 50, 62);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
  }
  
  .box-container {
    height: 15px;
    width: 105px;
    display: flex;
    position: relative;
  }
  .box-container .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    animation: move 500ms linear 0ms infinite;
    margin-right: 30px;
  }
  .box-container .circle:first-child {
    position: absolute;
    top: 0;
    left: 0;
    animation: grow 500ms linear 0ms infinite;
  }
  .box-container .circle:last-child {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    animation: grow 500ms linear 0s infinite reverse;
  }
  
  @keyframes grow {
    from {
      transform: scale(0, 0);
      opacity: 0;
    }
    to {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  @keyframes move {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(45px);
    }
  }