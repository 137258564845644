.formsstep {
    width: 80%;
    margin: auto;
}

.formsstep label,
.formsstep_n1 label {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.formsstep input,
.formsstep select,
.formsstep_n1 input,
.formsstep_n1 select {
    padding: 11px 10px;
    height: auto;
    background: #e8f0fe;
}

.formsstep .text-danger,
.formsstep_n1 .text-danger {
    font-size: 10px;
    margin-top: 2px;
}

.css-b31dej {
    padding: 0 !important;
}

.StepperContainer-0-2-1 {
    background: #e8f0fe;
}

.StepButton-d0-0-2-6,
.StepButton-d2-0-2-12,
.StepButton-d4-0-2-20,
.StepButton-d6-0-2-26,
.StepButton-d8-0-2-32,
.StepButton-d10-0-2-38,
.StepButton-0-2-4 {
    width: 3em !important;
    height: 3em !important;
    background-color: #fff !important;
}

.StepButtonContent-d1-0-2-7,
.StepButtonContent-d3-0-2-13,
.StepButtonContent-d5-0-2-21,
.StepButtonContent-d5-0-2-21,
.StepButtonContent-d9-0-2-33,
.StepButtonContent-d7-0-2-27,
.StepButtonContent-d11-0-2-39,
.StepButtonContent-d5-0-2-21.completed,
.StepButtonContent-d33-0-2-97,
.StepButtonContent-d35-0-2-103,
.StepButtonContent-d31-0-2-93,
.StepButtonContent-d9-0-2-31,
.StepButtonContent-d11-0-2-37 {
    font-size: 1.5rem !important;
    color: rgb(164 164 164) !important;
}

.StepButton-d0-0-2-6.active,
.StepButton-d2-0-2-12.active,
.StepButton-d4-0-2-20.active,
.StepButton-d6-0-2-26.active,
.StepButton-d8-0-2-32.active,
.StepButton-d10-0-2-38.active,
.StepButton-d8-0-2-30.active,
.StepButton-d10-0-2-36.active {
    background-color: rgb(26 123 197 / 66%) !important;
    scale: 1.2;
}

.StepMain-0-2-3>.active {
    background-color: rgb(26 123 197 / 66%) !important;
    scale: 1.2;
}

.StepMain-0-2-3>.active span svg path {
    stroke: #fff;
}

.StepMain-0-2-3>.active span .wht {
    fill: #fff;
}

.StepMain-0-2-3>.completed {
    background-color: rgba(141, 198, 147, 0.664) !important;
    scale: 1;
}

.StepMain-0-2-3>.completed span .wht {
    fill: #fff;
}

.StepMain-0-2-3>.completed span svg path {
    stroke: #fff;
}

.StepButtonContent-d1-0-2-7.active,
.StepButtonContent-d3-0-2-13.active,
.StepButtonContent-d5-0-2-21.active,
.StepButtonContent-d7-0-2-27.active,
.StepButtonContent-d9-0-2-33.active,
.StepButtonContent-d11-0-2-39.active,
.StepButtonContent-d9-0-2-31.active,
.StepButtonContent-d11-0-2-37.active {
    color: rgb(255, 255, 255) !important;
}

.Label-d1-0-2-11,
.Label-d3-0-2-19,
.Label-d5-0-2-25,
.Label-d7-0-2-31,
.Label-d9-0-2-37,
.Label-d11-0-2-43 {
    color: #000;
}

.css-sxix9q-MuiButtonBase-root-MuiButton-root,
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    border: 1px solid #007bff !important;
    color: #2035a1 !important;
    padding: 8px 30px !important;
}

.css-ke5b6m-MuiButtonBase-root-MuiButton-root {
    padding: 8px 30px !important;
}

.Connector-d9-0-2-41,
.ConnectorContainer-d6-0-2-34,
.ConnectorContainer-d4-0-2-28,
.ConnectorContainer-d2-0-2-22,
.ConnectorContainer-d0-0-2-16,
.Connector-d25-0-2-105,
.Connector-d23-0-2-99 {
    margin-top: 9px;
}

.borderbox_upl {
    border: 1px dashed #ddd;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
}

.borderbox_upl>h2 {
    font-size: 15px;
    font-weight: 500;
}

.imgcn {
    text-align: center;
    margin: auto;
    border: 1px solid #ddd;
    padding: 6px;
    border-radius: 5px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.borderb1 {
    border-bottom: 1px solid #ddd;
}

.blckbd>span {
    display: block;
    margin-bottom: 10px;
}

.css-lrbspc {
    padding: 0 !important;
}

.width50 {
    width: 50%;
    margin: auto;
}

.titletxt {
    font-size: 15px;
    font-weight: 500;
}