@-moz-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-o-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-ms-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-moz-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@-o-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@-ms-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@-moz-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-o-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-ms-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-moz-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-ms-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-o-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-ms-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}

@-o-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}

@keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}

@-moz-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}

@-o-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}

@-ms-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}

@-moz-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@-o-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@-ms-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@-moz-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-o-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-ms-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-moz-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-o-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-ms-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-moz-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-o-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-ms-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-ms-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-ms-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

@-o-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

@-ms-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

@-moz-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}

@-o-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}

@-ms-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}

@-moz-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}

@-o-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}

@-ms-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}

@keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}

@-o-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}

@-ms-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}

@keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}

@-moz-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}

@-o-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}

@-ms-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}

@keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}

@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-ms-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-ms-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-ms-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-o-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-ms-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-o-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-ms-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-o-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-ms-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-o-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-ms-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-o-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-ms-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-ms-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-ms-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-moz-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@-o-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@-ms-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@-moz-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-o-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-ms-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-moz-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-o-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-ms-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-moz-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-o-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-ms-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-moz-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-o-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-ms-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-moz-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-o-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-ms-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

@-moz-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-o-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-ms-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-moz-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@-ms-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-ms-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-ms-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-ms-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-ms-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-ms-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-ms-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-ms-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-ms-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-ms-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-o-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-ms-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}

@-moz-keyframes rollIn {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@-webkit-keyframes rollIn {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@-o-keyframes rollIn {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@-ms-keyframes rollIn {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes rollIn {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}

@-moz-keyframes rollOut {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}

@-webkit-keyframes rollOut {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}

@-o-keyframes rollOut {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}

@-ms-keyframes rollOut {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}

@keyframes rollOut {
  0% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
  --blue: #5e50f9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #e91e63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #0f1531;
  --blue: #5e50f9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #e91e63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #434a54;
  --gray-light: #aab2bd;
  --gray-lighter: #e8eff4;
  --gray-lightest: #e6e9ed;
  --black: #000000;
  --primary: #727cf5;
  --secondary: #7987a1;
  --success: #10b759;
  --info: #66d1d1;
  --warning: #fbbc06;
  --danger: #ff3366;
  --light: #ececec;
  --dark: #282f3a;
  --primary-muted: #b1cfec;
  --info-muted: #7ee5e5;
  --danger-muted: #f77eb9;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.875rem 0.9375rem;
  padding-bottom: 0.875rem 0.9375rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.19rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.875rem 0.9375rem;
  vertical-align: top;
  border-top: 1px solid #e8ebf1;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8ebf1;
}

.table tbody + tbody {
  border-top: 2px solid #e8ebf1;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e8ebf1;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e8ebf1;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eee;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #f9fafb;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d8dafc;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b6bbfa;
}

.table-hover .table-primary:hover {
  background-color: #c0c4fa;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #c0c4fa;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9dde5;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b9c1ce;
}

.table-hover .table-secondary:hover {
  background-color: #cacfdb;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cacfdb;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bcebd1;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #83daa9;
}

.table-hover .table-success:hover {
  background-color: #a8e5c4;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a8e5c4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d4f2f2;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #afe7e7;
}

.table-hover .table-info:hover {
  background-color: #c0ecec;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c0ecec;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #feecb9;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fddc7e;
}

.table-hover .table-warning:hover {
  background-color: #fee5a0;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fee5a0;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffc6d4;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ff95af;
}

.table-hover .table-danger:hover {
  background-color: #ffadc1;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ffadc1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafafa;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: whitesmoke;
}

.table-hover .table-light:hover {
  background-color: #ededed;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ededed;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c3c5c8;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8f9399;
}

.table-hover .table-dark:hover {
  background-color: #b6b8bc;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b6b8bc;
}

.table-primary-muted,
.table-primary-muted > th,
.table-primary-muted > td {
  background-color: #e9f2fa;
}

.table-primary-muted th,
.table-primary-muted td,
.table-primary-muted thead th,
.table-primary-muted tbody + tbody {
  border-color: #d6e6f5;
}

.table-hover .table-primary-muted:hover {
  background-color: #d4e6f5;
}

.table-hover .table-primary-muted:hover > td,
.table-hover .table-primary-muted:hover > th {
  background-color: #d4e6f5;
}

.table-info-muted,
.table-info-muted > th,
.table-info-muted > td {
  background-color: #dbf8f8;
}

.table-info-muted th,
.table-info-muted td,
.table-info-muted thead th,
.table-info-muted tbody + tbody {
  border-color: #bcf1f1;
}

.table-hover .table-info-muted:hover {
  background-color: #c6f4f4;
}

.table-hover .table-info-muted:hover > td,
.table-hover .table-info-muted:hover > th {
  background-color: #c6f4f4;
}

.table-danger-muted,
.table-danger-muted > th,
.table-danger-muted > td {
  background-color: #fddbeb;
}

.table-danger-muted th,
.table-danger-muted td,
.table-danger-muted thead th,
.table-danger-muted tbody + tbody {
  border-color: #fbbcdb;
}

.table-hover .table-danger-muted:hover {
  background-color: #fcc3de;
}

.table-hover .table-danger-muted:hover > td,
.table-hover .table-danger-muted:hover > th {
  background-color: #fcc3de;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e8ebf1;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control,
select,
.email-compose-fields .select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control,
  select,
  .email-compose-fields
    .select2-container--default
    .select2-selection--multiple,
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--single .select2-search__field,
  .typeahead,
  .tt-query,
  .tt-hint {
    transition: none;
  }
}

.form-control::-ms-expand,
select::-ms-expand,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple::-ms-expand,
.select2-container--default .select2-selection--single::-ms-expand,
.select2-container--default
  .select2-selection--single
  .select2-search__field::-ms-expand,
.typeahead::-ms-expand,
.tt-query::-ms-expand,
.tt-hint::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring,
select:-moz-focusring,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:-moz-focusring,
.select2-container--default .select2-selection--single:-moz-focusring,
.select2-container--default
  .select2-selection--single
  .select2-search__field:-moz-focusring,
.typeahead:-moz-focusring,
.tt-query:-moz-focusring,
.tt-hint:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus,
select:focus,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:focus,
.select2-container--default .select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder,
select::placeholder,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple::placeholder,
.select2-container--default .select2-selection--single::placeholder,
.select2-container--default
  .select2-selection--single
  .select2-search__field::placeholder,
.typeahead::placeholder,
.tt-query::placeholder,
.tt-hint::placeholder {
  color: #c9c8c8;
  opacity: 1;
}

.form-control:disabled,
select:disabled,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:disabled,
.select2-container--default .select2-selection--single:disabled,
.select2-container--default
  .select2-selection--single
  .select2-search__field:disabled,
.typeahead:disabled,
.tt-query:disabled,
.tt-hint:disabled,
.form-control[readonly],
select[readonly],
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple[readonly],
.select2-container--default .select2-selection--single[readonly],
.select2-container--default
  .select2-selection--single
  .select2-search__field[readonly],
.typeahead[readonly],
.tt-query[readonly],
.tt-hint[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
.email-compose-fields
  .select2-container--default
  input.select2-selection--multiple[type="date"],
.select2-container--default input.select2-selection--single[type="date"],
.select2-container--default
  .select2-selection--single
  input.select2-search__field[type="date"],
input.typeahead[type="date"],
input.tt-query[type="date"],
input.tt-hint[type="date"],
input[type="time"].form-control,
.email-compose-fields
  .select2-container--default
  input.select2-selection--multiple[type="time"],
.select2-container--default input.select2-selection--single[type="time"],
.select2-container--default
  .select2-selection--single
  input.select2-search__field[type="time"],
input.typeahead[type="time"],
input.tt-query[type="time"],
input.tt-hint[type="time"],
input[type="datetime-local"].form-control,
.email-compose-fields
  .select2-container--default
  input.select2-selection--multiple[type="datetime-local"],
.select2-container--default
  input.select2-selection--single[type="datetime-local"],
.select2-container--default
  .select2-selection--single
  input.select2-search__field[type="datetime-local"],
input.typeahead[type="datetime-local"],
input.tt-query[type="datetime-local"],
input.tt-hint[type="datetime-local"],
input[type="month"].form-control,
.email-compose-fields
  .select2-container--default
  input.select2-selection--multiple[type="month"],
.select2-container--default input.select2-selection--single[type="month"],
.select2-container--default
  .select2-selection--single
  input.select2-search__field[type="month"],
input.typeahead[type="month"],
input.tt-query[type="month"],
input.tt-hint[type="month"] {
  appearance: none;
}

select.form-control:focus::-ms-value,
select:focus::-ms-value,
.email-compose-fields
  .select2-container--default
  select.select2-selection--multiple:focus::-ms-value,
.select2-container--default select.select2-selection--single:focus::-ms-value,
.select2-container--default
  .select2-selection--single
  select.select2-search__field:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: calc(0.94rem + 1px);
  padding-bottom: calc(0.94rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.8rem;
  line-height: 2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.4rem 0.875rem;
  font-size: 0.8rem;
  line-height: 2;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.94rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select[size],
.email-compose-fields
  .select2-container--default
  select.select2-selection--multiple[size],
.select2-container--default select.select2-selection--single[size],
.select2-container--default
  .select2-selection--single
  select.select2-search__field[size],
select.form-control[multiple],
select[multiple],
.email-compose-fields
  .select2-container--default
  select.select2-selection--multiple[multiple],
.select2-container--default select.select2-selection--single[multiple],
.select2-container--default
  .select2-selection--single
  select.select2-search__field[multiple] {
  height: auto;
}

textarea.form-control,
.email-compose-fields
  .select2-container--default
  textarea.select2-selection--multiple,
.select2-container--default textarea.select2-selection--single,
.select2-container--default
  .select2-selection--single
  textarea.select2-search__field,
textarea.typeahead,
textarea.tt-query,
textarea.tt-hint {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #686868;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated select:valid,
.was-validated
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple:valid,
.email-compose-fields
  .select2-container--default
  .was-validated
  .select2-selection--multiple:valid,
.was-validated .select2-container--default .select2-selection--single:valid,
.select2-container--default .was-validated .select2-selection--single:valid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:valid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:valid,
.was-validated .typeahead:valid,
.was-validated .tt-query:valid,
.was-validated .tt-hint:valid,
.form-control.is-valid,
select.is-valid,
.email-compose-fields
  .select2-container--default
  .is-valid.select2-selection--multiple,
.select2-container--default .is-valid.select2-selection--single,
.select2-container--default
  .select2-selection--single
  .is-valid.select2-search__field,
.is-valid.typeahead,
.is-valid.tt-query,
.is-valid.tt-hint {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.was-validated select:valid:focus,
.was-validated
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple:valid:focus,
.email-compose-fields
  .select2-container--default
  .was-validated
  .select2-selection--multiple:valid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single:valid:focus,
.select2-container--default
  .was-validated
  .select2-selection--single:valid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:valid:focus,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:valid:focus,
.was-validated .typeahead:valid:focus,
.was-validated .tt-query:valid:focus,
.was-validated .tt-hint:valid:focus,
.form-control.is-valid:focus,
select.is-valid:focus,
.email-compose-fields
  .select2-container--default
  .is-valid.select2-selection--multiple:focus,
.select2-container--default .is-valid.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  .is-valid.select2-search__field:focus,
.is-valid.typeahead:focus,
.is-valid.tt-query:focus,
.is-valid.tt-hint:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated
  .email-compose-fields
  .select2-container--default
  textarea.select2-selection--multiple:valid,
.email-compose-fields
  .select2-container--default
  .was-validated
  textarea.select2-selection--multiple:valid,
.was-validated
  .select2-container--default
  textarea.select2-selection--single:valid,
.select2-container--default
  .was-validated
  textarea.select2-selection--single:valid,
.was-validated
  .select2-container--default
  .select2-selection--single
  textarea.select2-search__field:valid,
.select2-container--default
  .select2-selection--single
  .was-validated
  textarea.select2-search__field:valid,
.was-validated textarea.typeahead:valid,
.was-validated textarea.tt-query:valid,
.was-validated textarea.tt-hint:valid,
textarea.form-control.is-valid,
.email-compose-fields
  .select2-container--default
  textarea.is-valid.select2-selection--multiple,
.select2-container--default textarea.is-valid.select2-selection--single,
.select2-container--default
  .select2-selection--single
  textarea.is-valid.select2-search__field,
textarea.is-valid.typeahead,
textarea.is-valid.tt-query,
textarea.is-valid.tt-hint {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated select:invalid,
.was-validated
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple:invalid,
.email-compose-fields
  .select2-container--default
  .was-validated
  .select2-selection--multiple:invalid,
.was-validated .select2-container--default .select2-selection--single:invalid,
.select2-container--default .was-validated .select2-selection--single:invalid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:invalid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:invalid,
.was-validated .typeahead:invalid,
.was-validated .tt-query:invalid,
.was-validated .tt-hint:invalid,
.form-control.is-invalid,
select.is-invalid,
.email-compose-fields
  .select2-container--default
  .is-invalid.select2-selection--multiple,
.select2-container--default .is-invalid.select2-selection--single,
.select2-container--default
  .select2-selection--single
  .is-invalid.select2-search__field,
.is-invalid.typeahead,
.is-invalid.tt-query,
.is-invalid.tt-hint {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.was-validated select:invalid:focus,
.was-validated
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple:invalid:focus,
.email-compose-fields
  .select2-container--default
  .was-validated
  .select2-selection--multiple:invalid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single:invalid:focus,
.select2-container--default
  .was-validated
  .select2-selection--single:invalid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:invalid:focus,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:invalid:focus,
.was-validated .typeahead:invalid:focus,
.was-validated .tt-query:invalid:focus,
.was-validated .tt-hint:invalid:focus,
.form-control.is-invalid:focus,
select.is-invalid:focus,
.email-compose-fields
  .select2-container--default
  .is-invalid.select2-selection--multiple:focus,
.select2-container--default .is-invalid.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  .is-invalid.select2-search__field:focus,
.is-invalid.typeahead:focus,
.is-invalid.tt-query:focus,
.is-invalid.tt-hint:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated
  .email-compose-fields
  .select2-container--default
  textarea.select2-selection--multiple:invalid,
.email-compose-fields
  .select2-container--default
  .was-validated
  textarea.select2-selection--multiple:invalid,
.was-validated
  .select2-container--default
  textarea.select2-selection--single:invalid,
.select2-container--default
  .was-validated
  textarea.select2-selection--single:invalid,
.was-validated
  .select2-container--default
  .select2-selection--single
  textarea.select2-search__field:invalid,
.select2-container--default
  .select2-selection--single
  .was-validated
  textarea.select2-search__field:invalid,
.was-validated textarea.typeahead:invalid,
.was-validated textarea.tt-query:invalid,
.was-validated textarea.tt-hint:invalid,
textarea.form-control.is-invalid,
.email-compose-fields
  .select2-container--default
  textarea.is-invalid.select2-selection--multiple,
.select2-container--default textarea.is-invalid.select2-selection--single,
.select2-container--default
  .select2-selection--single
  textarea.is-invalid.select2-search__field,
textarea.is-invalid.typeahead,
textarea.is-invalid.tt-query,
textarea.is-invalid.tt-hint {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control,
  .form-inline select,
  .form-inline
    .email-compose-fields
    .select2-container--default
    .select2-selection--multiple,
  .email-compose-fields
    .select2-container--default
    .form-inline
    .select2-selection--multiple,
  .form-inline .select2-container--default .select2-selection--single,
  .select2-container--default .form-inline .select2-selection--single,
  .form-inline
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
  .select2-container--default
    .select2-selection--single
    .form-inline
    .select2-search__field,
  .form-inline .typeahead,
  .form-inline .tt-query,
  .form-inline .tt-hint {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn,
.fc .fc-button,
.swal2-modal .swal2-actions button,
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions .disabled a {
  display: inline-block;
  font-weight: 600;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn,
  .fc .fc-button,
  .swal2-modal .swal2-actions button,
  .wizard > .actions a,
  .wizard > .actions a:hover,
  .wizard > .actions .disabled a {
    transition: none;
  }
}

.btn:hover,
.fc .fc-button:hover,
.swal2-modal .swal2-actions button:hover,
.wizard > .actions a:hover {
  color: #000;
  text-decoration: none;
}

.btn:focus,
.fc .fc-button:focus,
.swal2-modal .swal2-actions button:focus,
.wizard > .actions a:focus,
.btn.focus,
.fc .focus.fc-button,
.swal2-modal .swal2-actions button.focus,
.wizard > .actions a.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.fc .disabled.fc-button,
.swal2-modal .swal2-actions button.disabled,
.wizard > .actions a.disabled,
.btn:disabled,
.fc .fc-button:disabled,
.swal2-modal .swal2-actions button:disabled,
.wizard > .actions a:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled),
.fc .fc-button:not(:disabled):not(.disabled),
.swal2-modal .swal2-actions button:not(:disabled):not(.disabled),
.wizard > .actions a:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
.fc a.disabled.fc-button,
.wizard > .actions a.disabled,
fieldset:disabled a.btn,
fieldset:disabled .fc a.fc-button,
.fc fieldset:disabled a.fc-button,
fieldset:disabled .wizard > .actions a,
.wizard > .actions fieldset:disabled a {
  pointer-events: none;
}

.btn-primary,
.swal2-modal .swal2-actions button.swal2-confirm,
.wizard > .actions a,
.wizard > .actions a:hover {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-primary:hover,
.swal2-modal .swal2-actions button.swal2-confirm:hover,
.wizard > .actions a:hover {
  color: #fff;
  background-color: #4e5bf2;
  border-color: #4250f2;
}

.btn-primary:focus,
.swal2-modal .swal2-actions button.swal2-confirm:focus,
.wizard > .actions a:focus,
.btn-primary.focus,
.swal2-modal .swal2-actions button.focus.swal2-confirm,
.wizard > .actions a.focus {
  color: #fff;
  background-color: #4e5bf2;
  border-color: #4250f2;
  box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
}

.btn-primary.disabled,
.swal2-modal .swal2-actions button.disabled.swal2-confirm,
.wizard > .actions a.disabled,
.btn-primary:disabled,
.swal2-modal .swal2-actions button.swal2-confirm:disabled,
.wizard > .actions a:disabled {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-primary:not(:disabled):not(.disabled):active,
.swal2-modal
  .swal2-actions
  button.swal2-confirm:not(:disabled):not(.disabled):active,
.wizard > .actions a:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.swal2-modal
  .swal2-actions
  button.swal2-confirm:not(:disabled):not(.disabled).active,
.wizard > .actions a:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.swal2-modal .swal2-actions .show > button.dropdown-toggle.swal2-confirm,
.wizard > .actions .show > a.dropdown-toggle {
  color: #fff;
  background-color: #4250f2;
  border-color: #3645f1;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.swal2-modal
  .swal2-actions
  button.swal2-confirm:not(:disabled):not(.disabled):active:focus,
.wizard > .actions a:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.swal2-modal
  .swal2-actions
  button.swal2-confirm:not(:disabled):not(.disabled).active:focus,
.wizard > .actions a:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.swal2-modal .swal2-actions .show > button.dropdown-toggle.swal2-confirm:focus,
.wizard > .actions .show > a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #64738f;
  border-color: #5f6d88;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #64738f;
  border-color: #5f6d88;
  box-shadow: 0 0 0 0.2rem rgba(141, 153, 175, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5f6d88;
  border-color: #5a6780;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 153, 175, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #10b759;
  border-color: #10b759;
}

.btn-success:hover {
  color: #fff;
  background-color: #0d9448;
  border-color: #0c8842;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #0d9448;
  border-color: #0c8842;
  box-shadow: 0 0 0 0.2rem rgba(52, 194, 114, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #10b759;
  border-color: #10b759;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0c8842;
  border-color: #0b7c3c;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 194, 114, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #66d1d1;
  border-color: #66d1d1;
}

.btn-info:hover {
  color: #212529;
  background-color: #49c8c8;
  border-color: #3fc5c5;
}

.btn-info:focus,
.btn-info.focus {
  color: #212529;
  background-color: #49c8c8;
  border-color: #3fc5c5;
  box-shadow: 0 0 0 0.2rem rgba(92, 183, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #66d1d1;
  border-color: #66d1d1;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #3fc5c5;
  border-color: #39bebe;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 183, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #fbbc06;
  border-color: #fbbc06;
}

.btn-warning:hover {
  color: #212529;
  background-color: #d7a103;
  border-color: #cb9703;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #d7a103;
  border-color: #cb9703;
  box-shadow: 0 0 0 0.2rem rgba(218, 165, 11, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #fbbc06;
  border-color: #fbbc06;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cb9703;
  border-color: #be8e03;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 165, 11, 0.5);
}

.btn-danger,
.swal2-modal .swal2-actions button.swal2-cancel {
  color: #fff;
  background-color: #ff3366;
  border-color: #ff3366;
}

.btn-danger:hover,
.swal2-modal .swal2-actions button.swal2-cancel:hover {
  color: #fff;
  background-color: #ff0d49;
  border-color: #ff0040;
}

.btn-danger:focus,
.swal2-modal .swal2-actions button.swal2-cancel:focus,
.btn-danger.focus,
.swal2-modal .swal2-actions button.focus.swal2-cancel {
  color: #fff;
  background-color: #ff0d49;
  border-color: #ff0040;
  box-shadow: 0 0 0 0.2rem rgba(255, 82, 125, 0.5);
}

.btn-danger.disabled,
.swal2-modal .swal2-actions button.disabled.swal2-cancel,
.btn-danger:disabled,
.swal2-modal .swal2-actions button.swal2-cancel:disabled {
  color: #fff;
  background-color: #ff3366;
  border-color: #ff3366;
}

.btn-danger:not(:disabled):not(.disabled):active,
.swal2-modal
  .swal2-actions
  button.swal2-cancel:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.swal2-modal
  .swal2-actions
  button.swal2-cancel:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle,
.swal2-modal .swal2-actions .show > button.dropdown-toggle.swal2-cancel {
  color: #fff;
  background-color: #ff0040;
  border-color: #f2003d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.swal2-modal
  .swal2-actions
  button.swal2-cancel:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.swal2-modal
  .swal2-actions
  button.swal2-cancel:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus,
.swal2-modal .swal2-actions .show > button.dropdown-toggle.swal2-cancel:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 82, 125, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-light:hover {
  color: #212529;
  background-color: #d9d9d9;
  border-color: lightgray;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #d9d9d9;
  border-color: lightgray;
  box-shadow: 0 0 0 0.2rem rgba(206, 206, 207, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: lightgray;
  border-color: #cccccc;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 206, 207, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #282f3a;
  border-color: #282f3a;
}

.btn-dark:hover {
  color: #fff;
  background-color: #181d23;
  border-color: #13171c;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #181d23;
  border-color: #13171c;
  box-shadow: 0 0 0 0.2rem rgba(72, 78, 88, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #282f3a;
  border-color: #282f3a;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #13171c;
  border-color: #0e1014;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 78, 88, 0.5);
}

.btn-primary-muted {
  color: #212529;
  background-color: #b1cfec;
  border-color: #b1cfec;
}

.btn-primary-muted:hover {
  color: #212529;
  background-color: #92bce5;
  border-color: #88b6e2;
}

.btn-primary-muted:focus,
.btn-primary-muted.focus {
  color: #212529;
  background-color: #92bce5;
  border-color: #88b6e2;
  box-shadow: 0 0 0 0.2rem rgba(155, 182, 207, 0.5);
}

.btn-primary-muted.disabled,
.btn-primary-muted:disabled {
  color: #212529;
  background-color: #b1cfec;
  border-color: #b1cfec;
}

.btn-primary-muted:not(:disabled):not(.disabled):active,
.btn-primary-muted:not(:disabled):not(.disabled).active,
.show > .btn-primary-muted.dropdown-toggle {
  color: #212529;
  background-color: #88b6e2;
  border-color: #7eafe0;
}

.btn-primary-muted:not(:disabled):not(.disabled):active:focus,
.btn-primary-muted:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-muted.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 182, 207, 0.5);
}

.btn-info-muted {
  color: #212529;
  background-color: #7ee5e5;
  border-color: #7ee5e5;
}

.btn-info-muted:hover {
  color: #212529;
  background-color: #5edfdf;
  border-color: #54dcdc;
}

.btn-info-muted:focus,
.btn-info-muted.focus {
  color: #212529;
  background-color: #5edfdf;
  border-color: #54dcdc;
  box-shadow: 0 0 0 0.2rem rgba(112, 200, 201, 0.5);
}

.btn-info-muted.disabled,
.btn-info-muted:disabled {
  color: #212529;
  background-color: #7ee5e5;
  border-color: #7ee5e5;
}

.btn-info-muted:not(:disabled):not(.disabled):active,
.btn-info-muted:not(:disabled):not(.disabled).active,
.show > .btn-info-muted.dropdown-toggle {
  color: #212529;
  background-color: #54dcdc;
  border-color: #49dada;
}

.btn-info-muted:not(:disabled):not(.disabled):active:focus,
.btn-info-muted:not(:disabled):not(.disabled).active:focus,
.show > .btn-info-muted.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 200, 201, 0.5);
}

.btn-danger-muted {
  color: #212529;
  background-color: #f77eb9;
  border-color: #f77eb9;
}

.btn-danger-muted:hover {
  color: #fff;
  background-color: #f55aa5;
  border-color: #f44e9f;
}

.btn-danger-muted:focus,
.btn-danger-muted.focus {
  color: #fff;
  background-color: #f55aa5;
  border-color: #f44e9f;
  box-shadow: 0 0 0 0.2rem rgba(215, 113, 163, 0.5);
}

.btn-danger-muted.disabled,
.btn-danger-muted:disabled {
  color: #212529;
  background-color: #f77eb9;
  border-color: #f77eb9;
}

.btn-danger-muted:not(:disabled):not(.disabled):active,
.btn-danger-muted:not(:disabled):not(.disabled).active,
.show > .btn-danger-muted.dropdown-toggle {
  color: #fff;
  background-color: #f44e9f;
  border-color: #f34298;
}

.btn-danger-muted:not(:disabled):not(.disabled):active:focus,
.btn-danger-muted:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger-muted.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 113, 163, 0.5);
}

.btn-outline-primary,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .fc-button,
.wizard > .actions .disabled a {
  color: #727cf5;
  border-color: #727cf5;
}

.btn-outline-primary:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .fc-button:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .fc-button:hover,
.wizard > .actions .disabled a:hover {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-outline-primary:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .fc-button:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .fc-button:focus,
.wizard > .actions .disabled a:focus,
.btn-outline-primary.focus,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .focus.fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .focus.fc-button,
.wizard > .actions .disabled a.focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.btn-outline-primary.disabled,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .disabled.fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled.fc-button,
.wizard > .actions .disabled a.disabled,
.btn-outline-primary:disabled,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .fc-button:disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button:disabled,
.wizard > .actions .disabled a:disabled {
  color: #727cf5;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled):active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled):active,
.wizard > .actions .disabled a:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled).active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled).active,
.wizard > .actions .disabled a:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button,
.wizard > .actions .disabled .show > a.dropdown-toggle {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled):active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled):active:focus,
.wizard > .actions .disabled a:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled).active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button:not(:disabled):not(.disabled).active:focus,
.wizard > .actions .disabled a:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button:focus,
.wizard > .actions .disabled .show > a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.btn-outline-secondary {
  color: #7987a1;
  border-color: #7987a1;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #7987a1;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}

.btn-outline-success {
  color: #10b759;
  border-color: #10b759;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #10b759;
  border-color: #10b759;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #10b759;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #10b759;
  border-color: #10b759;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.5);
}

.btn-outline-info {
  color: #66d1d1;
  border-color: #66d1d1;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #66d1d1;
  border-color: #66d1d1;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 209, 209, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #66d1d1;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #66d1d1;
  border-color: #66d1d1;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 209, 209, 0.5);
}

.btn-outline-warning {
  color: #fbbc06;
  border-color: #fbbc06;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #fbbc06;
  border-color: #fbbc06;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 188, 6, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fbbc06;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fbbc06;
  border-color: #fbbc06;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 188, 6, 0.5);
}

.btn-outline-danger {
  color: #ff3366;
  border-color: #ff3366;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff3366;
  border-color: #ff3366;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ff3366;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff3366;
  border-color: #ff3366;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.5);
}

.btn-outline-light {
  color: #ececec;
  border-color: #ececec;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 236, 236, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ececec;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 236, 236, 0.5);
}

.btn-outline-dark {
  color: #282f3a;
  border-color: #282f3a;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #282f3a;
  border-color: #282f3a;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 47, 58, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #282f3a;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #282f3a;
  border-color: #282f3a;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 47, 58, 0.5);
}

.btn-outline-primary-muted,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active {
  color: #b1cfec;
  border-color: #b1cfec;
}

.btn-outline-primary-muted:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active:hover {
  color: #212529;
  background-color: #b1cfec;
  border-color: #b1cfec;
}

.btn-outline-primary-muted:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active:focus,
.btn-outline-primary-muted.focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .focus.fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .focus.fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .focus.fc-button.fc-state-active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .focus.fc-button.fc-state-active {
  box-shadow: 0 0 0 0.2rem rgba(177, 207, 236, 0.5);
}

.btn-outline-primary-muted.disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled.fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled.fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled.fc-button.fc-state-active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled.fc-button.fc-state-active,
.btn-outline-primary-muted:disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled:disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled:disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active:disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active:disabled {
  color: #b1cfec;
  background-color: transparent;
}

.btn-outline-primary-muted:not(:disabled):not(.disabled):active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled):active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled):active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled):active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled):active,
.btn-outline-primary-muted:not(:disabled):not(.disabled).active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled).active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled).active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled).active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-muted.dropdown-toggle,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-active {
  color: #212529;
  background-color: #b1cfec;
  border-color: #b1cfec;
}

.btn-outline-primary-muted:not(:disabled):not(.disabled):active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled):active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled):active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled):active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary-muted:not(:disabled):not(.disabled).active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled).active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-disabled:not(:disabled):not(.disabled).active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled).active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button.fc-state-active:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-muted.dropdown-toggle:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-disabled:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-disabled:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-active:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .show
  > .dropdown-toggle.fc-button.fc-state-active:focus {
  box-shadow: 0 0 0 0.2rem rgba(177, 207, 236, 0.5);
}

.btn-outline-info-muted {
  color: #7ee5e5;
  border-color: #7ee5e5;
}

.btn-outline-info-muted:hover {
  color: #212529;
  background-color: #7ee5e5;
  border-color: #7ee5e5;
}

.btn-outline-info-muted:focus,
.btn-outline-info-muted.focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 229, 229, 0.5);
}

.btn-outline-info-muted.disabled,
.btn-outline-info-muted:disabled {
  color: #7ee5e5;
  background-color: transparent;
}

.btn-outline-info-muted:not(:disabled):not(.disabled):active,
.btn-outline-info-muted:not(:disabled):not(.disabled).active,
.show > .btn-outline-info-muted.dropdown-toggle {
  color: #212529;
  background-color: #7ee5e5;
  border-color: #7ee5e5;
}

.btn-outline-info-muted:not(:disabled):not(.disabled):active:focus,
.btn-outline-info-muted:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info-muted.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 229, 229, 0.5);
}

.btn-outline-danger-muted {
  color: #f77eb9;
  border-color: #f77eb9;
}

.btn-outline-danger-muted:hover {
  color: #212529;
  background-color: #f77eb9;
  border-color: #f77eb9;
}

.btn-outline-danger-muted:focus,
.btn-outline-danger-muted.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 126, 185, 0.5);
}

.btn-outline-danger-muted.disabled,
.btn-outline-danger-muted:disabled {
  color: #f77eb9;
  background-color: transparent;
}

.btn-outline-danger-muted:not(:disabled):not(.disabled):active,
.btn-outline-danger-muted:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger-muted.dropdown-toggle {
  color: #212529;
  background-color: #f77eb9;
  border-color: #f77eb9;
}

.btn-outline-danger-muted:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger-muted:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger-muted.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 126, 185, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn,
.fc .btn-group-lg > .fc-button,
.swal2-modal .swal2-actions .btn-group-lg > button,
.wizard > .actions .btn-group-lg > a,
.wizard > .actions .disabled .btn-group-lg > a {
  padding: 0.8rem 1.5rem;
  font-size: 1.3rem;
  line-height: 1;
  border-radius: 0.1875rem;
}

.btn-sm,
.btn-group-sm > .btn,
.fc .btn-group-sm > .fc-button,
.swal2-modal .swal2-actions .btn-group-sm > button,
.wizard > .actions .btn-group-sm > a,
.wizard > .actions .disabled .btn-group-sm > a {
  padding: 0.4rem 0.85rem;
  font-size: 0.75rem;
  line-height: 0.85rem;
  border-radius: 0.1875rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8125rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f8f8f8;
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e8ebf1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #eaeaf1;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #000;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #000;
}

.btn-group,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .btn,
.fc .btn-group > .fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .fc-button,
.swal2-modal .swal2-actions .btn-group > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button,
.wizard > .actions .btn-group > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a,
.wizard > .actions .disabled .btn-group > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a,
.btn-group-vertical > .btn,
.fc .btn-group-vertical > .fc-button,
.swal2-modal .swal2-actions .btn-group-vertical > button,
.wizard > .actions .btn-group-vertical > a,
.wizard > .actions .disabled .btn-group-vertical > a {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .btn:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .btn:hover,
.fc .btn-group > .fc-button:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .fc-button:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .fc-button:hover,
.swal2-modal .swal2-actions .btn-group > button:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:hover,
.wizard > .actions .btn-group > a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:hover,
.wizard > .actions .disabled .btn-group > a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:hover,
.btn-group-vertical > .btn:hover,
.fc .btn-group-vertical > .fc-button:hover,
.swal2-modal .swal2-actions .btn-group-vertical > button:hover,
.wizard > .actions .btn-group-vertical > a:hover,
.wizard > .actions .disabled .btn-group-vertical > a:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .btn:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .btn:focus,
.fc .btn-group > .fc-button:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .fc-button:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .fc-button:focus,
.swal2-modal .swal2-actions .btn-group > button:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:focus,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:focus,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:focus,
.wizard > .actions .btn-group > a:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:focus,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:focus,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:focus,
.wizard > .actions .disabled .btn-group > a:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:focus,
.btn-group > .btn:active,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .btn:active,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .btn:active,
.fc .btn-group > .fc-button:active,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .fc-button:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:active,
.swal2-modal .swal2-actions .btn-group > button:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:active,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:active,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:active,
.wizard > .actions .btn-group > a:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:active,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:active,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:active,
.wizard > .actions .disabled .btn-group > a:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:active,
.btn-group > .btn.active,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .btn.active,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group > .btn.active,
.fc .btn-group > .active.fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group > .active.fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .active.fc-button,
.swal2-modal .swal2-actions .btn-group > button.active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button.active,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button.active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button.active,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button.active,
.wizard > .actions .btn-group > a.active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a.active,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a.active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a.active,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a.active,
.wizard > .actions .disabled .btn-group > a.active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a.active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a.active,
.btn-group-vertical > .btn:focus,
.fc .btn-group-vertical > .fc-button:focus,
.swal2-modal .swal2-actions .btn-group-vertical > button:focus,
.wizard > .actions .btn-group-vertical > a:focus,
.wizard > .actions .disabled .btn-group-vertical > a:focus,
.btn-group-vertical > .btn:active,
.fc .btn-group-vertical > .fc-button:active,
.swal2-modal .swal2-actions .btn-group-vertical > button:active,
.wizard > .actions .btn-group-vertical > a:active,
.wizard > .actions .disabled .btn-group-vertical > a:active,
.btn-group-vertical > .btn.active,
.fc .btn-group-vertical > .active.fc-button,
.swal2-modal .swal2-actions .btn-group-vertical > button.active,
.wizard > .actions .btn-group-vertical > a.active,
.wizard > .actions .disabled .btn-group-vertical > a.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:first-child),
.fc .btn-group > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:first-child),
.swal2-modal .swal2-actions .btn-group > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:first-child),
.wizard > .actions .btn-group > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:first-child),
.wizard > .actions .disabled .btn-group > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.btn-group > .btn-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.fc .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .btn-group
  > button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.wizard > .actions .btn-group > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .disabled
  .btn-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc .btn-group > .btn-group:not(:last-child) > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.swal2-modal .swal2-actions .btn-group > .btn-group:not(:last-child) > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.wizard > .actions .btn-group > .btn-group:not(:last-child) > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard > .actions .disabled .btn-group > .btn-group:not(:last-child) > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:first-child),
.fc .btn-group > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:first-child),
.swal2-modal .swal2-actions .btn-group > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:first-child),
.wizard > .actions .btn-group > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:first-child),
.wizard > .actions .disabled .btn-group > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc .btn-group > .btn-group:not(:first-child) > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.swal2-modal .swal2-actions .btn-group > .btn-group:not(:first-child) > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.wizard > .actions .btn-group > .btn-group:not(:first-child) > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard > .actions .disabled .btn-group > .btn-group:not(:first-child) > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.fc .btn-group-sm > .fc-button + .dropdown-toggle-split,
.swal2-modal .swal2-actions .btn-group-sm > button + .dropdown-toggle-split,
.wizard > .actions .btn-group-sm > a + .dropdown-toggle-split,
.wizard > .actions .disabled .btn-group-sm > a + .dropdown-toggle-split {
  padding-right: 0.6375rem;
  padding-left: 0.6375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.fc .btn-group-lg > .fc-button + .dropdown-toggle-split,
.swal2-modal .swal2-actions .btn-group-lg > button + .dropdown-toggle-split,
.wizard > .actions .btn-group-lg > a + .dropdown-toggle-split,
.wizard > .actions .disabled .btn-group-lg > a + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.fc .btn-group-vertical > .fc-button,
.swal2-modal .swal2-actions .btn-group-vertical > button,
.wizard > .actions .btn-group-vertical > a,
.wizard > .actions .disabled .btn-group-vertical > a,
.btn-group-vertical > .btn-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.fc .btn-group-vertical > .fc-button:not(:first-child),
.swal2-modal .swal2-actions .btn-group-vertical > button:not(:first-child),
.wizard > .actions .btn-group-vertical > a:not(:first-child),
.wizard > .actions .disabled .btn-group-vertical > a:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.fc .btn-group-vertical > .fc-button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .btn-group-vertical
  > button:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .btn-group-vertical
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .disabled
  .btn-group-vertical
  > a:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .btn,
.fc .btn-group-vertical > .btn-group:not(:last-child) > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .fc-button,
.swal2-modal
  .swal2-actions
  .btn-group-vertical
  > .btn-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > button,
.wizard > .actions .btn-group-vertical > .btn-group:not(:last-child) > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .disabled
  .btn-group-vertical
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.fc .btn-group-vertical > .fc-button:not(:first-child),
.swal2-modal .swal2-actions .btn-group-vertical > button:not(:first-child),
.wizard > .actions .btn-group-vertical > a:not(:first-child),
.wizard > .actions .disabled .btn-group-vertical > a:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .btn,
.fc .btn-group-vertical > .btn-group:not(:first-child) > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .fc-button,
.swal2-modal
  .swal2-actions
  .btn-group-vertical
  > .btn-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > button,
.wizard > .actions .btn-group-vertical > .btn-group:not(:first-child) > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .disabled
  .btn-group-vertical
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.fc .btn-group-toggle > .fc-button,
.swal2-modal .swal2-actions .btn-group-toggle > button,
.wizard > .actions .btn-group-toggle > a,
.wizard > .actions .disabled .btn-group-toggle > a,
.btn-group-toggle > .btn-group > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > .btn,
.fc .btn-group-toggle > .btn-group > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > .fc-button,
.swal2-modal .swal2-actions .btn-group-toggle > .btn-group > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .fc-button-group
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .fc-button-group
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > button,
.wizard > .actions .btn-group-toggle > .btn-group > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > a,
.wizard > .actions .disabled .btn-group-toggle > .btn-group > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .fc-button-group
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .fc-button-group
  > a {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.fc .btn-group-toggle > .fc-button input[type="radio"],
.swal2-modal .swal2-actions .btn-group-toggle > button input[type="radio"],
.wizard > .actions .btn-group-toggle > a input[type="radio"],
.wizard > .actions .disabled .btn-group-toggle > a input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.fc .btn-group-toggle > .fc-button input[type="checkbox"],
.swal2-modal .swal2-actions .btn-group-toggle > button input[type="checkbox"],
.wizard > .actions .btn-group-toggle > a input[type="checkbox"],
.wizard > .actions .disabled .btn-group-toggle > a input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > .btn
  input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > .btn
  input[type="radio"],
.fc .btn-group-toggle > .btn-group > .fc-button input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > .fc-button
  input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > .fc-button
  input[type="radio"],
.swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .btn-group
  > button
  input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="radio"],
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="radio"],
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="radio"],
.wizard > .actions .btn-group-toggle > .btn-group > a input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="radio"],
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="radio"],
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="radio"],
.wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .btn-group
  > a
  input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="radio"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > .btn
  input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > .btn
  input[type="checkbox"],
.fc .btn-group-toggle > .btn-group > .fc-button input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > .fc-button
  input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > .fc-button
  input[type="checkbox"],
.swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .btn-group
  > button
  input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="checkbox"],
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="checkbox"],
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > button
  input[type="checkbox"],
.wizard > .actions .btn-group-toggle > .btn-group > a input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="checkbox"],
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="checkbox"],
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="checkbox"],
.wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .btn-group
  > a
  input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="checkbox"],
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > select,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple,
.select2-container--default .input-group > .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field,
.input-group > .typeahead,
.input-group > .tt-query,
.input-group > .tt-hint,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > select + .form-control,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .form-control,
.select2-container--default
  .input-group
  > .select2-selection--single
  + .form-control,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .form-control,
.input-group > .typeahead + .form-control,
.input-group > .tt-query + .form-control,
.input-group > .tt-hint + .form-control,
.input-group > .form-control + select,
.input-group > select + select,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + select,
.select2-container--default .input-group > .select2-selection--single + select,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + select,
.input-group > .typeahead + select,
.input-group > .tt-query + select,
.input-group > .tt-hint + select,
.email-compose-fields
  .select2-container--default
  .input-group
  > .form-control
  + .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .input-group
  > select
  + .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--single
  + .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .input-group
  > .typeahead
  + .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .input-group
  > .tt-query
  + .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .input-group
  > .tt-hint
  + .select2-selection--multiple,
.select2-container--default
  .input-group
  > .form-control
  + .select2-selection--single,
.select2-container--default .input-group > select + .select2-selection--single,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .select2-selection--single,
.select2-container--default
  .input-group
  > .select2-selection--single
  + .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .select2-selection--single,
.select2-container--default
  .input-group
  > .typeahead
  + .select2-selection--single,
.select2-container--default
  .input-group
  > .tt-query
  + .select2-selection--single,
.select2-container--default
  .input-group
  > .tt-hint
  + .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group
  > .form-control
  + .select2-search__field,
.select2-container--default
  .select2-selection--single
  .input-group
  > select
  + .select2-search__field,
.email-compose-fields
  .select2-container--default
  .select2-selection--single
  .input-group
  > .select2-selection--multiple
  + .select2-search__field,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-selection--single
  + .select2-search__field,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .select2-search__field,
.select2-container--default
  .select2-selection--single
  .input-group
  > .typeahead
  + .select2-search__field,
.select2-container--default
  .select2-selection--single
  .input-group
  > .tt-query
  + .select2-search__field,
.select2-container--default
  .select2-selection--single
  .input-group
  > .tt-hint
  + .select2-search__field,
.input-group > .form-control + .typeahead,
.input-group > select + .typeahead,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .typeahead,
.select2-container--default
  .input-group
  > .select2-selection--single
  + .typeahead,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .typeahead,
.input-group > .typeahead + .typeahead,
.input-group > .tt-query + .typeahead,
.input-group > .tt-hint + .typeahead,
.input-group > .form-control + .tt-query,
.input-group > select + .tt-query,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .tt-query,
.select2-container--default
  .input-group
  > .select2-selection--single
  + .tt-query,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .tt-query,
.input-group > .typeahead + .tt-query,
.input-group > .tt-query + .tt-query,
.input-group > .tt-hint + .tt-query,
.input-group > .form-control + .tt-hint,
.input-group > select + .tt-hint,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .tt-hint,
.select2-container--default
  .input-group
  > .select2-selection--single
  + .tt-hint,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .tt-hint,
.input-group > .typeahead + .tt-hint,
.input-group > .tt-query + .tt-hint,
.input-group > .tt-hint + .tt-hint,
.input-group > .form-control + .custom-select,
.input-group > select + .custom-select,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .custom-select,
.select2-container--default
  .input-group
  > .select2-selection--single
  + .custom-select,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .custom-select,
.input-group > .typeahead + .custom-select,
.input-group > .tt-query + .custom-select,
.input-group > .tt-hint + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > select + .custom-file,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple
  + .custom-file,
.select2-container--default
  .input-group
  > .select2-selection--single
  + .custom-file,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field
  + .custom-file,
.input-group > .typeahead + .custom-file,
.input-group > .tt-query + .custom-file,
.input-group > .tt-hint + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + select,
.email-compose-fields
  .select2-container--default
  .input-group
  > .form-control-plaintext
  + .select2-selection--multiple,
.select2-container--default
  .input-group
  > .form-control-plaintext
  + .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group
  > .form-control-plaintext
  + .select2-search__field,
.input-group > .form-control-plaintext + .typeahead,
.input-group > .form-control-plaintext + .tt-query,
.input-group > .form-control-plaintext + .tt-hint,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + select,
.email-compose-fields
  .select2-container--default
  .input-group
  > .custom-select
  + .select2-selection--multiple,
.select2-container--default
  .input-group
  > .custom-select
  + .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group
  > .custom-select
  + .select2-search__field,
.input-group > .custom-select + .typeahead,
.input-group > .custom-select + .tt-query,
.input-group > .custom-select + .tt-hint,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + select,
.email-compose-fields
  .select2-container--default
  .input-group
  > .custom-file
  + .select2-selection--multiple,
.select2-container--default
  .input-group
  > .custom-file
  + .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group
  > .custom-file
  + .select2-search__field,
.input-group > .custom-file + .typeahead,
.input-group > .custom-file + .tt-query,
.input-group > .custom-file + .tt-hint,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > select:focus,
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple:focus,
.select2-container--default .input-group > .select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:focus,
.input-group > .typeahead:focus,
.input-group > .tt-query:focus,
.input-group > .tt-hint:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > select:not(:last-child),
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple:not(:last-child),
.select2-container--default
  .input-group
  > .select2-selection--single:not(:last-child),
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:last-child),
.input-group > .typeahead:not(:last-child),
.input-group > .tt-query:not(:last-child),
.input-group > .tt-hint:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > select:not(:first-child),
.email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple:not(:first-child),
.select2-container--default
  .input-group
  > .select2-selection--single:not(:first-child),
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:first-child),
.input-group > .typeahead:not(:first-child),
.input-group > .tt-query:not(:first-child),
.input-group > .tt-hint:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .fc .fc-button,
.fc .input-group-prepend .fc-button,
.input-group-prepend .swal2-modal .swal2-actions button,
.swal2-modal .swal2-actions .input-group-prepend button,
.input-group-prepend .wizard > .actions a,
.wizard > .actions .input-group-prepend a,
.input-group-append .btn,
.input-group-append .fc .fc-button,
.fc .input-group-append .fc-button,
.input-group-append .swal2-modal .swal2-actions button,
.swal2-modal .swal2-actions .input-group-append button,
.input-group-append .wizard > .actions a,
.wizard > .actions .input-group-append a {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-prepend .fc .fc-button:focus,
.fc .input-group-prepend .fc-button:focus,
.input-group-prepend .swal2-modal .swal2-actions button:focus,
.swal2-modal .swal2-actions .input-group-prepend button:focus,
.input-group-prepend .wizard > .actions a:focus,
.wizard > .actions .input-group-prepend a:focus,
.input-group-append .btn:focus,
.input-group-append .fc .fc-button:focus,
.fc .input-group-append .fc-button:focus,
.input-group-append .swal2-modal .swal2-actions button:focus,
.swal2-modal .swal2-actions .input-group-append button:focus,
.input-group-append .wizard > .actions a:focus,
.wizard > .actions .input-group-append a:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .fc .fc-button + .btn,
.fc .input-group-prepend .fc-button + .btn,
.input-group-prepend .swal2-modal .swal2-actions button + .btn,
.swal2-modal .swal2-actions .input-group-prepend button + .btn,
.input-group-prepend .wizard > .actions a + .btn,
.wizard > .actions .input-group-prepend a + .btn,
.input-group-prepend .wizard > .actions .disabled a + .btn,
.input-group-prepend .fc .btn + .fc-button,
.fc .input-group-prepend .btn + .fc-button,
.input-group-prepend .fc .fc-button + .fc-button,
.fc .input-group-prepend .fc-button + .fc-button,
.input-group-prepend .swal2-modal .swal2-actions .fc button + .fc-button,
.fc .input-group-prepend .swal2-modal .swal2-actions button + .fc-button,
.swal2-modal .swal2-actions .input-group-prepend .fc button + .fc-button,
.fc .swal2-modal .swal2-actions .input-group-prepend button + .fc-button,
.input-group-prepend .wizard > .actions .fc a + .fc-button,
.fc .input-group-prepend .wizard > .actions a + .fc-button,
.wizard > .actions .input-group-prepend .fc a + .fc-button,
.fc .wizard > .actions .input-group-prepend a + .fc-button,
.fc .input-group-prepend .wizard > .actions .disabled a + .fc-button,
.input-group-prepend .swal2-modal .swal2-actions .btn + button,
.swal2-modal .swal2-actions .input-group-prepend .btn + button,
.input-group-prepend .fc .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .input-group-prepend .fc .fc-button + button,
.fc .input-group-prepend .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .fc .input-group-prepend .fc-button + button,
.input-group-prepend .swal2-modal .swal2-actions button + button,
.swal2-modal .swal2-actions .input-group-prepend button + button,
.input-group-prepend .wizard > .actions .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .input-group-prepend .wizard > .actions a + button,
.wizard > .actions .input-group-prepend .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .wizard > .actions .input-group-prepend a + button,
.swal2-modal
  .swal2-actions
  .input-group-prepend
  .wizard
  > .actions
  .disabled
  a
  + button,
.input-group-prepend .wizard > .actions .btn + a,
.wizard > .actions .input-group-prepend .btn + a,
.input-group-prepend .fc .wizard > .actions .fc-button + a,
.wizard > .actions .input-group-prepend .fc .fc-button + a,
.fc .input-group-prepend .wizard > .actions .fc-button + a,
.wizard > .actions .fc .input-group-prepend .fc-button + a,
.input-group-prepend .swal2-modal .swal2-actions .wizard > .actions button + a,
.wizard > .actions .input-group-prepend .swal2-modal .swal2-actions button + a,
.swal2-modal .swal2-actions .input-group-prepend .wizard > .actions button + a,
.wizard > .actions .swal2-modal .swal2-actions .input-group-prepend button + a,
.input-group-prepend .wizard > .actions a + a,
.wizard > .actions .input-group-prepend a + a,
.input-group-prepend .wizard > .actions .disabled .btn + a,
.input-group-prepend .fc .wizard > .actions .disabled .fc-button + a,
.fc .input-group-prepend .wizard > .actions .disabled .fc-button + a,
.input-group-prepend
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.swal2-modal
  .swal2-actions
  .input-group-prepend
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard > .actions .input-group-prepend .disabled a + a,
.input-group-prepend .wizard > .actions .disabled a + a,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .fc .fc-button + .input-group-text,
.fc .input-group-prepend .fc-button + .input-group-text,
.input-group-prepend .swal2-modal .swal2-actions button + .input-group-text,
.swal2-modal .swal2-actions .input-group-prepend button + .input-group-text,
.input-group-prepend .wizard > .actions a + .input-group-text,
.wizard > .actions .input-group-prepend a + .input-group-text,
.input-group-prepend .wizard > .actions .disabled a + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .fc .input-group-text + .fc-button,
.fc .input-group-prepend .input-group-text + .fc-button,
.input-group-prepend .swal2-modal .swal2-actions .input-group-text + button,
.swal2-modal .swal2-actions .input-group-prepend .input-group-text + button,
.input-group-prepend .wizard > .actions .input-group-text + a,
.wizard > .actions .input-group-prepend .input-group-text + a,
.input-group-prepend .wizard > .actions .disabled .input-group-text + a,
.input-group-append .btn + .btn,
.input-group-append .fc .fc-button + .btn,
.fc .input-group-append .fc-button + .btn,
.input-group-append .swal2-modal .swal2-actions button + .btn,
.swal2-modal .swal2-actions .input-group-append button + .btn,
.input-group-append .wizard > .actions a + .btn,
.wizard > .actions .input-group-append a + .btn,
.input-group-append .wizard > .actions .disabled a + .btn,
.input-group-append .fc .btn + .fc-button,
.fc .input-group-append .btn + .fc-button,
.input-group-append .fc .fc-button + .fc-button,
.fc .input-group-append .fc-button + .fc-button,
.input-group-append .swal2-modal .swal2-actions .fc button + .fc-button,
.fc .input-group-append .swal2-modal .swal2-actions button + .fc-button,
.swal2-modal .swal2-actions .input-group-append .fc button + .fc-button,
.fc .swal2-modal .swal2-actions .input-group-append button + .fc-button,
.input-group-append .wizard > .actions .fc a + .fc-button,
.fc .input-group-append .wizard > .actions a + .fc-button,
.wizard > .actions .input-group-append .fc a + .fc-button,
.fc .wizard > .actions .input-group-append a + .fc-button,
.fc .input-group-append .wizard > .actions .disabled a + .fc-button,
.input-group-append .swal2-modal .swal2-actions .btn + button,
.swal2-modal .swal2-actions .input-group-append .btn + button,
.input-group-append .fc .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .input-group-append .fc .fc-button + button,
.fc .input-group-append .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .fc .input-group-append .fc-button + button,
.input-group-append .swal2-modal .swal2-actions button + button,
.swal2-modal .swal2-actions .input-group-append button + button,
.input-group-append .wizard > .actions .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .input-group-append .wizard > .actions a + button,
.wizard > .actions .input-group-append .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .wizard > .actions .input-group-append a + button,
.swal2-modal
  .swal2-actions
  .input-group-append
  .wizard
  > .actions
  .disabled
  a
  + button,
.input-group-append .wizard > .actions .btn + a,
.wizard > .actions .input-group-append .btn + a,
.input-group-append .fc .wizard > .actions .fc-button + a,
.wizard > .actions .input-group-append .fc .fc-button + a,
.fc .input-group-append .wizard > .actions .fc-button + a,
.wizard > .actions .fc .input-group-append .fc-button + a,
.input-group-append .swal2-modal .swal2-actions .wizard > .actions button + a,
.wizard > .actions .input-group-append .swal2-modal .swal2-actions button + a,
.swal2-modal .swal2-actions .input-group-append .wizard > .actions button + a,
.wizard > .actions .swal2-modal .swal2-actions .input-group-append button + a,
.input-group-append .wizard > .actions a + a,
.wizard > .actions .input-group-append a + a,
.input-group-append .wizard > .actions .disabled .btn + a,
.input-group-append .fc .wizard > .actions .disabled .fc-button + a,
.fc .input-group-append .wizard > .actions .disabled .fc-button + a,
.input-group-append
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.swal2-modal
  .swal2-actions
  .input-group-append
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard > .actions .input-group-append .disabled a + a,
.input-group-append .wizard > .actions .disabled a + a,
.input-group-append .btn + .input-group-text,
.input-group-append .fc .fc-button + .input-group-text,
.fc .input-group-append .fc-button + .input-group-text,
.input-group-append .swal2-modal .swal2-actions button + .input-group-text,
.swal2-modal .swal2-actions .input-group-append button + .input-group-text,
.input-group-append .wizard > .actions a + .input-group-text,
.wizard > .actions .input-group-append a + .input-group-text,
.input-group-append .wizard > .actions .disabled a + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .fc .input-group-text + .fc-button,
.fc .input-group-append .input-group-text + .fc-button,
.input-group-append .swal2-modal .swal2-actions .input-group-text + button,
.swal2-modal .swal2-actions .input-group-append .input-group-text + button,
.input-group-append .wizard > .actions .input-group-text + a,
.wizard > .actions .input-group-append .input-group-text + a,
.input-group-append .wizard > .actions .disabled .input-group-text + a {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(114, 124, 245, 0.08);
  border: 1px solid #ced4da;
  border-radius: 2px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > select:not(textarea),
.email-compose-fields
  .select2-container--default
  .input-group-lg
  > .select2-selection--multiple:not(textarea),
.select2-container--default
  .input-group-lg
  > .select2-selection--single:not(textarea),
.select2-container--default
  .select2-selection--single
  .input-group-lg
  > .select2-search__field:not(textarea),
.input-group-lg > .typeahead:not(textarea),
.input-group-lg > .tt-query:not(textarea),
.input-group-lg > .tt-hint:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > select,
.email-compose-fields
  .select2-container--default
  .input-group-lg
  > .select2-selection--multiple,
.select2-container--default .input-group-lg > .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group-lg
  > .select2-search__field,
.input-group-lg > .typeahead,
.input-group-lg > .tt-query,
.input-group-lg > .tt-hint,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.fc .input-group-lg > .input-group-prepend > .fc-button,
.swal2-modal .swal2-actions .input-group-lg > .input-group-prepend > button,
.wizard > .actions .input-group-lg > .input-group-prepend > a,
.wizard > .actions .disabled .input-group-lg > .input-group-prepend > a,
.input-group-lg > .input-group-append > .btn,
.fc .input-group-lg > .input-group-append > .fc-button,
.swal2-modal .swal2-actions .input-group-lg > .input-group-append > button,
.wizard > .actions .input-group-lg > .input-group-append > a,
.wizard > .actions .disabled .input-group-lg > .input-group-append > a {
  padding: 0.94rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > select:not(textarea),
.email-compose-fields
  .select2-container--default
  .input-group-sm
  > .select2-selection--multiple:not(textarea),
.select2-container--default
  .input-group-sm
  > .select2-selection--single:not(textarea),
.select2-container--default
  .select2-selection--single
  .input-group-sm
  > .select2-search__field:not(textarea),
.input-group-sm > .typeahead:not(textarea),
.input-group-sm > .tt-query:not(textarea),
.input-group-sm > .tt-hint:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > select,
.email-compose-fields
  .select2-container--default
  .input-group-sm
  > .select2-selection--multiple,
.select2-container--default .input-group-sm > .select2-selection--single,
.select2-container--default
  .select2-selection--single
  .input-group-sm
  > .select2-search__field,
.input-group-sm > .typeahead,
.input-group-sm > .tt-query,
.input-group-sm > .tt-hint,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.fc .input-group-sm > .input-group-prepend > .fc-button,
.swal2-modal .swal2-actions .input-group-sm > .input-group-prepend > button,
.wizard > .actions .input-group-sm > .input-group-prepend > a,
.wizard > .actions .disabled .input-group-sm > .input-group-prepend > a,
.input-group-sm > .input-group-append > .btn,
.fc .input-group-sm > .input-group-append > .fc-button,
.swal2-modal .swal2-actions .input-group-sm > .input-group-append > button,
.wizard > .actions .input-group-sm > .input-group-append > a,
.wizard > .actions .disabled .input-group-sm > .input-group-append > a {
  padding: 0.4rem 0.875rem;
  font-size: 0.8rem;
  line-height: 2;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.fc .input-group > .input-group-prepend > .fc-button,
.swal2-modal .swal2-actions .input-group > .input-group-prepend > button,
.wizard > .actions .input-group > .input-group-prepend > a,
.wizard > .actions .disabled .input-group > .input-group-prepend > a,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.fc .input-group > .input-group-append:not(:last-child) > .fc-button,
.swal2-modal
  .swal2-actions
  .input-group
  > .input-group-append:not(:last-child)
  > button,
.wizard > .actions .input-group > .input-group-append:not(:last-child) > a,
.wizard
  > .actions
  .disabled
  .input-group
  > .input-group-append:not(:last-child)
  > a,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .input-group
  > .input-group-append:last-child
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .input-group
  > .input-group-append:last-child
  > button:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .input-group
  > .input-group-append:last-child
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .disabled
  .input-group
  > .input-group-append:last-child
  > a:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.fc .input-group > .input-group-append > .fc-button,
.swal2-modal .swal2-actions .input-group > .input-group-append > button,
.wizard > .actions .input-group > .input-group-append > a,
.wizard > .actions .disabled .input-group > .input-group-append > a,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.fc .input-group > .input-group-prepend:not(:first-child) > .fc-button,
.swal2-modal
  .swal2-actions
  .input-group
  > .input-group-prepend:not(:first-child)
  > button,
.wizard > .actions .input-group > .input-group-prepend:not(:first-child) > a,
.wizard
  > .actions
  .disabled
  .input-group
  > .input-group-prepend:not(:first-child)
  > a,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.fc
  .input-group
  > .input-group-prepend:first-child
  > .fc-button:not(:first-child),
.swal2-modal
  .swal2-actions
  .input-group
  > .input-group-prepend:first-child
  > button:not(:first-child),
.wizard
  > .actions
  .input-group
  > .input-group-prepend:first-child
  > a:not(:first-child),
.wizard
  > .actions
  .disabled
  .input-group
  > .input-group-prepend:first-child
  > a:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #ebedf2;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #ebedf2 #ebedf2 #ebedf2;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: #ffffff;
  border-color: #ebedf2 #ebedf2 #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f2f4f9;
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 0.875rem;
}

.card-subtitle {
  margin-top: -0.4375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.875rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #f2f4f9;
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.875rem 1.5rem;
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #f2f4f9;
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.875rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.56rem 1.13rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #495057;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 4px 5px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge,
.fc .fc-button .badge,
.swal2-modal .swal2-actions button .badge,
.wizard > .actions a .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #727cf5;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #4250f2;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #7987a1;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #5f6d88;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #10b759;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #0c8842;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #66d1d1;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #212529;
  background-color: #3fc5c5;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 209, 209, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fbbc06;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #cb9703;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 188, 6, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ff3366;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #ff0040;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 102, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #ececec;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: lightgray;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(236, 236, 236, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #282f3a;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #13171c;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 47, 58, 0.5);
}

.badge-primary-muted {
  color: #212529;
  background-color: #b1cfec;
}

a.badge-primary-muted:hover,
a.badge-primary-muted:focus {
  color: #212529;
  background-color: #88b6e2;
}

a.badge-primary-muted:focus,
a.badge-primary-muted.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(177, 207, 236, 0.5);
}

.badge-info-muted {
  color: #212529;
  background-color: #7ee5e5;
}

a.badge-info-muted:hover,
a.badge-info-muted:focus {
  color: #212529;
  background-color: #54dcdc;
}

a.badge-info-muted:focus,
a.badge-info-muted.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(126, 229, 229, 0.5);
}

.badge-danger-muted {
  color: #212529;
  background-color: #f77eb9;
}

a.badge-danger-muted:hover,
a.badge-danger-muted:focus {
  color: #212529;
  background-color: #f44e9f;
}

a.badge-danger-muted:focus,
a.badge-danger-muted.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 126, 185, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #3b407f;
  background-color: #e3e5fd;
  border-color: #d8dafc;
}

.alert-primary hr {
  border-top-color: #c0c4fa;
}

.alert-primary .alert-link {
  color: #2b2e5c;
}

.alert-secondary {
  color: #3f4654;
  background-color: #e4e7ec;
  border-color: #d9dde5;
}

.alert-secondary hr {
  border-top-color: #cacfdb;
}

.alert-secondary .alert-link {
  color: #292e37;
}

.alert-success {
  color: #085f2e;
  background-color: #cff1de;
  border-color: #bcebd1;
}

.alert-success hr {
  border-top-color: #a8e5c4;
}

.alert-success .alert-link {
  color: #043017;
}

.alert-info {
  color: #356d6d;
  background-color: #e0f6f6;
  border-color: #d4f2f2;
}

.alert-info hr {
  border-top-color: #c0ecec;
}

.alert-info .alert-link {
  color: #244b4b;
}

.alert-warning {
  color: #836203;
  background-color: #fef2cd;
  border-color: #feecb9;
}

.alert-warning hr {
  border-top-color: #fee5a0;
}

.alert-warning .alert-link {
  color: #513d02;
}

.alert-danger {
  color: #851b35;
  background-color: #ffd6e0;
  border-color: #ffc6d4;
}

.alert-danger hr {
  border-top-color: #ffadc1;
}

.alert-danger .alert-link {
  color: #5b1224;
}

.alert-light {
  color: #7b7b7b;
  background-color: #fbfbfb;
  border-color: #fafafa;
}

.alert-light hr {
  border-top-color: #ededed;
}

.alert-light .alert-link {
  color: #626262;
}

.alert-dark {
  color: #15181e;
  background-color: #d4d5d8;
  border-color: #c3c5c8;
}

.alert-dark hr {
  border-top-color: #b6b8bc;
}

.alert-dark .alert-link {
  color: black;
}

.alert-primary-muted {
  color: #5c6c7b;
  background-color: #eff5fb;
  border-color: #e9f2fa;
}

.alert-primary-muted hr {
  border-top-color: #d4e6f5;
}

.alert-primary-muted .alert-link {
  color: #46525e;
}

.alert-info-muted {
  color: #427777;
  background-color: #e5fafa;
  border-color: #dbf8f8;
}

.alert-info-muted hr {
  border-top-color: #c6f4f4;
}

.alert-info-muted .alert-link {
  color: #305656;
}

.alert-danger-muted {
  color: #804260;
  background-color: #fde5f1;
  border-color: #fddbeb;
}

.alert-danger-muted hr {
  border-top-color: #fcc3de;
}

.alert-danger-muted .alert-link {
  color: #5e3147;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #3b407f;
  background-color: #d8dafc;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #3b407f;
  background-color: #c0c4fa;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #3b407f;
  border-color: #3b407f;
}

.list-group-item-secondary {
  color: #3f4654;
  background-color: #d9dde5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #3f4654;
  background-color: #cacfdb;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #3f4654;
  border-color: #3f4654;
}

.list-group-item-success {
  color: #085f2e;
  background-color: #bcebd1;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #085f2e;
  background-color: #a8e5c4;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #085f2e;
  border-color: #085f2e;
}

.list-group-item-info {
  color: #356d6d;
  background-color: #d4f2f2;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #356d6d;
  background-color: #c0ecec;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #356d6d;
  border-color: #356d6d;
}

.list-group-item-warning {
  color: #836203;
  background-color: #feecb9;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #836203;
  background-color: #fee5a0;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #836203;
  border-color: #836203;
}

.list-group-item-danger {
  color: #851b35;
  background-color: #ffc6d4;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #851b35;
  background-color: #ffadc1;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #851b35;
  border-color: #851b35;
}

.list-group-item-light {
  color: #7b7b7b;
  background-color: #fafafa;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #7b7b7b;
  background-color: #ededed;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #7b7b7b;
  border-color: #7b7b7b;
}

.list-group-item-dark {
  color: #15181e;
  background-color: #c3c5c8;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #15181e;
  background-color: #b6b8bc;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #15181e;
  border-color: #15181e;
}

.list-group-item-primary-muted {
  color: #5c6c7b;
  background-color: #e9f2fa;
}

.list-group-item-primary-muted.list-group-item-action:hover,
.list-group-item-primary-muted.list-group-item-action:focus {
  color: #5c6c7b;
  background-color: #d4e6f5;
}

.list-group-item-primary-muted.list-group-item-action.active {
  color: #ffffff;
  background-color: #5c6c7b;
  border-color: #5c6c7b;
}

.list-group-item-info-muted {
  color: #427777;
  background-color: #dbf8f8;
}

.list-group-item-info-muted.list-group-item-action:hover,
.list-group-item-info-muted.list-group-item-action:focus {
  color: #427777;
  background-color: #c6f4f4;
}

.list-group-item-info-muted.list-group-item-action.active {
  color: #ffffff;
  background-color: #427777;
  border-color: #427777;
}

.list-group-item-danger-muted {
  color: #804260;
  background-color: #fddbeb;
}

.list-group-item-danger-muted.list-group-item-action:hover,
.list-group-item-danger-muted.list-group-item-action:focus {
  color: #804260;
  background-color: #fcc3de;
}

.list-group-item-danger-muted.list-group-item-action.active {
  color: #ffffff;
  background-color: #804260;
  border-color: #804260;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.4s ease;
  transform: scale(0.8);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 20px);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 20px);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 20px);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 20px);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e8ebf1;
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e8ebf1;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -25px -26px -25px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.937rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.687rem;
  border-top: 1px solid #e8ebf1;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 60px);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 60px);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 60px);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 60px);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #727cf5 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #4250f2 !important;
}

.bg-secondary {
  background-color: #7987a1 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5f6d88 !important;
}

.bg-success {
  background-color: #10b759 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0c8842 !important;
}

.bg-info {
  background-color: #66d1d1 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3fc5c5 !important;
}

.bg-warning {
  background-color: #fbbc06 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cb9703 !important;
}

.bg-danger {
  background-color: #ff3366 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff0040 !important;
}

.bg-light {
  background-color: #ececec !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: lightgray !important;
}

.bg-dark {
  background-color: #282f3a !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #13171c !important;
}

.bg-primary-muted {
  background-color: #b1cfec !important;
}

a.bg-primary-muted:hover,
a.bg-primary-muted:focus,
button.bg-primary-muted:hover,
button.bg-primary-muted:focus {
  background-color: #88b6e2 !important;
}

.bg-info-muted {
  background-color: #7ee5e5 !important;
}

a.bg-info-muted:hover,
a.bg-info-muted:focus,
button.bg-info-muted:hover,
button.bg-info-muted:focus {
  background-color: #54dcdc !important;
}

.bg-danger-muted {
  background-color: #f77eb9 !important;
}

a.bg-danger-muted:hover,
a.bg-danger-muted:focus,
button.bg-danger-muted:hover,
button.bg-danger-muted:focus {
  background-color: #f44e9f !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border,
.dropify-wrapper,
.dropzone {
  border: 1px solid #e8ebf1 !important;
}

.border-top {
  border-top: 1px solid #e8ebf1 !important;
}

.border-right {
  border-right: 1px solid #e8ebf1 !important;
}

.border-bottom {
  border-bottom: 1px solid #e8ebf1 !important;
}

.border-left {
  border-left: 1px solid #e8ebf1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #727cf5 !important;
}

.border-secondary {
  border-color: #7987a1 !important;
}

.border-success {
  border-color: #10b759 !important;
}

.border-info {
  border-color: #66d1d1 !important;
}

.border-warning {
  border-color: #fbbc06 !important;
}

.border-danger,
.swal2-modal .swal2-actions button.swal2-cancel {
  border-color: #ff3366 !important;
}

.border-light {
  border-color: #ececec !important;
}

.border-dark {
  border-color: #282f3a !important;
}

.border-primary-muted {
  border-color: #b1cfec !important;
}

.border-info-muted {
  border-color: #7ee5e5 !important;
}

.border-danger-muted {
  border-color: #f77eb9 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none,
.navbar .search-form {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex,
.data-icon-card-primary .background-icon,
.data-icon-card-secondary .background-icon,
.data-icon-card-success .background-icon,
.data-icon-card-info .background-icon,
.data-icon-card-warning .background-icon,
.data-icon-card-danger .background-icon,
.data-icon-card-light .background-icon,
.data-icon-card-dark .background-icon,
.data-icon-card-primary-muted .background-icon,
.data-icon-card-info-muted .background-icon,
.data-icon-card-danger-muted .background-icon,
.swal2-modal {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex,
  .navbar .search-form {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end,
.data-icon-card-primary .background-icon,
.data-icon-card-secondary .background-icon,
.data-icon-card-success .background-icon,
.data-icon-card-info .background-icon,
.data-icon-card-warning .background-icon,
.data-icon-card-danger .background-icon,
.data-icon-card-light .background-icon,
.data-icon-card-dark .background-icon,
.data-icon-card-primary-muted .background-icon,
.data-icon-card-info-muted .background-icon,
.data-icon-card-danger-muted .background-icon {
  justify-content: flex-end !important;
}

.justify-content-center,
.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center,
.navbar .search-form {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end,
  .dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100,
.dataTables_wrapper.dt-bootstrap4 .dataTables_length select {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-60 {
  height: 60% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.btn-toolbar .btn-group + .btn-group,
.btn-toolbar
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  + .btn-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-toolbar
  .fc-button-group
  + .btn-group,
.btn-toolbar
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  + .btn-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-toolbar
  .fc-button-group
  + .btn-group,
.btn-toolbar
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  + .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-toolbar
  .btn-group
  + .fc-button-group,
.btn-toolbar
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  + .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-toolbar
  .fc-button-group
  + .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-toolbar
  .fc-left
  .fc-button-group
  + .fc-button-group,
.btn-toolbar
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  + .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-toolbar
  .btn-group
  + .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-toolbar
  .fc-right
  .fc-button-group
  + .fc-button-group,
.btn-toolbar
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  + .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-toolbar
  .fc-button-group
  + .fc-button-group,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.dataTables_wrapper div.dataTables_paginate ul.pagination,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .dataTables_wrapper div.dataTables_paginate ul.pagination,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #727cf5 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #2b3af0 !important;
}

.text-secondary {
  color: #7987a1 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #556179 !important;
}

.text-success {
  color: #10b759 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #0a7137 !important;
}

.text-info {
  color: #66d1d1 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #36b4b4 !important;
}

.text-warning {
  color: #fbbc06 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #b28503 !important;
}

.text-danger {
  color: #ff3366 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e60039 !important;
}

.text-light {
  color: #ececec !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #c6c6c6 !important;
}

.text-dark {
  color: #282f3a !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #090a0d !important;
}

.text-primary-muted {
  color: #b1cfec !important;
}

a.text-primary-muted:hover,
a.text-primary-muted:focus {
  color: #73a9dd !important;
}

.text-info-muted {
  color: #7ee5e5 !important;
}

a.text-info-muted:hover,
a.text-info-muted:focus {
  color: #3ed8d8 !important;
}

.text-danger-muted {
  color: #f77eb9 !important;
}

a.text-danger-muted:hover,
a.text-danger-muted:focus {
  color: #f33692 !important;
}

.text-body {
  color: #000 !important;
}

.text-muted,
.dropzone.dz-clickable .dz-message *,
.flot-text .flot-x-axis > div,
.flot-text .flot-x-axis .flot-tick-label,
.flot-text .flot-y-axis > div,
.flot-text .flot-y-axis .flot-tick-label,
.editor-toolbar a {
  color: #686868 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e8ebf1;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e8ebf1;
  }
}
/* Animation mixins */

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0px, 0);
  }
}

.dropdownAnimation,
.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -ms-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOUt;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

.infinite-spin,
.settings-sidebar .sidebar-body .settings-sidebar-toggler svg {
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pulse,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item
  .nav-link
  .indicator
  .circle::before {
  animation-name: pulse;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

@keyframes pulse {
  0% {
    opacity: 1;
    width: 7px;
    height: 7px;
    left: 0;
    top: 0;
  }
  95% {
    opacity: 0.1;
    left: -10.5px;
    top: -10.5px;
    width: 28px;
    height: 28px;
  }
  100% {
    opacity: 0;
    width: 7px;
    height: 7px;
    left: 0;
    top: 0;
  }
}
/* Miscellaneous Mixins */

.wd-5 {
  width: 5px;
}

.wd-5p {
  width: 5%;
}

.mx-wd-5p {
  max-width: 5%;
}

.mn-wd-5p {
  min-width: 5%;
}

.wd-5-f {
  width: 5px !important;
}

.wd-5p-f {
  width: 5% !important;
}

.mx-wd-5p-f {
  max-width: 5% !important;
}

.mn-wd-5p-f {
  min-width: 5% !important;
}

.wd-10 {
  width: 10px;
}

.wd-10p {
  width: 10%;
}

.mx-wd-10p {
  max-width: 10%;
}

.mn-wd-10p {
  min-width: 10%;
}

.wd-10-f {
  width: 10px !important;
}

.wd-10p-f {
  width: 10% !important;
}

.mx-wd-10p-f {
  max-width: 10% !important;
}

.mn-wd-10p-f {
  min-width: 10% !important;
}

.wd-15 {
  width: 15px;
}

.wd-15p {
  width: 15%;
}

.mx-wd-15p {
  max-width: 15%;
}

.mn-wd-15p {
  min-width: 15%;
}

.wd-15-f {
  width: 15px !important;
}

.wd-15p-f {
  width: 15% !important;
}

.mx-wd-15p-f {
  max-width: 15% !important;
}

.mn-wd-15p-f {
  min-width: 15% !important;
}

.wd-20 {
  width: 20px;
}

.wd-20p {
  width: 20%;
}

.mx-wd-20p {
  max-width: 20%;
}

.mn-wd-20p {
  min-width: 20%;
}

.wd-20-f {
  width: 20px !important;
}

.wd-20p-f {
  width: 20% !important;
}

.mx-wd-20p-f {
  max-width: 20% !important;
}

.mn-wd-20p-f {
  min-width: 20% !important;
}

.wd-25 {
  width: 25px;
}

.wd-25p {
  width: 25%;
}

.mx-wd-25p {
  max-width: 25%;
}

.mn-wd-25p {
  min-width: 25%;
}

.wd-25-f {
  width: 25px !important;
}

.wd-25p-f {
  width: 25% !important;
}

.mx-wd-25p-f {
  max-width: 25% !important;
}

.mn-wd-25p-f {
  min-width: 25% !important;
}

.wd-30 {
  width: 30px;
}

.wd-30p {
  width: 30%;
}

.mx-wd-30p {
  max-width: 30%;
}

.mn-wd-30p {
  min-width: 30%;
}

.wd-30-f {
  width: 30px !important;
}

.wd-30p-f {
  width: 30% !important;
}

.mx-wd-30p-f {
  max-width: 30% !important;
}

.mn-wd-30p-f {
  min-width: 30% !important;
}

.wd-35 {
  width: 35px;
}

.wd-35p {
  width: 35%;
}

.mx-wd-35p {
  max-width: 35%;
}

.mn-wd-35p {
  min-width: 35%;
}

.wd-35-f {
  width: 35px !important;
}

.wd-35p-f {
  width: 35% !important;
}

.mx-wd-35p-f {
  max-width: 35% !important;
}

.mn-wd-35p-f {
  min-width: 35% !important;
}

.wd-40 {
  width: 40px;
}

.wd-40p {
  width: 40%;
}

.mx-wd-40p {
  max-width: 40%;
}

.mn-wd-40p {
  min-width: 40%;
}

.wd-40-f {
  width: 40px !important;
}

.wd-40p-f {
  width: 40% !important;
}

.mx-wd-40p-f {
  max-width: 40% !important;
}

.mn-wd-40p-f {
  min-width: 40% !important;
}

.wd-45 {
  width: 45px;
}

.wd-45p {
  width: 45%;
}

.mx-wd-45p {
  max-width: 45%;
}

.mn-wd-45p {
  min-width: 45%;
}

.wd-45-f {
  width: 45px !important;
}

.wd-45p-f {
  width: 45% !important;
}

.mx-wd-45p-f {
  max-width: 45% !important;
}

.mn-wd-45p-f {
  min-width: 45% !important;
}

.wd-50 {
  width: 50px;
}

.wd-50p {
  width: 50%;
}

.mx-wd-50p {
  max-width: 50%;
}

.mn-wd-50p {
  min-width: 50%;
}

.wd-50-f {
  width: 50px !important;
}

.wd-50p-f {
  width: 50% !important;
}

.mx-wd-50p-f {
  max-width: 50% !important;
}

.mn-wd-50p-f {
  min-width: 50% !important;
}

.wd-55 {
  width: 55px;
}

.wd-55p {
  width: 55%;
}

.mx-wd-55p {
  max-width: 55%;
}

.mn-wd-55p {
  min-width: 55%;
}

.wd-55-f {
  width: 55px !important;
}

.wd-55p-f {
  width: 55% !important;
}

.mx-wd-55p-f {
  max-width: 55% !important;
}

.mn-wd-55p-f {
  min-width: 55% !important;
}

.wd-60 {
  width: 60px;
}

.wd-60p {
  width: 60%;
}

.mx-wd-60p {
  max-width: 60%;
}

.mn-wd-60p {
  min-width: 60%;
}

.wd-60-f {
  width: 60px !important;
}

.wd-60p-f {
  width: 60% !important;
}

.mx-wd-60p-f {
  max-width: 60% !important;
}

.mn-wd-60p-f {
  min-width: 60% !important;
}

.wd-65 {
  width: 65px;
}

.wd-65p {
  width: 65%;
}

.mx-wd-65p {
  max-width: 65%;
}

.mn-wd-65p {
  min-width: 65%;
}

.wd-65-f {
  width: 65px !important;
}

.wd-65p-f {
  width: 65% !important;
}

.mx-wd-65p-f {
  max-width: 65% !important;
}

.mn-wd-65p-f {
  min-width: 65% !important;
}

.wd-70 {
  width: 70px;
}

.wd-70p {
  width: 70%;
}

.mx-wd-70p {
  max-width: 70%;
}

.mn-wd-70p {
  min-width: 70%;
}

.wd-70-f {
  width: 70px !important;
}

.wd-70p-f {
  width: 70% !important;
}

.mx-wd-70p-f {
  max-width: 70% !important;
}

.mn-wd-70p-f {
  min-width: 70% !important;
}

.wd-75 {
  width: 75px;
}

.wd-75p {
  width: 75%;
}

.mx-wd-75p {
  max-width: 75%;
}

.mn-wd-75p {
  min-width: 75%;
}

.wd-75-f {
  width: 75px !important;
}

.wd-75p-f {
  width: 75% !important;
}

.mx-wd-75p-f {
  max-width: 75% !important;
}

.mn-wd-75p-f {
  min-width: 75% !important;
}

.wd-80 {
  width: 80px;
}

.wd-80p {
  width: 80%;
}

.mx-wd-80p {
  max-width: 80%;
}

.mn-wd-80p {
  min-width: 80%;
}

.wd-80-f {
  width: 80px !important;
}

.wd-80p-f {
  width: 80% !important;
}

.mx-wd-80p-f {
  max-width: 80% !important;
}

.mn-wd-80p-f {
  min-width: 80% !important;
}

.wd-85 {
  width: 85px;
}

.wd-85p {
  width: 85%;
}

.mx-wd-85p {
  max-width: 85%;
}

.mn-wd-85p {
  min-width: 85%;
}

.wd-85-f {
  width: 85px !important;
}

.wd-85p-f {
  width: 85% !important;
}

.mx-wd-85p-f {
  max-width: 85% !important;
}

.mn-wd-85p-f {
  min-width: 85% !important;
}

.wd-90 {
  width: 90px;
}

.wd-90p {
  width: 90%;
}

.mx-wd-90p {
  max-width: 90%;
}

.mn-wd-90p {
  min-width: 90%;
}

.wd-90-f {
  width: 90px !important;
}

.wd-90p-f {
  width: 90% !important;
}

.mx-wd-90p-f {
  max-width: 90% !important;
}

.mn-wd-90p-f {
  min-width: 90% !important;
}

.wd-95 {
  width: 95px;
}

.wd-95p {
  width: 95%;
}

.mx-wd-95p {
  max-width: 95%;
}

.mn-wd-95p {
  min-width: 95%;
}

.wd-95-f {
  width: 95px !important;
}

.wd-95p-f {
  width: 95% !important;
}

.mx-wd-95p-f {
  max-width: 95% !important;
}

.mn-wd-95p-f {
  min-width: 95% !important;
}

.wd-100 {
  width: 100px;
}

.wd-100p {
  width: 100%;
}

.mx-wd-100p {
  max-width: 100%;
}

.mn-wd-100p {
  min-width: 100%;
}

.wd-100-f {
  width: 100px !important;
}

.wd-100p-f {
  width: 100% !important;
}

.mx-wd-100p-f {
  max-width: 100% !important;
}

.mn-wd-100p-f {
  min-width: 100% !important;
}

.wd-150 {
  width: 150px;
}

.wd-150-f {
  width: 150px !important;
}

.wd-200 {
  width: 200px;
}

.wd-200-f {
  width: 200px !important;
}

.wd-250 {
  width: 250px;
}

.wd-250-f {
  width: 250px !important;
}

.wd-300 {
  width: 300px;
}

.wd-300-f {
  width: 300px !important;
}

.wd-350 {
  width: 350px;
}

.wd-350-f {
  width: 350px !important;
}

.wd-400 {
  width: 400px;
}

.wd-400-f {
  width: 400px !important;
}

.wd-450 {
  width: 450px;
}

.wd-450-f {
  width: 450px !important;
}

.wd-500 {
  width: 500px;
}

.wd-500-f {
  width: 500px !important;
}

.wd-550 {
  width: 550px;
}

.wd-550-f {
  width: 550px !important;
}

.wd-600 {
  width: 600px;
}

.wd-600-f {
  width: 600px !important;
}

.wd-650 {
  width: 650px;
}

.wd-650-f {
  width: 650px !important;
}

.wd-700 {
  width: 700px;
}

.wd-700-f {
  width: 700px !important;
}

.wd-750 {
  width: 750px;
}

.wd-750-f {
  width: 750px !important;
}

.wd-800 {
  width: 800px;
}

.wd-800-f {
  width: 800px !important;
}

.wd-850 {
  width: 850px;
}

.wd-850-f {
  width: 850px !important;
}

.wd-900 {
  width: 900px;
}

.wd-900-f {
  width: 900px !important;
}

.wd-950 {
  width: 950px;
}

.wd-950-f {
  width: 950px !important;
}

.wd-1000 {
  width: 1000px;
}

.wd-1000-f {
  width: 1000px !important;
}

@media (min-width: 480px) {
  .wd-xs-5 {
    width: 5px;
  }
  .wd-xs-5p {
    width: 5%;
  }
  .mx-wd-xs-5p {
    max-width: 5%;
  }
  .mn-wd-xs-5p {
    min-width: 5%;
  }
  .wd-xs-5-f {
    width: 5px !important;
  }
  .wd-xs-5p-f {
    width: 5% !important;
  }
  .mx-wd-xs-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-xs-5p-f {
    min-width: 5% !important;
  }
  .wd-xs-10 {
    width: 10px;
  }
  .wd-xs-10p {
    width: 10%;
  }
  .mx-wd-xs-10p {
    max-width: 10%;
  }
  .mn-wd-xs-10p {
    min-width: 10%;
  }
  .wd-xs-10-f {
    width: 10px !important;
  }
  .wd-xs-10p-f {
    width: 10% !important;
  }
  .mx-wd-xs-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-xs-10p-f {
    min-width: 10% !important;
  }
  .wd-xs-15 {
    width: 15px;
  }
  .wd-xs-15p {
    width: 15%;
  }
  .mx-wd-xs-15p {
    max-width: 15%;
  }
  .mn-wd-xs-15p {
    min-width: 15%;
  }
  .wd-xs-15-f {
    width: 15px !important;
  }
  .wd-xs-15p-f {
    width: 15% !important;
  }
  .mx-wd-xs-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-xs-15p-f {
    min-width: 15% !important;
  }
  .wd-xs-20 {
    width: 20px;
  }
  .wd-xs-20p {
    width: 20%;
  }
  .mx-wd-xs-20p {
    max-width: 20%;
  }
  .mn-wd-xs-20p {
    min-width: 20%;
  }
  .wd-xs-20-f {
    width: 20px !important;
  }
  .wd-xs-20p-f {
    width: 20% !important;
  }
  .mx-wd-xs-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-xs-20p-f {
    min-width: 20% !important;
  }
  .wd-xs-25 {
    width: 25px;
  }
  .wd-xs-25p {
    width: 25%;
  }
  .mx-wd-xs-25p {
    max-width: 25%;
  }
  .mn-wd-xs-25p {
    min-width: 25%;
  }
  .wd-xs-25-f {
    width: 25px !important;
  }
  .wd-xs-25p-f {
    width: 25% !important;
  }
  .mx-wd-xs-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-xs-25p-f {
    min-width: 25% !important;
  }
  .wd-xs-30 {
    width: 30px;
  }
  .wd-xs-30p {
    width: 30%;
  }
  .mx-wd-xs-30p {
    max-width: 30%;
  }
  .mn-wd-xs-30p {
    min-width: 30%;
  }
  .wd-xs-30-f {
    width: 30px !important;
  }
  .wd-xs-30p-f {
    width: 30% !important;
  }
  .mx-wd-xs-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-xs-30p-f {
    min-width: 30% !important;
  }
  .wd-xs-35 {
    width: 35px;
  }
  .wd-xs-35p {
    width: 35%;
  }
  .mx-wd-xs-35p {
    max-width: 35%;
  }
  .mn-wd-xs-35p {
    min-width: 35%;
  }
  .wd-xs-35-f {
    width: 35px !important;
  }
  .wd-xs-35p-f {
    width: 35% !important;
  }
  .mx-wd-xs-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-xs-35p-f {
    min-width: 35% !important;
  }
  .wd-xs-40 {
    width: 40px;
  }
  .wd-xs-40p {
    width: 40%;
  }
  .mx-wd-xs-40p {
    max-width: 40%;
  }
  .mn-wd-xs-40p {
    min-width: 40%;
  }
  .wd-xs-40-f {
    width: 40px !important;
  }
  .wd-xs-40p-f {
    width: 40% !important;
  }
  .mx-wd-xs-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-xs-40p-f {
    min-width: 40% !important;
  }
  .wd-xs-45 {
    width: 45px;
  }
  .wd-xs-45p {
    width: 45%;
  }
  .mx-wd-xs-45p {
    max-width: 45%;
  }
  .mn-wd-xs-45p {
    min-width: 45%;
  }
  .wd-xs-45-f {
    width: 45px !important;
  }
  .wd-xs-45p-f {
    width: 45% !important;
  }
  .mx-wd-xs-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-xs-45p-f {
    min-width: 45% !important;
  }
  .wd-xs-50 {
    width: 50px;
  }
  .wd-xs-50p {
    width: 50%;
  }
  .mx-wd-xs-50p {
    max-width: 50%;
  }
  .mn-wd-xs-50p {
    min-width: 50%;
  }
  .wd-xs-50-f {
    width: 50px !important;
  }
  .wd-xs-50p-f {
    width: 50% !important;
  }
  .mx-wd-xs-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-xs-50p-f {
    min-width: 50% !important;
  }
  .wd-xs-55 {
    width: 55px;
  }
  .wd-xs-55p {
    width: 55%;
  }
  .mx-wd-xs-55p {
    max-width: 55%;
  }
  .mn-wd-xs-55p {
    min-width: 55%;
  }
  .wd-xs-55-f {
    width: 55px !important;
  }
  .wd-xs-55p-f {
    width: 55% !important;
  }
  .mx-wd-xs-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-xs-55p-f {
    min-width: 55% !important;
  }
  .wd-xs-60 {
    width: 60px;
  }
  .wd-xs-60p {
    width: 60%;
  }
  .mx-wd-xs-60p {
    max-width: 60%;
  }
  .mn-wd-xs-60p {
    min-width: 60%;
  }
  .wd-xs-60-f {
    width: 60px !important;
  }
  .wd-xs-60p-f {
    width: 60% !important;
  }
  .mx-wd-xs-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-xs-60p-f {
    min-width: 60% !important;
  }
  .wd-xs-65 {
    width: 65px;
  }
  .wd-xs-65p {
    width: 65%;
  }
  .mx-wd-xs-65p {
    max-width: 65%;
  }
  .mn-wd-xs-65p {
    min-width: 65%;
  }
  .wd-xs-65-f {
    width: 65px !important;
  }
  .wd-xs-65p-f {
    width: 65% !important;
  }
  .mx-wd-xs-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-xs-65p-f {
    min-width: 65% !important;
  }
  .wd-xs-70 {
    width: 70px;
  }
  .wd-xs-70p {
    width: 70%;
  }
  .mx-wd-xs-70p {
    max-width: 70%;
  }
  .mn-wd-xs-70p {
    min-width: 70%;
  }
  .wd-xs-70-f {
    width: 70px !important;
  }
  .wd-xs-70p-f {
    width: 70% !important;
  }
  .mx-wd-xs-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-xs-70p-f {
    min-width: 70% !important;
  }
  .wd-xs-75 {
    width: 75px;
  }
  .wd-xs-75p {
    width: 75%;
  }
  .mx-wd-xs-75p {
    max-width: 75%;
  }
  .mn-wd-xs-75p {
    min-width: 75%;
  }
  .wd-xs-75-f {
    width: 75px !important;
  }
  .wd-xs-75p-f {
    width: 75% !important;
  }
  .mx-wd-xs-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-xs-75p-f {
    min-width: 75% !important;
  }
  .wd-xs-80 {
    width: 80px;
  }
  .wd-xs-80p {
    width: 80%;
  }
  .mx-wd-xs-80p {
    max-width: 80%;
  }
  .mn-wd-xs-80p {
    min-width: 80%;
  }
  .wd-xs-80-f {
    width: 80px !important;
  }
  .wd-xs-80p-f {
    width: 80% !important;
  }
  .mx-wd-xs-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-xs-80p-f {
    min-width: 80% !important;
  }
  .wd-xs-85 {
    width: 85px;
  }
  .wd-xs-85p {
    width: 85%;
  }
  .mx-wd-xs-85p {
    max-width: 85%;
  }
  .mn-wd-xs-85p {
    min-width: 85%;
  }
  .wd-xs-85-f {
    width: 85px !important;
  }
  .wd-xs-85p-f {
    width: 85% !important;
  }
  .mx-wd-xs-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-xs-85p-f {
    min-width: 85% !important;
  }
  .wd-xs-90 {
    width: 90px;
  }
  .wd-xs-90p {
    width: 90%;
  }
  .mx-wd-xs-90p {
    max-width: 90%;
  }
  .mn-wd-xs-90p {
    min-width: 90%;
  }
  .wd-xs-90-f {
    width: 90px !important;
  }
  .wd-xs-90p-f {
    width: 90% !important;
  }
  .mx-wd-xs-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-xs-90p-f {
    min-width: 90% !important;
  }
  .wd-xs-95 {
    width: 95px;
  }
  .wd-xs-95p {
    width: 95%;
  }
  .mx-wd-xs-95p {
    max-width: 95%;
  }
  .mn-wd-xs-95p {
    min-width: 95%;
  }
  .wd-xs-95-f {
    width: 95px !important;
  }
  .wd-xs-95p-f {
    width: 95% !important;
  }
  .mx-wd-xs-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-xs-95p-f {
    min-width: 95% !important;
  }
  .wd-xs-100 {
    width: 100px;
  }
  .wd-xs-100p {
    width: 100%;
  }
  .mx-wd-xs-100p {
    max-width: 100%;
  }
  .mn-wd-xs-100p {
    min-width: 100%;
  }
  .wd-xs-100-f {
    width: 100px !important;
  }
  .wd-xs-100p-f {
    width: 100% !important;
  }
  .mx-wd-xs-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-xs-100p-f {
    min-width: 100% !important;
  }
  .wd-xs-150 {
    width: 150px;
  }
  .wd-xs-150p {
    width: 150%;
  }
  .mx-wd-xs-150p {
    max-width: 150%;
  }
  .mn-wd-xs-150p {
    min-width: 150%;
  }
  .wd-xs-150-f {
    width: 150px !important;
  }
  .wd-xs-150p-f {
    width: 150% !important;
  }
  .mx-wd-xs-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-xs-150p-f {
    min-width: 150% !important;
  }
  .wd-xs-200 {
    width: 200px;
  }
  .wd-xs-200p {
    width: 200%;
  }
  .mx-wd-xs-200p {
    max-width: 200%;
  }
  .mn-wd-xs-200p {
    min-width: 200%;
  }
  .wd-xs-200-f {
    width: 200px !important;
  }
  .wd-xs-200p-f {
    width: 200% !important;
  }
  .mx-wd-xs-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-xs-200p-f {
    min-width: 200% !important;
  }
  .wd-xs-250 {
    width: 250px;
  }
  .wd-xs-250p {
    width: 250%;
  }
  .mx-wd-xs-250p {
    max-width: 250%;
  }
  .mn-wd-xs-250p {
    min-width: 250%;
  }
  .wd-xs-250-f {
    width: 250px !important;
  }
  .wd-xs-250p-f {
    width: 250% !important;
  }
  .mx-wd-xs-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-xs-250p-f {
    min-width: 250% !important;
  }
  .wd-xs-300 {
    width: 300px;
  }
  .wd-xs-300p {
    width: 300%;
  }
  .mx-wd-xs-300p {
    max-width: 300%;
  }
  .mn-wd-xs-300p {
    min-width: 300%;
  }
  .wd-xs-300-f {
    width: 300px !important;
  }
  .wd-xs-300p-f {
    width: 300% !important;
  }
  .mx-wd-xs-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-xs-300p-f {
    min-width: 300% !important;
  }
  .wd-xs-350 {
    width: 350px;
  }
  .wd-xs-350p {
    width: 350%;
  }
  .mx-wd-xs-350p {
    max-width: 350%;
  }
  .mn-wd-xs-350p {
    min-width: 350%;
  }
  .wd-xs-350-f {
    width: 350px !important;
  }
  .wd-xs-350p-f {
    width: 350% !important;
  }
  .mx-wd-xs-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-xs-350p-f {
    min-width: 350% !important;
  }
  .wd-xs-400 {
    width: 400px;
  }
  .wd-xs-400p {
    width: 400%;
  }
  .mx-wd-xs-400p {
    max-width: 400%;
  }
  .mn-wd-xs-400p {
    min-width: 400%;
  }
  .wd-xs-400-f {
    width: 400px !important;
  }
  .wd-xs-400p-f {
    width: 400% !important;
  }
  .mx-wd-xs-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-xs-400p-f {
    min-width: 400% !important;
  }
  .wd-xs-450 {
    width: 450px;
  }
  .wd-xs-450p {
    width: 450%;
  }
  .mx-wd-xs-450p {
    max-width: 450%;
  }
  .mn-wd-xs-450p {
    min-width: 450%;
  }
  .wd-xs-450-f {
    width: 450px !important;
  }
  .wd-xs-450p-f {
    width: 450% !important;
  }
  .mx-wd-xs-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-xs-450p-f {
    min-width: 450% !important;
  }
  .wd-xs-500 {
    width: 500px;
  }
  .wd-xs-500p {
    width: 500%;
  }
  .mx-wd-xs-500p {
    max-width: 500%;
  }
  .mn-wd-xs-500p {
    min-width: 500%;
  }
  .wd-xs-500-f {
    width: 500px !important;
  }
  .wd-xs-500p-f {
    width: 500% !important;
  }
  .mx-wd-xs-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-xs-500p-f {
    min-width: 500% !important;
  }
  .wd-xs-550 {
    width: 550px;
  }
  .wd-xs-550p {
    width: 550%;
  }
  .mx-wd-xs-550p {
    max-width: 550%;
  }
  .mn-wd-xs-550p {
    min-width: 550%;
  }
  .wd-xs-550-f {
    width: 550px !important;
  }
  .wd-xs-550p-f {
    width: 550% !important;
  }
  .mx-wd-xs-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-xs-550p-f {
    min-width: 550% !important;
  }
  .wd-xs-600 {
    width: 600px;
  }
  .wd-xs-600p {
    width: 600%;
  }
  .mx-wd-xs-600p {
    max-width: 600%;
  }
  .mn-wd-xs-600p {
    min-width: 600%;
  }
  .wd-xs-600-f {
    width: 600px !important;
  }
  .wd-xs-600p-f {
    width: 600% !important;
  }
  .mx-wd-xs-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-xs-600p-f {
    min-width: 600% !important;
  }
  .wd-xs-650 {
    width: 650px;
  }
  .wd-xs-650p {
    width: 650%;
  }
  .mx-wd-xs-650p {
    max-width: 650%;
  }
  .mn-wd-xs-650p {
    min-width: 650%;
  }
  .wd-xs-650-f {
    width: 650px !important;
  }
  .wd-xs-650p-f {
    width: 650% !important;
  }
  .mx-wd-xs-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-xs-650p-f {
    min-width: 650% !important;
  }
  .wd-xs-700 {
    width: 700px;
  }
  .wd-xs-700p {
    width: 700%;
  }
  .mx-wd-xs-700p {
    max-width: 700%;
  }
  .mn-wd-xs-700p {
    min-width: 700%;
  }
  .wd-xs-700-f {
    width: 700px !important;
  }
  .wd-xs-700p-f {
    width: 700% !important;
  }
  .mx-wd-xs-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-xs-700p-f {
    min-width: 700% !important;
  }
  .wd-xs-750 {
    width: 750px;
  }
  .wd-xs-750p {
    width: 750%;
  }
  .mx-wd-xs-750p {
    max-width: 750%;
  }
  .mn-wd-xs-750p {
    min-width: 750%;
  }
  .wd-xs-750-f {
    width: 750px !important;
  }
  .wd-xs-750p-f {
    width: 750% !important;
  }
  .mx-wd-xs-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-xs-750p-f {
    min-width: 750% !important;
  }
  .wd-xs-800 {
    width: 800px;
  }
  .wd-xs-800p {
    width: 800%;
  }
  .mx-wd-xs-800p {
    max-width: 800%;
  }
  .mn-wd-xs-800p {
    min-width: 800%;
  }
  .wd-xs-800-f {
    width: 800px !important;
  }
  .wd-xs-800p-f {
    width: 800% !important;
  }
  .mx-wd-xs-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-xs-800p-f {
    min-width: 800% !important;
  }
  .wd-xs-850 {
    width: 850px;
  }
  .wd-xs-850p {
    width: 850%;
  }
  .mx-wd-xs-850p {
    max-width: 850%;
  }
  .mn-wd-xs-850p {
    min-width: 850%;
  }
  .wd-xs-850-f {
    width: 850px !important;
  }
  .wd-xs-850p-f {
    width: 850% !important;
  }
  .mx-wd-xs-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-xs-850p-f {
    min-width: 850% !important;
  }
  .wd-xs-900 {
    width: 900px;
  }
  .wd-xs-900p {
    width: 900%;
  }
  .mx-wd-xs-900p {
    max-width: 900%;
  }
  .mn-wd-xs-900p {
    min-width: 900%;
  }
  .wd-xs-900-f {
    width: 900px !important;
  }
  .wd-xs-900p-f {
    width: 900% !important;
  }
  .mx-wd-xs-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-xs-900p-f {
    min-width: 900% !important;
  }
  .wd-xs-950 {
    width: 950px;
  }
  .wd-xs-950p {
    width: 950%;
  }
  .mx-wd-xs-950p {
    max-width: 950%;
  }
  .mn-wd-xs-950p {
    min-width: 950%;
  }
  .wd-xs-950-f {
    width: 950px !important;
  }
  .wd-xs-950p-f {
    width: 950% !important;
  }
  .mx-wd-xs-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-xs-950p-f {
    min-width: 950% !important;
  }
  .wd-xs-1000 {
    width: 1000px;
  }
  .wd-xs-1000p {
    width: 1000%;
  }
  .mx-wd-xs-1000p {
    max-width: 1000%;
  }
  .mn-wd-xs-1000p {
    min-width: 1000%;
  }
  .wd-xs-1000-f {
    width: 1000px !important;
  }
  .wd-xs-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-xs-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-xs-1000p-f {
    min-width: 1000% !important;
  }
  .wd-xs-auto {
    width: auto;
  }
  .wd-xs-auto-f {
    width: auto !important;
  }
}

@media (min-width: 576px) {
  .wd-sm-5 {
    width: 5px;
  }
  .wd-sm-5p {
    width: 5%;
  }
  .mx-wd-sm-5p {
    max-width: 5%;
  }
  .mn-wd-sm-5p {
    min-width: 5%;
  }
  .wd-sm-5-f {
    width: 5px !important;
  }
  .wd-sm-5p-f {
    width: 5% !important;
  }
  .mx-wd-sm-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-sm-5p-f {
    min-width: 5% !important;
  }
  .wd-sm-10 {
    width: 10px;
  }
  .wd-sm-10p {
    width: 10%;
  }
  .mx-wd-sm-10p {
    max-width: 10%;
  }
  .mn-wd-sm-10p {
    min-width: 10%;
  }
  .wd-sm-10-f {
    width: 10px !important;
  }
  .wd-sm-10p-f {
    width: 10% !important;
  }
  .mx-wd-sm-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-sm-10p-f {
    min-width: 10% !important;
  }
  .wd-sm-15 {
    width: 15px;
  }
  .wd-sm-15p {
    width: 15%;
  }
  .mx-wd-sm-15p {
    max-width: 15%;
  }
  .mn-wd-sm-15p {
    min-width: 15%;
  }
  .wd-sm-15-f {
    width: 15px !important;
  }
  .wd-sm-15p-f {
    width: 15% !important;
  }
  .mx-wd-sm-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-sm-15p-f {
    min-width: 15% !important;
  }
  .wd-sm-20 {
    width: 20px;
  }
  .wd-sm-20p {
    width: 20%;
  }
  .mx-wd-sm-20p {
    max-width: 20%;
  }
  .mn-wd-sm-20p {
    min-width: 20%;
  }
  .wd-sm-20-f {
    width: 20px !important;
  }
  .wd-sm-20p-f {
    width: 20% !important;
  }
  .mx-wd-sm-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-sm-20p-f {
    min-width: 20% !important;
  }
  .wd-sm-25 {
    width: 25px;
  }
  .wd-sm-25p {
    width: 25%;
  }
  .mx-wd-sm-25p {
    max-width: 25%;
  }
  .mn-wd-sm-25p {
    min-width: 25%;
  }
  .wd-sm-25-f {
    width: 25px !important;
  }
  .wd-sm-25p-f {
    width: 25% !important;
  }
  .mx-wd-sm-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-sm-25p-f {
    min-width: 25% !important;
  }
  .wd-sm-30 {
    width: 30px;
  }
  .wd-sm-30p {
    width: 30%;
  }
  .mx-wd-sm-30p {
    max-width: 30%;
  }
  .mn-wd-sm-30p {
    min-width: 30%;
  }
  .wd-sm-30-f {
    width: 30px !important;
  }
  .wd-sm-30p-f {
    width: 30% !important;
  }
  .mx-wd-sm-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-sm-30p-f {
    min-width: 30% !important;
  }
  .wd-sm-35 {
    width: 35px;
  }
  .wd-sm-35p {
    width: 35%;
  }
  .mx-wd-sm-35p {
    max-width: 35%;
  }
  .mn-wd-sm-35p {
    min-width: 35%;
  }
  .wd-sm-35-f {
    width: 35px !important;
  }
  .wd-sm-35p-f {
    width: 35% !important;
  }
  .mx-wd-sm-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-sm-35p-f {
    min-width: 35% !important;
  }
  .wd-sm-40 {
    width: 40px;
  }
  .wd-sm-40p {
    width: 40%;
  }
  .mx-wd-sm-40p {
    max-width: 40%;
  }
  .mn-wd-sm-40p {
    min-width: 40%;
  }
  .wd-sm-40-f {
    width: 40px !important;
  }
  .wd-sm-40p-f {
    width: 40% !important;
  }
  .mx-wd-sm-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-sm-40p-f {
    min-width: 40% !important;
  }
  .wd-sm-45 {
    width: 45px;
  }
  .wd-sm-45p {
    width: 45%;
  }
  .mx-wd-sm-45p {
    max-width: 45%;
  }
  .mn-wd-sm-45p {
    min-width: 45%;
  }
  .wd-sm-45-f {
    width: 45px !important;
  }
  .wd-sm-45p-f {
    width: 45% !important;
  }
  .mx-wd-sm-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-sm-45p-f {
    min-width: 45% !important;
  }
  .wd-sm-50 {
    width: 50px;
  }
  .wd-sm-50p {
    width: 50%;
  }
  .mx-wd-sm-50p {
    max-width: 50%;
  }
  .mn-wd-sm-50p {
    min-width: 50%;
  }
  .wd-sm-50-f {
    width: 50px !important;
  }
  .wd-sm-50p-f {
    width: 50% !important;
  }
  .mx-wd-sm-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-sm-50p-f {
    min-width: 50% !important;
  }
  .wd-sm-55 {
    width: 55px;
  }
  .wd-sm-55p {
    width: 55%;
  }
  .mx-wd-sm-55p {
    max-width: 55%;
  }
  .mn-wd-sm-55p {
    min-width: 55%;
  }
  .wd-sm-55-f {
    width: 55px !important;
  }
  .wd-sm-55p-f {
    width: 55% !important;
  }
  .mx-wd-sm-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-sm-55p-f {
    min-width: 55% !important;
  }
  .wd-sm-60 {
    width: 60px;
  }
  .wd-sm-60p {
    width: 60%;
  }
  .mx-wd-sm-60p {
    max-width: 60%;
  }
  .mn-wd-sm-60p {
    min-width: 60%;
  }
  .wd-sm-60-f {
    width: 60px !important;
  }
  .wd-sm-60p-f {
    width: 60% !important;
  }
  .mx-wd-sm-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-sm-60p-f {
    min-width: 60% !important;
  }
  .wd-sm-65 {
    width: 65px;
  }
  .wd-sm-65p {
    width: 65%;
  }
  .mx-wd-sm-65p {
    max-width: 65%;
  }
  .mn-wd-sm-65p {
    min-width: 65%;
  }
  .wd-sm-65-f {
    width: 65px !important;
  }
  .wd-sm-65p-f {
    width: 65% !important;
  }
  .mx-wd-sm-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-sm-65p-f {
    min-width: 65% !important;
  }
  .wd-sm-70 {
    width: 70px;
  }
  .wd-sm-70p {
    width: 70%;
  }
  .mx-wd-sm-70p {
    max-width: 70%;
  }
  .mn-wd-sm-70p {
    min-width: 70%;
  }
  .wd-sm-70-f {
    width: 70px !important;
  }
  .wd-sm-70p-f {
    width: 70% !important;
  }
  .mx-wd-sm-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-sm-70p-f {
    min-width: 70% !important;
  }
  .wd-sm-75 {
    width: 75px;
  }
  .wd-sm-75p {
    width: 75%;
  }
  .mx-wd-sm-75p {
    max-width: 75%;
  }
  .mn-wd-sm-75p {
    min-width: 75%;
  }
  .wd-sm-75-f {
    width: 75px !important;
  }
  .wd-sm-75p-f {
    width: 75% !important;
  }
  .mx-wd-sm-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-sm-75p-f {
    min-width: 75% !important;
  }
  .wd-sm-80 {
    width: 80px;
  }
  .wd-sm-80p {
    width: 80%;
  }
  .mx-wd-sm-80p {
    max-width: 80%;
  }
  .mn-wd-sm-80p {
    min-width: 80%;
  }
  .wd-sm-80-f {
    width: 80px !important;
  }
  .wd-sm-80p-f {
    width: 80% !important;
  }
  .mx-wd-sm-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-sm-80p-f {
    min-width: 80% !important;
  }
  .wd-sm-85 {
    width: 85px;
  }
  .wd-sm-85p {
    width: 85%;
  }
  .mx-wd-sm-85p {
    max-width: 85%;
  }
  .mn-wd-sm-85p {
    min-width: 85%;
  }
  .wd-sm-85-f {
    width: 85px !important;
  }
  .wd-sm-85p-f {
    width: 85% !important;
  }
  .mx-wd-sm-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-sm-85p-f {
    min-width: 85% !important;
  }
  .wd-sm-90 {
    width: 90px;
  }
  .wd-sm-90p {
    width: 90%;
  }
  .mx-wd-sm-90p {
    max-width: 90%;
  }
  .mn-wd-sm-90p {
    min-width: 90%;
  }
  .wd-sm-90-f {
    width: 90px !important;
  }
  .wd-sm-90p-f {
    width: 90% !important;
  }
  .mx-wd-sm-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-sm-90p-f {
    min-width: 90% !important;
  }
  .wd-sm-95 {
    width: 95px;
  }
  .wd-sm-95p {
    width: 95%;
  }
  .mx-wd-sm-95p {
    max-width: 95%;
  }
  .mn-wd-sm-95p {
    min-width: 95%;
  }
  .wd-sm-95-f {
    width: 95px !important;
  }
  .wd-sm-95p-f {
    width: 95% !important;
  }
  .mx-wd-sm-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-sm-95p-f {
    min-width: 95% !important;
  }
  .wd-sm-100 {
    width: 100px;
  }
  .wd-sm-100p {
    width: 100%;
  }
  .mx-wd-sm-100p {
    max-width: 100%;
  }
  .mn-wd-sm-100p {
    min-width: 100%;
  }
  .wd-sm-100-f {
    width: 100px !important;
  }
  .wd-sm-100p-f {
    width: 100% !important;
  }
  .mx-wd-sm-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-sm-100p-f {
    min-width: 100% !important;
  }
  .wd-sm-150 {
    width: 150px;
  }
  .wd-sm-150p {
    width: 150%;
  }
  .mx-wd-sm-150p {
    max-width: 150%;
  }
  .mn-wd-sm-150p {
    min-width: 150%;
  }
  .wd-sm-150-f {
    width: 150px !important;
  }
  .wd-sm-150p-f {
    width: 150% !important;
  }
  .mx-wd-sm-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-sm-150p-f {
    min-width: 150% !important;
  }
  .wd-sm-200 {
    width: 200px;
  }
  .wd-sm-200p {
    width: 200%;
  }
  .mx-wd-sm-200p {
    max-width: 200%;
  }
  .mn-wd-sm-200p {
    min-width: 200%;
  }
  .wd-sm-200-f {
    width: 200px !important;
  }
  .wd-sm-200p-f {
    width: 200% !important;
  }
  .mx-wd-sm-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-sm-200p-f {
    min-width: 200% !important;
  }
  .wd-sm-250 {
    width: 250px;
  }
  .wd-sm-250p {
    width: 250%;
  }
  .mx-wd-sm-250p {
    max-width: 250%;
  }
  .mn-wd-sm-250p {
    min-width: 250%;
  }
  .wd-sm-250-f {
    width: 250px !important;
  }
  .wd-sm-250p-f {
    width: 250% !important;
  }
  .mx-wd-sm-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-sm-250p-f {
    min-width: 250% !important;
  }
  .wd-sm-300 {
    width: 300px;
  }
  .wd-sm-300p {
    width: 300%;
  }
  .mx-wd-sm-300p {
    max-width: 300%;
  }
  .mn-wd-sm-300p {
    min-width: 300%;
  }
  .wd-sm-300-f {
    width: 300px !important;
  }
  .wd-sm-300p-f {
    width: 300% !important;
  }
  .mx-wd-sm-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-sm-300p-f {
    min-width: 300% !important;
  }
  .wd-sm-350 {
    width: 350px;
  }
  .wd-sm-350p {
    width: 350%;
  }
  .mx-wd-sm-350p {
    max-width: 350%;
  }
  .mn-wd-sm-350p {
    min-width: 350%;
  }
  .wd-sm-350-f {
    width: 350px !important;
  }
  .wd-sm-350p-f {
    width: 350% !important;
  }
  .mx-wd-sm-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-sm-350p-f {
    min-width: 350% !important;
  }
  .wd-sm-400 {
    width: 400px;
  }
  .wd-sm-400p {
    width: 400%;
  }
  .mx-wd-sm-400p {
    max-width: 400%;
  }
  .mn-wd-sm-400p {
    min-width: 400%;
  }
  .wd-sm-400-f {
    width: 400px !important;
  }
  .wd-sm-400p-f {
    width: 400% !important;
  }
  .mx-wd-sm-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-sm-400p-f {
    min-width: 400% !important;
  }
  .wd-sm-450 {
    width: 450px;
  }
  .wd-sm-450p {
    width: 450%;
  }
  .mx-wd-sm-450p {
    max-width: 450%;
  }
  .mn-wd-sm-450p {
    min-width: 450%;
  }
  .wd-sm-450-f {
    width: 450px !important;
  }
  .wd-sm-450p-f {
    width: 450% !important;
  }
  .mx-wd-sm-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-sm-450p-f {
    min-width: 450% !important;
  }
  .wd-sm-500 {
    width: 500px;
  }
  .wd-sm-500p {
    width: 500%;
  }
  .mx-wd-sm-500p {
    max-width: 500%;
  }
  .mn-wd-sm-500p {
    min-width: 500%;
  }
  .wd-sm-500-f {
    width: 500px !important;
  }
  .wd-sm-500p-f {
    width: 500% !important;
  }
  .mx-wd-sm-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-sm-500p-f {
    min-width: 500% !important;
  }
  .wd-sm-550 {
    width: 550px;
  }
  .wd-sm-550p {
    width: 550%;
  }
  .mx-wd-sm-550p {
    max-width: 550%;
  }
  .mn-wd-sm-550p {
    min-width: 550%;
  }
  .wd-sm-550-f {
    width: 550px !important;
  }
  .wd-sm-550p-f {
    width: 550% !important;
  }
  .mx-wd-sm-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-sm-550p-f {
    min-width: 550% !important;
  }
  .wd-sm-600 {
    width: 600px;
  }
  .wd-sm-600p {
    width: 600%;
  }
  .mx-wd-sm-600p {
    max-width: 600%;
  }
  .mn-wd-sm-600p {
    min-width: 600%;
  }
  .wd-sm-600-f {
    width: 600px !important;
  }
  .wd-sm-600p-f {
    width: 600% !important;
  }
  .mx-wd-sm-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-sm-600p-f {
    min-width: 600% !important;
  }
  .wd-sm-650 {
    width: 650px;
  }
  .wd-sm-650p {
    width: 650%;
  }
  .mx-wd-sm-650p {
    max-width: 650%;
  }
  .mn-wd-sm-650p {
    min-width: 650%;
  }
  .wd-sm-650-f {
    width: 650px !important;
  }
  .wd-sm-650p-f {
    width: 650% !important;
  }
  .mx-wd-sm-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-sm-650p-f {
    min-width: 650% !important;
  }
  .wd-sm-700 {
    width: 700px;
  }
  .wd-sm-700p {
    width: 700%;
  }
  .mx-wd-sm-700p {
    max-width: 700%;
  }
  .mn-wd-sm-700p {
    min-width: 700%;
  }
  .wd-sm-700-f {
    width: 700px !important;
  }
  .wd-sm-700p-f {
    width: 700% !important;
  }
  .mx-wd-sm-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-sm-700p-f {
    min-width: 700% !important;
  }
  .wd-sm-750 {
    width: 750px;
  }
  .wd-sm-750p {
    width: 750%;
  }
  .mx-wd-sm-750p {
    max-width: 750%;
  }
  .mn-wd-sm-750p {
    min-width: 750%;
  }
  .wd-sm-750-f {
    width: 750px !important;
  }
  .wd-sm-750p-f {
    width: 750% !important;
  }
  .mx-wd-sm-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-sm-750p-f {
    min-width: 750% !important;
  }
  .wd-sm-800 {
    width: 800px;
  }
  .wd-sm-800p {
    width: 800%;
  }
  .mx-wd-sm-800p {
    max-width: 800%;
  }
  .mn-wd-sm-800p {
    min-width: 800%;
  }
  .wd-sm-800-f {
    width: 800px !important;
  }
  .wd-sm-800p-f {
    width: 800% !important;
  }
  .mx-wd-sm-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-sm-800p-f {
    min-width: 800% !important;
  }
  .wd-sm-850 {
    width: 850px;
  }
  .wd-sm-850p {
    width: 850%;
  }
  .mx-wd-sm-850p {
    max-width: 850%;
  }
  .mn-wd-sm-850p {
    min-width: 850%;
  }
  .wd-sm-850-f {
    width: 850px !important;
  }
  .wd-sm-850p-f {
    width: 850% !important;
  }
  .mx-wd-sm-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-sm-850p-f {
    min-width: 850% !important;
  }
  .wd-sm-900 {
    width: 900px;
  }
  .wd-sm-900p {
    width: 900%;
  }
  .mx-wd-sm-900p {
    max-width: 900%;
  }
  .mn-wd-sm-900p {
    min-width: 900%;
  }
  .wd-sm-900-f {
    width: 900px !important;
  }
  .wd-sm-900p-f {
    width: 900% !important;
  }
  .mx-wd-sm-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-sm-900p-f {
    min-width: 900% !important;
  }
  .wd-sm-950 {
    width: 950px;
  }
  .wd-sm-950p {
    width: 950%;
  }
  .mx-wd-sm-950p {
    max-width: 950%;
  }
  .mn-wd-sm-950p {
    min-width: 950%;
  }
  .wd-sm-950-f {
    width: 950px !important;
  }
  .wd-sm-950p-f {
    width: 950% !important;
  }
  .mx-wd-sm-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-sm-950p-f {
    min-width: 950% !important;
  }
  .wd-sm-1000 {
    width: 1000px;
  }
  .wd-sm-1000p {
    width: 1000%;
  }
  .mx-wd-sm-1000p {
    max-width: 1000%;
  }
  .mn-wd-sm-1000p {
    min-width: 1000%;
  }
  .wd-sm-1000-f {
    width: 1000px !important;
  }
  .wd-sm-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-sm-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-sm-1000p-f {
    min-width: 1000% !important;
  }
  .wd-sm-auto {
    width: auto;
  }
  .wd-sm-auto-f {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .wd-md-5 {
    width: 5px;
  }
  .wd-md-5p {
    width: 5%;
  }
  .mx-wd-md-5p {
    max-width: 5%;
  }
  .mn-wd-md-5p {
    min-width: 5%;
  }
  .wd-md-5-f {
    width: 5px !important;
  }
  .wd-md-5p-f {
    width: 5% !important;
  }
  .mx-wd-md-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-md-5p-f {
    min-width: 5% !important;
  }
  .wd-md-10 {
    width: 10px;
  }
  .wd-md-10p {
    width: 10%;
  }
  .mx-wd-md-10p {
    max-width: 10%;
  }
  .mn-wd-md-10p {
    min-width: 10%;
  }
  .wd-md-10-f {
    width: 10px !important;
  }
  .wd-md-10p-f {
    width: 10% !important;
  }
  .mx-wd-md-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-md-10p-f {
    min-width: 10% !important;
  }
  .wd-md-15 {
    width: 15px;
  }
  .wd-md-15p {
    width: 15%;
  }
  .mx-wd-md-15p {
    max-width: 15%;
  }
  .mn-wd-md-15p {
    min-width: 15%;
  }
  .wd-md-15-f {
    width: 15px !important;
  }
  .wd-md-15p-f {
    width: 15% !important;
  }
  .mx-wd-md-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-md-15p-f {
    min-width: 15% !important;
  }
  .wd-md-20 {
    width: 20px;
  }
  .wd-md-20p {
    width: 20%;
  }
  .mx-wd-md-20p {
    max-width: 20%;
  }
  .mn-wd-md-20p {
    min-width: 20%;
  }
  .wd-md-20-f {
    width: 20px !important;
  }
  .wd-md-20p-f {
    width: 20% !important;
  }
  .mx-wd-md-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-md-20p-f {
    min-width: 20% !important;
  }
  .wd-md-25 {
    width: 25px;
  }
  .wd-md-25p {
    width: 25%;
  }
  .mx-wd-md-25p {
    max-width: 25%;
  }
  .mn-wd-md-25p {
    min-width: 25%;
  }
  .wd-md-25-f {
    width: 25px !important;
  }
  .wd-md-25p-f {
    width: 25% !important;
  }
  .mx-wd-md-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-md-25p-f {
    min-width: 25% !important;
  }
  .wd-md-30 {
    width: 30px;
  }
  .wd-md-30p {
    width: 30%;
  }
  .mx-wd-md-30p {
    max-width: 30%;
  }
  .mn-wd-md-30p {
    min-width: 30%;
  }
  .wd-md-30-f {
    width: 30px !important;
  }
  .wd-md-30p-f {
    width: 30% !important;
  }
  .mx-wd-md-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-md-30p-f {
    min-width: 30% !important;
  }
  .wd-md-35 {
    width: 35px;
  }
  .wd-md-35p {
    width: 35%;
  }
  .mx-wd-md-35p {
    max-width: 35%;
  }
  .mn-wd-md-35p {
    min-width: 35%;
  }
  .wd-md-35-f {
    width: 35px !important;
  }
  .wd-md-35p-f {
    width: 35% !important;
  }
  .mx-wd-md-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-md-35p-f {
    min-width: 35% !important;
  }
  .wd-md-40 {
    width: 40px;
  }
  .wd-md-40p {
    width: 40%;
  }
  .mx-wd-md-40p {
    max-width: 40%;
  }
  .mn-wd-md-40p {
    min-width: 40%;
  }
  .wd-md-40-f {
    width: 40px !important;
  }
  .wd-md-40p-f {
    width: 40% !important;
  }
  .mx-wd-md-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-md-40p-f {
    min-width: 40% !important;
  }
  .wd-md-45 {
    width: 45px;
  }
  .wd-md-45p {
    width: 45%;
  }
  .mx-wd-md-45p {
    max-width: 45%;
  }
  .mn-wd-md-45p {
    min-width: 45%;
  }
  .wd-md-45-f {
    width: 45px !important;
  }
  .wd-md-45p-f {
    width: 45% !important;
  }
  .mx-wd-md-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-md-45p-f {
    min-width: 45% !important;
  }
  .wd-md-50 {
    width: 50px;
  }
  .wd-md-50p {
    width: 50%;
  }
  .mx-wd-md-50p {
    max-width: 50%;
  }
  .mn-wd-md-50p {
    min-width: 50%;
  }
  .wd-md-50-f {
    width: 50px !important;
  }
  .wd-md-50p-f {
    width: 50% !important;
  }
  .mx-wd-md-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-md-50p-f {
    min-width: 50% !important;
  }
  .wd-md-55 {
    width: 55px;
  }
  .wd-md-55p {
    width: 55%;
  }
  .mx-wd-md-55p {
    max-width: 55%;
  }
  .mn-wd-md-55p {
    min-width: 55%;
  }
  .wd-md-55-f {
    width: 55px !important;
  }
  .wd-md-55p-f {
    width: 55% !important;
  }
  .mx-wd-md-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-md-55p-f {
    min-width: 55% !important;
  }
  .wd-md-60 {
    width: 60px;
  }
  .wd-md-60p {
    width: 60%;
  }
  .mx-wd-md-60p {
    max-width: 60%;
  }
  .mn-wd-md-60p {
    min-width: 60%;
  }
  .wd-md-60-f {
    width: 60px !important;
  }
  .wd-md-60p-f {
    width: 60% !important;
  }
  .mx-wd-md-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-md-60p-f {
    min-width: 60% !important;
  }
  .wd-md-65 {
    width: 65px;
  }
  .wd-md-65p {
    width: 65%;
  }
  .mx-wd-md-65p {
    max-width: 65%;
  }
  .mn-wd-md-65p {
    min-width: 65%;
  }
  .wd-md-65-f {
    width: 65px !important;
  }
  .wd-md-65p-f {
    width: 65% !important;
  }
  .mx-wd-md-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-md-65p-f {
    min-width: 65% !important;
  }
  .wd-md-70 {
    width: 70px;
  }
  .wd-md-70p {
    width: 70%;
  }
  .mx-wd-md-70p {
    max-width: 70%;
  }
  .mn-wd-md-70p {
    min-width: 70%;
  }
  .wd-md-70-f {
    width: 70px !important;
  }
  .wd-md-70p-f {
    width: 70% !important;
  }
  .mx-wd-md-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-md-70p-f {
    min-width: 70% !important;
  }
  .wd-md-75 {
    width: 75px;
  }
  .wd-md-75p {
    width: 75%;
  }
  .mx-wd-md-75p {
    max-width: 75%;
  }
  .mn-wd-md-75p {
    min-width: 75%;
  }
  .wd-md-75-f {
    width: 75px !important;
  }
  .wd-md-75p-f {
    width: 75% !important;
  }
  .mx-wd-md-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-md-75p-f {
    min-width: 75% !important;
  }
  .wd-md-80 {
    width: 80px;
  }
  .wd-md-80p {
    width: 80%;
  }
  .mx-wd-md-80p {
    max-width: 80%;
  }
  .mn-wd-md-80p {
    min-width: 80%;
  }
  .wd-md-80-f {
    width: 80px !important;
  }
  .wd-md-80p-f {
    width: 80% !important;
  }
  .mx-wd-md-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-md-80p-f {
    min-width: 80% !important;
  }
  .wd-md-85 {
    width: 85px;
  }
  .wd-md-85p {
    width: 85%;
  }
  .mx-wd-md-85p {
    max-width: 85%;
  }
  .mn-wd-md-85p {
    min-width: 85%;
  }
  .wd-md-85-f {
    width: 85px !important;
  }
  .wd-md-85p-f {
    width: 85% !important;
  }
  .mx-wd-md-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-md-85p-f {
    min-width: 85% !important;
  }
  .wd-md-90 {
    width: 90px;
  }
  .wd-md-90p {
    width: 90%;
  }
  .mx-wd-md-90p {
    max-width: 90%;
  }
  .mn-wd-md-90p {
    min-width: 90%;
  }
  .wd-md-90-f {
    width: 90px !important;
  }
  .wd-md-90p-f {
    width: 90% !important;
  }
  .mx-wd-md-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-md-90p-f {
    min-width: 90% !important;
  }
  .wd-md-95 {
    width: 95px;
  }
  .wd-md-95p {
    width: 95%;
  }
  .mx-wd-md-95p {
    max-width: 95%;
  }
  .mn-wd-md-95p {
    min-width: 95%;
  }
  .wd-md-95-f {
    width: 95px !important;
  }
  .wd-md-95p-f {
    width: 95% !important;
  }
  .mx-wd-md-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-md-95p-f {
    min-width: 95% !important;
  }
  .wd-md-100 {
    width: 100px;
  }
  .wd-md-100p {
    width: 100%;
  }
  .mx-wd-md-100p {
    max-width: 100%;
  }
  .mn-wd-md-100p {
    min-width: 100%;
  }
  .wd-md-100-f {
    width: 100px !important;
  }
  .wd-md-100p-f {
    width: 100% !important;
  }
  .mx-wd-md-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-md-100p-f {
    min-width: 100% !important;
  }
  .wd-md-150 {
    width: 150px;
  }
  .wd-md-150p {
    width: 150%;
  }
  .mx-wd-md-150p {
    max-width: 150%;
  }
  .mn-wd-md-150p {
    min-width: 150%;
  }
  .wd-md-150-f {
    width: 150px !important;
  }
  .wd-md-150p-f {
    width: 150% !important;
  }
  .mx-wd-md-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-md-150p-f {
    min-width: 150% !important;
  }
  .wd-md-200 {
    width: 200px;
  }
  .wd-md-200p {
    width: 200%;
  }
  .mx-wd-md-200p {
    max-width: 200%;
  }
  .mn-wd-md-200p {
    min-width: 200%;
  }
  .wd-md-200-f {
    width: 200px !important;
  }
  .wd-md-200p-f {
    width: 200% !important;
  }
  .mx-wd-md-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-md-200p-f {
    min-width: 200% !important;
  }
  .wd-md-250 {
    width: 250px;
  }
  .wd-md-250p {
    width: 250%;
  }
  .mx-wd-md-250p {
    max-width: 250%;
  }
  .mn-wd-md-250p {
    min-width: 250%;
  }
  .wd-md-250-f {
    width: 250px !important;
  }
  .wd-md-250p-f {
    width: 250% !important;
  }
  .mx-wd-md-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-md-250p-f {
    min-width: 250% !important;
  }
  .wd-md-300 {
    width: 300px;
  }
  .wd-md-300p {
    width: 300%;
  }
  .mx-wd-md-300p {
    max-width: 300%;
  }
  .mn-wd-md-300p {
    min-width: 300%;
  }
  .wd-md-300-f {
    width: 300px !important;
  }
  .wd-md-300p-f {
    width: 300% !important;
  }
  .mx-wd-md-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-md-300p-f {
    min-width: 300% !important;
  }
  .wd-md-350 {
    width: 350px;
  }
  .wd-md-350p {
    width: 350%;
  }
  .mx-wd-md-350p {
    max-width: 350%;
  }
  .mn-wd-md-350p {
    min-width: 350%;
  }
  .wd-md-350-f {
    width: 350px !important;
  }
  .wd-md-350p-f {
    width: 350% !important;
  }
  .mx-wd-md-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-md-350p-f {
    min-width: 350% !important;
  }
  .wd-md-400 {
    width: 400px;
  }
  .wd-md-400p {
    width: 400%;
  }
  .mx-wd-md-400p {
    max-width: 400%;
  }
  .mn-wd-md-400p {
    min-width: 400%;
  }
  .wd-md-400-f {
    width: 400px !important;
  }
  .wd-md-400p-f {
    width: 400% !important;
  }
  .mx-wd-md-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-md-400p-f {
    min-width: 400% !important;
  }
  .wd-md-450 {
    width: 450px;
  }
  .wd-md-450p {
    width: 450%;
  }
  .mx-wd-md-450p {
    max-width: 450%;
  }
  .mn-wd-md-450p {
    min-width: 450%;
  }
  .wd-md-450-f {
    width: 450px !important;
  }
  .wd-md-450p-f {
    width: 450% !important;
  }
  .mx-wd-md-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-md-450p-f {
    min-width: 450% !important;
  }
  .wd-md-500 {
    width: 500px;
  }
  .wd-md-500p {
    width: 500%;
  }
  .mx-wd-md-500p {
    max-width: 500%;
  }
  .mn-wd-md-500p {
    min-width: 500%;
  }
  .wd-md-500-f {
    width: 500px !important;
  }
  .wd-md-500p-f {
    width: 500% !important;
  }
  .mx-wd-md-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-md-500p-f {
    min-width: 500% !important;
  }
  .wd-md-550 {
    width: 550px;
  }
  .wd-md-550p {
    width: 550%;
  }
  .mx-wd-md-550p {
    max-width: 550%;
  }
  .mn-wd-md-550p {
    min-width: 550%;
  }
  .wd-md-550-f {
    width: 550px !important;
  }
  .wd-md-550p-f {
    width: 550% !important;
  }
  .mx-wd-md-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-md-550p-f {
    min-width: 550% !important;
  }
  .wd-md-600 {
    width: 600px;
  }
  .wd-md-600p {
    width: 600%;
  }
  .mx-wd-md-600p {
    max-width: 600%;
  }
  .mn-wd-md-600p {
    min-width: 600%;
  }
  .wd-md-600-f {
    width: 600px !important;
  }
  .wd-md-600p-f {
    width: 600% !important;
  }
  .mx-wd-md-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-md-600p-f {
    min-width: 600% !important;
  }
  .wd-md-650 {
    width: 650px;
  }
  .wd-md-650p {
    width: 650%;
  }
  .mx-wd-md-650p {
    max-width: 650%;
  }
  .mn-wd-md-650p {
    min-width: 650%;
  }
  .wd-md-650-f {
    width: 650px !important;
  }
  .wd-md-650p-f {
    width: 650% !important;
  }
  .mx-wd-md-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-md-650p-f {
    min-width: 650% !important;
  }
  .wd-md-700 {
    width: 700px;
  }
  .wd-md-700p {
    width: 700%;
  }
  .mx-wd-md-700p {
    max-width: 700%;
  }
  .mn-wd-md-700p {
    min-width: 700%;
  }
  .wd-md-700-f {
    width: 700px !important;
  }
  .wd-md-700p-f {
    width: 700% !important;
  }
  .mx-wd-md-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-md-700p-f {
    min-width: 700% !important;
  }
  .wd-md-750 {
    width: 750px;
  }
  .wd-md-750p {
    width: 750%;
  }
  .mx-wd-md-750p {
    max-width: 750%;
  }
  .mn-wd-md-750p {
    min-width: 750%;
  }
  .wd-md-750-f {
    width: 750px !important;
  }
  .wd-md-750p-f {
    width: 750% !important;
  }
  .mx-wd-md-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-md-750p-f {
    min-width: 750% !important;
  }
  .wd-md-800 {
    width: 800px;
  }
  .wd-md-800p {
    width: 800%;
  }
  .mx-wd-md-800p {
    max-width: 800%;
  }
  .mn-wd-md-800p {
    min-width: 800%;
  }
  .wd-md-800-f {
    width: 800px !important;
  }
  .wd-md-800p-f {
    width: 800% !important;
  }
  .mx-wd-md-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-md-800p-f {
    min-width: 800% !important;
  }
  .wd-md-850 {
    width: 850px;
  }
  .wd-md-850p {
    width: 850%;
  }
  .mx-wd-md-850p {
    max-width: 850%;
  }
  .mn-wd-md-850p {
    min-width: 850%;
  }
  .wd-md-850-f {
    width: 850px !important;
  }
  .wd-md-850p-f {
    width: 850% !important;
  }
  .mx-wd-md-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-md-850p-f {
    min-width: 850% !important;
  }
  .wd-md-900 {
    width: 900px;
  }
  .wd-md-900p {
    width: 900%;
  }
  .mx-wd-md-900p {
    max-width: 900%;
  }
  .mn-wd-md-900p {
    min-width: 900%;
  }
  .wd-md-900-f {
    width: 900px !important;
  }
  .wd-md-900p-f {
    width: 900% !important;
  }
  .mx-wd-md-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-md-900p-f {
    min-width: 900% !important;
  }
  .wd-md-950 {
    width: 950px;
  }
  .wd-md-950p {
    width: 950%;
  }
  .mx-wd-md-950p {
    max-width: 950%;
  }
  .mn-wd-md-950p {
    min-width: 950%;
  }
  .wd-md-950-f {
    width: 950px !important;
  }
  .wd-md-950p-f {
    width: 950% !important;
  }
  .mx-wd-md-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-md-950p-f {
    min-width: 950% !important;
  }
  .wd-md-1000 {
    width: 1000px;
  }
  .wd-md-1000p {
    width: 1000%;
  }
  .mx-wd-md-1000p {
    max-width: 1000%;
  }
  .mn-wd-md-1000p {
    min-width: 1000%;
  }
  .wd-md-1000-f {
    width: 1000px !important;
  }
  .wd-md-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-md-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-md-1000p-f {
    min-width: 1000% !important;
  }
  .wd-md-auto {
    width: auto;
  }
  .wd-md-auto-f {
    width: auto !important;
  }
  .wd-md-120 {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .wd-lg-5 {
    width: 5px;
  }
  .wd-lg-5p {
    width: 5%;
  }
  .mx-wd-lg-5p {
    max-width: 5%;
  }
  .mn-wd-lg-5p {
    min-width: 5%;
  }
  .wd-lg-5-f {
    width: 5px !important;
  }
  .wd-lg-5p-f {
    width: 5% !important;
  }
  .mx-wd-lg-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-lg-5p-f {
    min-width: 5% !important;
  }
  .wd-lg-10 {
    width: 10px;
  }
  .wd-lg-10p {
    width: 10%;
  }
  .mx-wd-lg-10p {
    max-width: 10%;
  }
  .mn-wd-lg-10p {
    min-width: 10%;
  }
  .wd-lg-10-f {
    width: 10px !important;
  }
  .wd-lg-10p-f {
    width: 10% !important;
  }
  .mx-wd-lg-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-lg-10p-f {
    min-width: 10% !important;
  }
  .wd-lg-15 {
    width: 15px;
  }
  .wd-lg-15p {
    width: 15%;
  }
  .mx-wd-lg-15p {
    max-width: 15%;
  }
  .mn-wd-lg-15p {
    min-width: 15%;
  }
  .wd-lg-15-f {
    width: 15px !important;
  }
  .wd-lg-15p-f {
    width: 15% !important;
  }
  .mx-wd-lg-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-lg-15p-f {
    min-width: 15% !important;
  }
  .wd-lg-20 {
    width: 20px;
  }
  .wd-lg-20p {
    width: 20%;
  }
  .mx-wd-lg-20p {
    max-width: 20%;
  }
  .mn-wd-lg-20p {
    min-width: 20%;
  }
  .wd-lg-20-f {
    width: 20px !important;
  }
  .wd-lg-20p-f {
    width: 20% !important;
  }
  .mx-wd-lg-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-lg-20p-f {
    min-width: 20% !important;
  }
  .wd-lg-25 {
    width: 25px;
  }
  .wd-lg-25p {
    width: 25%;
  }
  .mx-wd-lg-25p {
    max-width: 25%;
  }
  .mn-wd-lg-25p {
    min-width: 25%;
  }
  .wd-lg-25-f {
    width: 25px !important;
  }
  .wd-lg-25p-f {
    width: 25% !important;
  }
  .mx-wd-lg-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-lg-25p-f {
    min-width: 25% !important;
  }
  .wd-lg-30 {
    width: 30px;
  }
  .wd-lg-30p {
    width: 30%;
  }
  .mx-wd-lg-30p {
    max-width: 30%;
  }
  .mn-wd-lg-30p {
    min-width: 30%;
  }
  .wd-lg-30-f {
    width: 30px !important;
  }
  .wd-lg-30p-f {
    width: 30% !important;
  }
  .mx-wd-lg-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-lg-30p-f {
    min-width: 30% !important;
  }
  .wd-lg-35 {
    width: 35px;
  }
  .wd-lg-35p {
    width: 35%;
  }
  .mx-wd-lg-35p {
    max-width: 35%;
  }
  .mn-wd-lg-35p {
    min-width: 35%;
  }
  .wd-lg-35-f {
    width: 35px !important;
  }
  .wd-lg-35p-f {
    width: 35% !important;
  }
  .mx-wd-lg-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-lg-35p-f {
    min-width: 35% !important;
  }
  .wd-lg-40 {
    width: 40px;
  }
  .wd-lg-40p {
    width: 40%;
  }
  .mx-wd-lg-40p {
    max-width: 40%;
  }
  .mn-wd-lg-40p {
    min-width: 40%;
  }
  .wd-lg-40-f {
    width: 40px !important;
  }
  .wd-lg-40p-f {
    width: 40% !important;
  }
  .mx-wd-lg-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-lg-40p-f {
    min-width: 40% !important;
  }
  .wd-lg-45 {
    width: 45px;
  }
  .wd-lg-45p {
    width: 45%;
  }
  .mx-wd-lg-45p {
    max-width: 45%;
  }
  .mn-wd-lg-45p {
    min-width: 45%;
  }
  .wd-lg-45-f {
    width: 45px !important;
  }
  .wd-lg-45p-f {
    width: 45% !important;
  }
  .mx-wd-lg-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-lg-45p-f {
    min-width: 45% !important;
  }
  .wd-lg-50 {
    width: 50px;
  }
  .wd-lg-50p {
    width: 50%;
  }
  .mx-wd-lg-50p {
    max-width: 50%;
  }
  .mn-wd-lg-50p {
    min-width: 50%;
  }
  .wd-lg-50-f {
    width: 50px !important;
  }
  .wd-lg-50p-f {
    width: 50% !important;
  }
  .mx-wd-lg-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-lg-50p-f {
    min-width: 50% !important;
  }
  .wd-lg-55 {
    width: 55px;
  }
  .wd-lg-55p {
    width: 55%;
  }
  .mx-wd-lg-55p {
    max-width: 55%;
  }
  .mn-wd-lg-55p {
    min-width: 55%;
  }
  .wd-lg-55-f {
    width: 55px !important;
  }
  .wd-lg-55p-f {
    width: 55% !important;
  }
  .mx-wd-lg-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-lg-55p-f {
    min-width: 55% !important;
  }
  .wd-lg-60 {
    width: 60px;
  }
  .wd-lg-60p {
    width: 60%;
  }
  .mx-wd-lg-60p {
    max-width: 60%;
  }
  .mn-wd-lg-60p {
    min-width: 60%;
  }
  .wd-lg-60-f {
    width: 60px !important;
  }
  .wd-lg-60p-f {
    width: 60% !important;
  }
  .mx-wd-lg-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-lg-60p-f {
    min-width: 60% !important;
  }
  .wd-lg-65 {
    width: 65px;
  }
  .wd-lg-65p {
    width: 65%;
  }
  .mx-wd-lg-65p {
    max-width: 65%;
  }
  .mn-wd-lg-65p {
    min-width: 65%;
  }
  .wd-lg-65-f {
    width: 65px !important;
  }
  .wd-lg-65p-f {
    width: 65% !important;
  }
  .mx-wd-lg-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-lg-65p-f {
    min-width: 65% !important;
  }
  .wd-lg-70 {
    width: 70px;
  }
  .wd-lg-70p {
    width: 70%;
  }
  .mx-wd-lg-70p {
    max-width: 70%;
  }
  .mn-wd-lg-70p {
    min-width: 70%;
  }
  .wd-lg-70-f {
    width: 70px !important;
  }
  .wd-lg-70p-f {
    width: 70% !important;
  }
  .mx-wd-lg-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-lg-70p-f {
    min-width: 70% !important;
  }
  .wd-lg-75 {
    width: 75px;
  }
  .wd-lg-75p {
    width: 75%;
  }
  .mx-wd-lg-75p {
    max-width: 75%;
  }
  .mn-wd-lg-75p {
    min-width: 75%;
  }
  .wd-lg-75-f {
    width: 75px !important;
  }
  .wd-lg-75p-f {
    width: 75% !important;
  }
  .mx-wd-lg-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-lg-75p-f {
    min-width: 75% !important;
  }
  .wd-lg-80 {
    width: 80px;
  }
  .wd-lg-80p {
    width: 80%;
  }
  .mx-wd-lg-80p {
    max-width: 80%;
  }
  .mn-wd-lg-80p {
    min-width: 80%;
  }
  .wd-lg-80-f {
    width: 80px !important;
  }
  .wd-lg-80p-f {
    width: 80% !important;
  }
  .mx-wd-lg-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-lg-80p-f {
    min-width: 80% !important;
  }
  .wd-lg-85 {
    width: 85px;
  }
  .wd-lg-85p {
    width: 85%;
  }
  .mx-wd-lg-85p {
    max-width: 85%;
  }
  .mn-wd-lg-85p {
    min-width: 85%;
  }
  .wd-lg-85-f {
    width: 85px !important;
  }
  .wd-lg-85p-f {
    width: 85% !important;
  }
  .mx-wd-lg-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-lg-85p-f {
    min-width: 85% !important;
  }
  .wd-lg-90 {
    width: 90px;
  }
  .wd-lg-90p {
    width: 90%;
  }
  .mx-wd-lg-90p {
    max-width: 90%;
  }
  .mn-wd-lg-90p {
    min-width: 90%;
  }
  .wd-lg-90-f {
    width: 90px !important;
  }
  .wd-lg-90p-f {
    width: 90% !important;
  }
  .mx-wd-lg-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-lg-90p-f {
    min-width: 90% !important;
  }
  .wd-lg-95 {
    width: 95px;
  }
  .wd-lg-95p {
    width: 95%;
  }
  .mx-wd-lg-95p {
    max-width: 95%;
  }
  .mn-wd-lg-95p {
    min-width: 95%;
  }
  .wd-lg-95-f {
    width: 95px !important;
  }
  .wd-lg-95p-f {
    width: 95% !important;
  }
  .mx-wd-lg-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-lg-95p-f {
    min-width: 95% !important;
  }
  .wd-lg-100 {
    width: 100px;
  }
  .wd-lg-100p {
    width: 100%;
  }
  .mx-wd-lg-100p {
    max-width: 100%;
  }
  .mn-wd-lg-100p {
    min-width: 100%;
  }
  .wd-lg-100-f {
    width: 100px !important;
  }
  .wd-lg-100p-f {
    width: 100% !important;
  }
  .mx-wd-lg-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-lg-100p-f {
    min-width: 100% !important;
  }
  .wd-lg-150 {
    width: 150px;
  }
  .wd-lg-150p {
    width: 150%;
  }
  .mx-wd-lg-150p {
    max-width: 150%;
  }
  .mn-wd-lg-150p {
    min-width: 150%;
  }
  .wd-lg-150-f {
    width: 150px !important;
  }
  .wd-lg-150p-f {
    width: 150% !important;
  }
  .mx-wd-lg-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-lg-150p-f {
    min-width: 150% !important;
  }
  .wd-lg-200 {
    width: 200px;
  }
  .wd-lg-200p {
    width: 200%;
  }
  .mx-wd-lg-200p {
    max-width: 200%;
  }
  .mn-wd-lg-200p {
    min-width: 200%;
  }
  .wd-lg-200-f {
    width: 200px !important;
  }
  .wd-lg-200p-f {
    width: 200% !important;
  }
  .mx-wd-lg-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-lg-200p-f {
    min-width: 200% !important;
  }
  .wd-lg-250 {
    width: 250px;
  }
  .wd-lg-250p {
    width: 250%;
  }
  .mx-wd-lg-250p {
    max-width: 250%;
  }
  .mn-wd-lg-250p {
    min-width: 250%;
  }
  .wd-lg-250-f {
    width: 250px !important;
  }
  .wd-lg-250p-f {
    width: 250% !important;
  }
  .mx-wd-lg-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-lg-250p-f {
    min-width: 250% !important;
  }
  .wd-lg-300 {
    width: 300px;
  }
  .wd-lg-300p {
    width: 300%;
  }
  .mx-wd-lg-300p {
    max-width: 300%;
  }
  .mn-wd-lg-300p {
    min-width: 300%;
  }
  .wd-lg-300-f {
    width: 300px !important;
  }
  .wd-lg-300p-f {
    width: 300% !important;
  }
  .mx-wd-lg-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-lg-300p-f {
    min-width: 300% !important;
  }
  .wd-lg-350 {
    width: 350px;
  }
  .wd-lg-350p {
    width: 350%;
  }
  .mx-wd-lg-350p {
    max-width: 350%;
  }
  .mn-wd-lg-350p {
    min-width: 350%;
  }
  .wd-lg-350-f {
    width: 350px !important;
  }
  .wd-lg-350p-f {
    width: 350% !important;
  }
  .mx-wd-lg-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-lg-350p-f {
    min-width: 350% !important;
  }
  .wd-lg-400 {
    width: 400px;
  }
  .wd-lg-400p {
    width: 400%;
  }
  .mx-wd-lg-400p {
    max-width: 400%;
  }
  .mn-wd-lg-400p {
    min-width: 400%;
  }
  .wd-lg-400-f {
    width: 400px !important;
  }
  .wd-lg-400p-f {
    width: 400% !important;
  }
  .mx-wd-lg-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-lg-400p-f {
    min-width: 400% !important;
  }
  .wd-lg-450 {
    width: 450px;
  }
  .wd-lg-450p {
    width: 450%;
  }
  .mx-wd-lg-450p {
    max-width: 450%;
  }
  .mn-wd-lg-450p {
    min-width: 450%;
  }
  .wd-lg-450-f {
    width: 450px !important;
  }
  .wd-lg-450p-f {
    width: 450% !important;
  }
  .mx-wd-lg-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-lg-450p-f {
    min-width: 450% !important;
  }
  .wd-lg-500 {
    width: 500px;
  }
  .wd-lg-500p {
    width: 500%;
  }
  .mx-wd-lg-500p {
    max-width: 500%;
  }
  .mn-wd-lg-500p {
    min-width: 500%;
  }
  .wd-lg-500-f {
    width: 500px !important;
  }
  .wd-lg-500p-f {
    width: 500% !important;
  }
  .mx-wd-lg-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-lg-500p-f {
    min-width: 500% !important;
  }
  .wd-lg-550 {
    width: 550px;
  }
  .wd-lg-550p {
    width: 550%;
  }
  .mx-wd-lg-550p {
    max-width: 550%;
  }
  .mn-wd-lg-550p {
    min-width: 550%;
  }
  .wd-lg-550-f {
    width: 550px !important;
  }
  .wd-lg-550p-f {
    width: 550% !important;
  }
  .mx-wd-lg-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-lg-550p-f {
    min-width: 550% !important;
  }
  .wd-lg-600 {
    width: 600px;
  }
  .wd-lg-600p {
    width: 600%;
  }
  .mx-wd-lg-600p {
    max-width: 600%;
  }
  .mn-wd-lg-600p {
    min-width: 600%;
  }
  .wd-lg-600-f {
    width: 600px !important;
  }
  .wd-lg-600p-f {
    width: 600% !important;
  }
  .mx-wd-lg-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-lg-600p-f {
    min-width: 600% !important;
  }
  .wd-lg-650 {
    width: 650px;
  }
  .wd-lg-650p {
    width: 650%;
  }
  .mx-wd-lg-650p {
    max-width: 650%;
  }
  .mn-wd-lg-650p {
    min-width: 650%;
  }
  .wd-lg-650-f {
    width: 650px !important;
  }
  .wd-lg-650p-f {
    width: 650% !important;
  }
  .mx-wd-lg-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-lg-650p-f {
    min-width: 650% !important;
  }
  .wd-lg-700 {
    width: 700px;
  }
  .wd-lg-700p {
    width: 700%;
  }
  .mx-wd-lg-700p {
    max-width: 700%;
  }
  .mn-wd-lg-700p {
    min-width: 700%;
  }
  .wd-lg-700-f {
    width: 700px !important;
  }
  .wd-lg-700p-f {
    width: 700% !important;
  }
  .mx-wd-lg-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-lg-700p-f {
    min-width: 700% !important;
  }
  .wd-lg-750 {
    width: 750px;
  }
  .wd-lg-750p {
    width: 750%;
  }
  .mx-wd-lg-750p {
    max-width: 750%;
  }
  .mn-wd-lg-750p {
    min-width: 750%;
  }
  .wd-lg-750-f {
    width: 750px !important;
  }
  .wd-lg-750p-f {
    width: 750% !important;
  }
  .mx-wd-lg-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-lg-750p-f {
    min-width: 750% !important;
  }
  .wd-lg-800 {
    width: 800px;
  }
  .wd-lg-800p {
    width: 800%;
  }
  .mx-wd-lg-800p {
    max-width: 800%;
  }
  .mn-wd-lg-800p {
    min-width: 800%;
  }
  .wd-lg-800-f {
    width: 800px !important;
  }
  .wd-lg-800p-f {
    width: 800% !important;
  }
  .mx-wd-lg-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-lg-800p-f {
    min-width: 800% !important;
  }
  .wd-lg-850 {
    width: 850px;
  }
  .wd-lg-850p {
    width: 850%;
  }
  .mx-wd-lg-850p {
    max-width: 850%;
  }
  .mn-wd-lg-850p {
    min-width: 850%;
  }
  .wd-lg-850-f {
    width: 850px !important;
  }
  .wd-lg-850p-f {
    width: 850% !important;
  }
  .mx-wd-lg-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-lg-850p-f {
    min-width: 850% !important;
  }
  .wd-lg-900 {
    width: 900px;
  }
  .wd-lg-900p {
    width: 900%;
  }
  .mx-wd-lg-900p {
    max-width: 900%;
  }
  .mn-wd-lg-900p {
    min-width: 900%;
  }
  .wd-lg-900-f {
    width: 900px !important;
  }
  .wd-lg-900p-f {
    width: 900% !important;
  }
  .mx-wd-lg-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-lg-900p-f {
    min-width: 900% !important;
  }
  .wd-lg-950 {
    width: 950px;
  }
  .wd-lg-950p {
    width: 950%;
  }
  .mx-wd-lg-950p {
    max-width: 950%;
  }
  .mn-wd-lg-950p {
    min-width: 950%;
  }
  .wd-lg-950-f {
    width: 950px !important;
  }
  .wd-lg-950p-f {
    width: 950% !important;
  }
  .mx-wd-lg-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-lg-950p-f {
    min-width: 950% !important;
  }
  .wd-lg-1000 {
    width: 1000px;
  }
  .wd-lg-1000p {
    width: 1000%;
  }
  .mx-wd-lg-1000p {
    max-width: 1000%;
  }
  .mn-wd-lg-1000p {
    min-width: 1000%;
  }
  .wd-lg-1000-f {
    width: 1000px !important;
  }
  .wd-lg-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-lg-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-lg-1000p-f {
    min-width: 1000% !important;
  }
  .wd-lg-auto {
    width: auto;
  }
  .wd-lg-auto-f {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .wd-xl-5 {
    width: 5px;
  }
  .wd-xl-5p {
    width: 5%;
  }
  .mx-wd-xl-5p {
    max-width: 5%;
  }
  .mn-wd-xl-5p {
    min-width: 5%;
  }
  .wd-xl-5-f {
    width: 5px !important;
  }
  .wd-xl-5p-f {
    width: 5% !important;
  }
  .mx-wd-xl-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-xl-5p-f {
    min-width: 5% !important;
  }
  .wd-xl-10 {
    width: 10px;
  }
  .wd-xl-10p {
    width: 10%;
  }
  .mx-wd-xl-10p {
    max-width: 10%;
  }
  .mn-wd-xl-10p {
    min-width: 10%;
  }
  .wd-xl-10-f {
    width: 10px !important;
  }
  .wd-xl-10p-f {
    width: 10% !important;
  }
  .mx-wd-xl-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-xl-10p-f {
    min-width: 10% !important;
  }
  .wd-xl-15 {
    width: 15px;
  }
  .wd-xl-15p {
    width: 15%;
  }
  .mx-wd-xl-15p {
    max-width: 15%;
  }
  .mn-wd-xl-15p {
    min-width: 15%;
  }
  .wd-xl-15-f {
    width: 15px !important;
  }
  .wd-xl-15p-f {
    width: 15% !important;
  }
  .mx-wd-xl-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-xl-15p-f {
    min-width: 15% !important;
  }
  .wd-xl-20 {
    width: 20px;
  }
  .wd-xl-20p {
    width: 20%;
  }
  .mx-wd-xl-20p {
    max-width: 20%;
  }
  .mn-wd-xl-20p {
    min-width: 20%;
  }
  .wd-xl-20-f {
    width: 20px !important;
  }
  .wd-xl-20p-f {
    width: 20% !important;
  }
  .mx-wd-xl-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-xl-20p-f {
    min-width: 20% !important;
  }
  .wd-xl-25 {
    width: 25px;
  }
  .wd-xl-25p {
    width: 25%;
  }
  .mx-wd-xl-25p {
    max-width: 25%;
  }
  .mn-wd-xl-25p {
    min-width: 25%;
  }
  .wd-xl-25-f {
    width: 25px !important;
  }
  .wd-xl-25p-f {
    width: 25% !important;
  }
  .mx-wd-xl-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-xl-25p-f {
    min-width: 25% !important;
  }
  .wd-xl-30 {
    width: 30px;
  }
  .wd-xl-30p {
    width: 30%;
  }
  .mx-wd-xl-30p {
    max-width: 30%;
  }
  .mn-wd-xl-30p {
    min-width: 30%;
  }
  .wd-xl-30-f {
    width: 30px !important;
  }
  .wd-xl-30p-f {
    width: 30% !important;
  }
  .mx-wd-xl-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-xl-30p-f {
    min-width: 30% !important;
  }
  .wd-xl-35 {
    width: 35px;
  }
  .wd-xl-35p {
    width: 35%;
  }
  .mx-wd-xl-35p {
    max-width: 35%;
  }
  .mn-wd-xl-35p {
    min-width: 35%;
  }
  .wd-xl-35-f {
    width: 35px !important;
  }
  .wd-xl-35p-f {
    width: 35% !important;
  }
  .mx-wd-xl-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-xl-35p-f {
    min-width: 35% !important;
  }
  .wd-xl-40 {
    width: 40px;
  }
  .wd-xl-40p {
    width: 40%;
  }
  .mx-wd-xl-40p {
    max-width: 40%;
  }
  .mn-wd-xl-40p {
    min-width: 40%;
  }
  .wd-xl-40-f {
    width: 40px !important;
  }
  .wd-xl-40p-f {
    width: 40% !important;
  }
  .mx-wd-xl-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-xl-40p-f {
    min-width: 40% !important;
  }
  .wd-xl-45 {
    width: 45px;
  }
  .wd-xl-45p {
    width: 45%;
  }
  .mx-wd-xl-45p {
    max-width: 45%;
  }
  .mn-wd-xl-45p {
    min-width: 45%;
  }
  .wd-xl-45-f {
    width: 45px !important;
  }
  .wd-xl-45p-f {
    width: 45% !important;
  }
  .mx-wd-xl-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-xl-45p-f {
    min-width: 45% !important;
  }
  .wd-xl-50 {
    width: 50px;
  }
  .wd-xl-50p {
    width: 50%;
  }
  .mx-wd-xl-50p {
    max-width: 50%;
  }
  .mn-wd-xl-50p {
    min-width: 50%;
  }
  .wd-xl-50-f {
    width: 50px !important;
  }
  .wd-xl-50p-f {
    width: 50% !important;
  }
  .mx-wd-xl-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-xl-50p-f {
    min-width: 50% !important;
  }
  .wd-xl-55 {
    width: 55px;
  }
  .wd-xl-55p {
    width: 55%;
  }
  .mx-wd-xl-55p {
    max-width: 55%;
  }
  .mn-wd-xl-55p {
    min-width: 55%;
  }
  .wd-xl-55-f {
    width: 55px !important;
  }
  .wd-xl-55p-f {
    width: 55% !important;
  }
  .mx-wd-xl-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-xl-55p-f {
    min-width: 55% !important;
  }
  .wd-xl-60 {
    width: 60px;
  }
  .wd-xl-60p {
    width: 60%;
  }
  .mx-wd-xl-60p {
    max-width: 60%;
  }
  .mn-wd-xl-60p {
    min-width: 60%;
  }
  .wd-xl-60-f {
    width: 60px !important;
  }
  .wd-xl-60p-f {
    width: 60% !important;
  }
  .mx-wd-xl-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-xl-60p-f {
    min-width: 60% !important;
  }
  .wd-xl-65 {
    width: 65px;
  }
  .wd-xl-65p {
    width: 65%;
  }
  .mx-wd-xl-65p {
    max-width: 65%;
  }
  .mn-wd-xl-65p {
    min-width: 65%;
  }
  .wd-xl-65-f {
    width: 65px !important;
  }
  .wd-xl-65p-f {
    width: 65% !important;
  }
  .mx-wd-xl-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-xl-65p-f {
    min-width: 65% !important;
  }
  .wd-xl-70 {
    width: 70px;
  }
  .wd-xl-70p {
    width: 70%;
  }
  .mx-wd-xl-70p {
    max-width: 70%;
  }
  .mn-wd-xl-70p {
    min-width: 70%;
  }
  .wd-xl-70-f {
    width: 70px !important;
  }
  .wd-xl-70p-f {
    width: 70% !important;
  }
  .mx-wd-xl-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-xl-70p-f {
    min-width: 70% !important;
  }
  .wd-xl-75 {
    width: 75px;
  }
  .wd-xl-75p {
    width: 75%;
  }
  .mx-wd-xl-75p {
    max-width: 75%;
  }
  .mn-wd-xl-75p {
    min-width: 75%;
  }
  .wd-xl-75-f {
    width: 75px !important;
  }
  .wd-xl-75p-f {
    width: 75% !important;
  }
  .mx-wd-xl-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-xl-75p-f {
    min-width: 75% !important;
  }
  .wd-xl-80 {
    width: 80px;
  }
  .wd-xl-80p {
    width: 80%;
  }
  .mx-wd-xl-80p {
    max-width: 80%;
  }
  .mn-wd-xl-80p {
    min-width: 80%;
  }
  .wd-xl-80-f {
    width: 80px !important;
  }
  .wd-xl-80p-f {
    width: 80% !important;
  }
  .mx-wd-xl-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-xl-80p-f {
    min-width: 80% !important;
  }
  .wd-xl-85 {
    width: 85px;
  }
  .wd-xl-85p {
    width: 85%;
  }
  .mx-wd-xl-85p {
    max-width: 85%;
  }
  .mn-wd-xl-85p {
    min-width: 85%;
  }
  .wd-xl-85-f {
    width: 85px !important;
  }
  .wd-xl-85p-f {
    width: 85% !important;
  }
  .mx-wd-xl-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-xl-85p-f {
    min-width: 85% !important;
  }
  .wd-xl-90 {
    width: 90px;
  }
  .wd-xl-90p {
    width: 90%;
  }
  .mx-wd-xl-90p {
    max-width: 90%;
  }
  .mn-wd-xl-90p {
    min-width: 90%;
  }
  .wd-xl-90-f {
    width: 90px !important;
  }
  .wd-xl-90p-f {
    width: 90% !important;
  }
  .mx-wd-xl-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-xl-90p-f {
    min-width: 90% !important;
  }
  .wd-xl-95 {
    width: 95px;
  }
  .wd-xl-95p {
    width: 95%;
  }
  .mx-wd-xl-95p {
    max-width: 95%;
  }
  .mn-wd-xl-95p {
    min-width: 95%;
  }
  .wd-xl-95-f {
    width: 95px !important;
  }
  .wd-xl-95p-f {
    width: 95% !important;
  }
  .mx-wd-xl-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-xl-95p-f {
    min-width: 95% !important;
  }
  .wd-xl-100 {
    width: 100px;
  }
  .wd-xl-100p {
    width: 100%;
  }
  .mx-wd-xl-100p {
    max-width: 100%;
  }
  .mn-wd-xl-100p {
    min-width: 100%;
  }
  .wd-xl-100-f {
    width: 100px !important;
  }
  .wd-xl-100p-f {
    width: 100% !important;
  }
  .mx-wd-xl-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-xl-100p-f {
    min-width: 100% !important;
  }
  .wd-xl-150 {
    width: 150px;
  }
  .wd-xl-150p {
    width: 150%;
  }
  .mx-wd-xl-150p {
    max-width: 150%;
  }
  .mn-wd-xl-150p {
    min-width: 150%;
  }
  .wd-xl-150-f {
    width: 150px !important;
  }
  .wd-xl-150p-f {
    width: 150% !important;
  }
  .mx-wd-xl-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-xl-150p-f {
    min-width: 150% !important;
  }
  .wd-xl-200 {
    width: 200px;
  }
  .wd-xl-200p {
    width: 200%;
  }
  .mx-wd-xl-200p {
    max-width: 200%;
  }
  .mn-wd-xl-200p {
    min-width: 200%;
  }
  .wd-xl-200-f {
    width: 200px !important;
  }
  .wd-xl-200p-f {
    width: 200% !important;
  }
  .mx-wd-xl-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-xl-200p-f {
    min-width: 200% !important;
  }
  .wd-xl-250 {
    width: 250px;
  }
  .wd-xl-250p {
    width: 250%;
  }
  .mx-wd-xl-250p {
    max-width: 250%;
  }
  .mn-wd-xl-250p {
    min-width: 250%;
  }
  .wd-xl-250-f {
    width: 250px !important;
  }
  .wd-xl-250p-f {
    width: 250% !important;
  }
  .mx-wd-xl-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-xl-250p-f {
    min-width: 250% !important;
  }
  .wd-xl-300 {
    width: 300px;
  }
  .wd-xl-300p {
    width: 300%;
  }
  .mx-wd-xl-300p {
    max-width: 300%;
  }
  .mn-wd-xl-300p {
    min-width: 300%;
  }
  .wd-xl-300-f {
    width: 300px !important;
  }
  .wd-xl-300p-f {
    width: 300% !important;
  }
  .mx-wd-xl-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-xl-300p-f {
    min-width: 300% !important;
  }
  .wd-xl-350 {
    width: 350px;
  }
  .wd-xl-350p {
    width: 350%;
  }
  .mx-wd-xl-350p {
    max-width: 350%;
  }
  .mn-wd-xl-350p {
    min-width: 350%;
  }
  .wd-xl-350-f {
    width: 350px !important;
  }
  .wd-xl-350p-f {
    width: 350% !important;
  }
  .mx-wd-xl-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-xl-350p-f {
    min-width: 350% !important;
  }
  .wd-xl-400 {
    width: 400px;
  }
  .wd-xl-400p {
    width: 400%;
  }
  .mx-wd-xl-400p {
    max-width: 400%;
  }
  .mn-wd-xl-400p {
    min-width: 400%;
  }
  .wd-xl-400-f {
    width: 400px !important;
  }
  .wd-xl-400p-f {
    width: 400% !important;
  }
  .mx-wd-xl-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-xl-400p-f {
    min-width: 400% !important;
  }
  .wd-xl-450 {
    width: 450px;
  }
  .wd-xl-450p {
    width: 450%;
  }
  .mx-wd-xl-450p {
    max-width: 450%;
  }
  .mn-wd-xl-450p {
    min-width: 450%;
  }
  .wd-xl-450-f {
    width: 450px !important;
  }
  .wd-xl-450p-f {
    width: 450% !important;
  }
  .mx-wd-xl-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-xl-450p-f {
    min-width: 450% !important;
  }
  .wd-xl-500 {
    width: 500px;
  }
  .wd-xl-500p {
    width: 500%;
  }
  .mx-wd-xl-500p {
    max-width: 500%;
  }
  .mn-wd-xl-500p {
    min-width: 500%;
  }
  .wd-xl-500-f {
    width: 500px !important;
  }
  .wd-xl-500p-f {
    width: 500% !important;
  }
  .mx-wd-xl-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-xl-500p-f {
    min-width: 500% !important;
  }
  .wd-xl-550 {
    width: 550px;
  }
  .wd-xl-550p {
    width: 550%;
  }
  .mx-wd-xl-550p {
    max-width: 550%;
  }
  .mn-wd-xl-550p {
    min-width: 550%;
  }
  .wd-xl-550-f {
    width: 550px !important;
  }
  .wd-xl-550p-f {
    width: 550% !important;
  }
  .mx-wd-xl-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-xl-550p-f {
    min-width: 550% !important;
  }
  .wd-xl-600 {
    width: 600px;
  }
  .wd-xl-600p {
    width: 600%;
  }
  .mx-wd-xl-600p {
    max-width: 600%;
  }
  .mn-wd-xl-600p {
    min-width: 600%;
  }
  .wd-xl-600-f {
    width: 600px !important;
  }
  .wd-xl-600p-f {
    width: 600% !important;
  }
  .mx-wd-xl-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-xl-600p-f {
    min-width: 600% !important;
  }
  .wd-xl-650 {
    width: 650px;
  }
  .wd-xl-650p {
    width: 650%;
  }
  .mx-wd-xl-650p {
    max-width: 650%;
  }
  .mn-wd-xl-650p {
    min-width: 650%;
  }
  .wd-xl-650-f {
    width: 650px !important;
  }
  .wd-xl-650p-f {
    width: 650% !important;
  }
  .mx-wd-xl-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-xl-650p-f {
    min-width: 650% !important;
  }
  .wd-xl-700 {
    width: 700px;
  }
  .wd-xl-700p {
    width: 700%;
  }
  .mx-wd-xl-700p {
    max-width: 700%;
  }
  .mn-wd-xl-700p {
    min-width: 700%;
  }
  .wd-xl-700-f {
    width: 700px !important;
  }
  .wd-xl-700p-f {
    width: 700% !important;
  }
  .mx-wd-xl-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-xl-700p-f {
    min-width: 700% !important;
  }
  .wd-xl-750 {
    width: 750px;
  }
  .wd-xl-750p {
    width: 750%;
  }
  .mx-wd-xl-750p {
    max-width: 750%;
  }
  .mn-wd-xl-750p {
    min-width: 750%;
  }
  .wd-xl-750-f {
    width: 750px !important;
  }
  .wd-xl-750p-f {
    width: 750% !important;
  }
  .mx-wd-xl-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-xl-750p-f {
    min-width: 750% !important;
  }
  .wd-xl-800 {
    width: 800px;
  }
  .wd-xl-800p {
    width: 800%;
  }
  .mx-wd-xl-800p {
    max-width: 800%;
  }
  .mn-wd-xl-800p {
    min-width: 800%;
  }
  .wd-xl-800-f {
    width: 800px !important;
  }
  .wd-xl-800p-f {
    width: 800% !important;
  }
  .mx-wd-xl-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-xl-800p-f {
    min-width: 800% !important;
  }
  .wd-xl-850 {
    width: 850px;
  }
  .wd-xl-850p {
    width: 850%;
  }
  .mx-wd-xl-850p {
    max-width: 850%;
  }
  .mn-wd-xl-850p {
    min-width: 850%;
  }
  .wd-xl-850-f {
    width: 850px !important;
  }
  .wd-xl-850p-f {
    width: 850% !important;
  }
  .mx-wd-xl-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-xl-850p-f {
    min-width: 850% !important;
  }
  .wd-xl-900 {
    width: 900px;
  }
  .wd-xl-900p {
    width: 900%;
  }
  .mx-wd-xl-900p {
    max-width: 900%;
  }
  .mn-wd-xl-900p {
    min-width: 900%;
  }
  .wd-xl-900-f {
    width: 900px !important;
  }
  .wd-xl-900p-f {
    width: 900% !important;
  }
  .mx-wd-xl-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-xl-900p-f {
    min-width: 900% !important;
  }
  .wd-xl-950 {
    width: 950px;
  }
  .wd-xl-950p {
    width: 950%;
  }
  .mx-wd-xl-950p {
    max-width: 950%;
  }
  .mn-wd-xl-950p {
    min-width: 950%;
  }
  .wd-xl-950-f {
    width: 950px !important;
  }
  .wd-xl-950p-f {
    width: 950% !important;
  }
  .mx-wd-xl-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-xl-950p-f {
    min-width: 950% !important;
  }
  .wd-xl-1000 {
    width: 1000px;
  }
  .wd-xl-1000p {
    width: 1000%;
  }
  .mx-wd-xl-1000p {
    max-width: 1000%;
  }
  .mn-wd-xl-1000p {
    min-width: 1000%;
  }
  .wd-xl-1000-f {
    width: 1000px !important;
  }
  .wd-xl-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-xl-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-xl-1000p-f {
    min-width: 1000% !important;
  }
  .wd-xl-auto {
    width: auto;
  }
  .wd-xl-auto {
    width: auto !important;
  }
}

.bg-twitter {
  background: social-color(twitter);
}

.bg-facebook {
  background: social-color(facebook);
}

.bg-google {
  background: social-color(google);
}

.bg-linkedin {
  background: social-color(linkedin);
}

.bg-pinterest {
  background: social-color(pinterest);
}

.bg-youtube {
  background: social-color(youtube);
}

.bg-github {
  background: social-color(github);
}

.bg-behance {
  background: social-color(behance);
}

.bg-dribbble {
  background: social-color(dribbble);
}

.bg-reddit {
  background: social-color(reddit);
}

.bg-instagram {
  background: social-color(instagram);
}

.bg-gradient-primary {
  background: linear-gradient(230deg, #759bff, #843cf6);
}

.bg-gradient-secondary {
  background: linear-gradient(to right, #e7ebf0, #868e96);
}

.bg-gradient-success {
  background: linear-gradient(45deg, #7bffce, #30c93e);
}

.bg-gradient-info {
  background: linear-gradient(to bottom, #0e4cfd, #6a8eff);
}

.bg-gradient-warning {
  background: linear-gradient(135deg, #ffc480, #ff763b);
}

.bg-gradient-danger {
  background: linear-gradient(316deg, #fc5286, #fbaaa2);
}

.bg-gradient-light {
  background: linear-gradient(to right, #cfd9df 0%, #e2ebf0 100%);
}

.bg-gradient-dark {
  background: linear-gradient(to right, #7d7979 0%, #000000 100%);
}

body {
  margin: 0;
  padding: 0;
}

.form-control,
select,
.email-compose-fields .select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint,
.form-control:focus,
select:focus,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:focus,
.select2-container--default .select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

a,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  text-shadow: none;
}

[type="button"]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0;
}

input,
.form-control:focus,
select:focus,
.email-compose-fields
  .select2-container--default
  .select2-selection--multiple:focus,
.select2-container--default .select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
  outline-style: none;
}

textarea {
  resize: none;
  overflow-x: hidden;
}

.btn,
.fc .fc-button,
.swal2-modal .swal2-actions button,
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions .disabled a,
.btn-group.open .dropdown-toggle,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .open.fc-button-group
  .dropdown-toggle,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .open.fc-button-group
  .dropdown-toggle,
.btn:active,
.fc .fc-button:active,
.swal2-modal .swal2-actions button:active,
.wizard > .actions a:active,
.btn:focus,
.fc .fc-button:focus,
.swal2-modal .swal2-actions button:focus,
.wizard > .actions a:focus,
.btn:hover,
.fc .fc-button:hover,
.swal2-modal .swal2-actions button:hover,
.wizard > .actions a:hover,
.btn:visited,
.fc .fc-button:visited,
.swal2-modal .swal2-actions button:visited,
.wizard > .actions a:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:hover,
input:focus,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:visited {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn.active.focus,
.fc .active.focus.fc-button,
.swal2-modal .swal2-actions button.active.focus,
.wizard > .actions a.active.focus,
.btn.active:focus,
.fc .active.fc-button:focus,
.swal2-modal .swal2-actions button.active:focus,
.wizard > .actions a.active:focus,
.btn.focus,
.fc .focus.fc-button,
.swal2-modal .swal2-actions button.focus,
.wizard > .actions a.focus,
.btn:active.focus,
.fc .fc-button:active.focus,
.swal2-modal .swal2-actions button:active.focus,
.wizard > .actions a:active.focus,
.btn:active:focus,
.fc .fc-button:active:focus,
.swal2-modal .swal2-actions button:active:focus,
.wizard > .actions a:active:focus,
.btn:focus,
.fc .fc-button:focus,
.swal2-modal .swal2-actions button:focus,
.wizard > .actions a:focus,
button,
button:active,
button:checked,
button:hover,
button:checked,
button:visited {
  outline: 0;
  outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0;
}

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: none;
}

a:focus,
input:focus {
  border-color: transparent;
  outline: none;
}

a:hover {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

@font-face {
  font-family: "Overpass";
  /* src: url("../../fonts/overpass/Overpass-Regular.eot"); */
  src: url("./fonts/overpass/Overpass-Regular.eot");
  src: url("./fonts/overpass/Overpass-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/overpass/Overpass-Regular.woff2") format("woff2"),
    url("./fonts/overpass/Overpass-Regular.woff") format("woff"),
    url("./fonts/overpass/Overpass-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("./fonts/overpass/Overpass-SemiBold.eot");
  src: url("./fonts/overpass/Overpass-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/overpass/Overpass-SemiBold.woff2") format("woff2"),
    url("./fonts/overpass/Overpass-SemiBold.woff") format("woff"),
    url("./fonts/overpass/Overpass-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("./fonts/overpass/Overpass-Italic.eot");
  src: url("./fonts/overpass/Overpass-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/overpass/Overpass-Italic.woff2") format("woff2"),
    url("./fonts/overpass/Overpass-Italic.woff") format("woff"),
    url("./fonts/overpass/Overpass-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Overpass";
  src: url("./fonts/overpass/Overpass-Light.eot");
  src: url("./fonts/overpass/Overpass-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/overpass/Overpass-Light.woff2") format("woff2"),
    url("./fonts/overpass/Overpass-Light.woff") format("woff"),
    url("./fonts/overpass/Overpass-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("./fonts/overpass/Overpass-ExtraBold.eot");
  src: url("./fonts/overpass/Overpass-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/overpass/Overpass-ExtraBold.woff2") format("woff2"),
    url("./fonts/overpass/Overpass-ExtraBold.woff") format("woff"),
    url("./fonts/overpass/Overpass-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("./fonts/overpass/Overpass-Bold.eot");
  src: url("./fonts/overpass/Overpass-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/overpass/Overpass-Bold.woff2") format("woff2"),
    url("./fonts/overpass/Overpass-Bold.woff") format("woff"),
    url("./fonts/overpass/Overpass-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("./fonts/overpass/Overpass-Black.eot");
  src: url("./fonts/overpass/Overpass-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/overpass/Overpass-Black.woff2") format("woff2"),
    url("./fonts/overpass/Overpass-Black.woff") format("woff"),
    url("./fonts/overpass/Overpass-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
/* Footer */

.footer {
  background: #f9fafb;
  padding: 15px 25px 15px;
  border-top: 1px solid #e8ebf1;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "Overpass", sans-serif;
  font-weight: 400;
  margin-top: auto;
}

.footer a {
  color: #727cf5;
  font-size: inherit;
}

.footer svg {
  width: 14px;
  height: 14px;
}

@media (max-width: 991px) {
  .footer {
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .footer {
    margin-left: 0;
    width: 100%;
  }
}

body,
html {
  padding-right: 0 !important;
}

body {
  margin: 0;
  color: #000;
  text-align: left;
  background-color: #f9fafb;
  height: 100%;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-full-screen {
  overflow: auto;
}

pre {
  background-color: #e8eff4;
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  font-family: "Overpass", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  border-radius: 4px;
}
/* Utilities */

.grid-margin {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 26px;
  height: 26px;
}

.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}

.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}

@media (min-width: 576px) {
  .border-rigwd-sm {
    border-right: 1px solid #e8ebf1;
  }
}

@media (min-width: 768px) {
  .border-rigwd-md {
    border-right: 1px solid #e8ebf1;
  }
}

@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid #e8ebf1;
  }
}

@media (min-width: 576px) {
  .border-left-sm {
    border-left: 1px solid #e8ebf1;
  }
}

@media (min-width: 768px) {
  .border-left-md {
    border-left: 1px solid #e8ebf1;
  }
}

@media (min-width: 992px) {
  .border-left-lg {
    border-left: 1px solid #e8ebf1;
  }
}

.icon-xs {
  width: 12px;
  height: 12px;
}

.icon-sm {
  width: 14px;
  height: 14px;
}

.icon-md {
  width: 16px;
  height: 16px;
}

.icon-lg,
.data-icon-card-primary .background-icon i,
.data-icon-card-secondary .background-icon i,
.data-icon-card-success .background-icon i,
.data-icon-card-info .background-icon i,
.data-icon-card-warning .background-icon i,
.data-icon-card-danger .background-icon i,
.data-icon-card-light .background-icon i,
.data-icon-card-dark .background-icon i,
.data-icon-card-primary-muted .background-icon i,
.data-icon-card-info-muted .background-icon i,
.data-icon-card-danger-muted .background-icon i {
  width: 20px;
  height: 20px;
}

.icon-xl {
  width: 26px;
  height: 26px;
}

.icon-xxl {
  width: 40px;
  height: 40px;
}

.text-gray,
.card .card-subtitle {
  color: #8c8c8c;
}

.text-black {
  color: #000;
}

.text-small {
  font-size: 12px;
}

.flex-grow {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.text-nowrap {
  white-space: nowrap;
}

.tx-10 {
  font-size: 10px;
}

.tx-11 {
  font-size: 11px;
}

.tx-12 {
  font-size: 12px;
}

.tx-13 {
  font-size: 13px;
  line-height: 1.3;
}

.tx-14 {
  font-size: 14px;
}

.tx-80 {
  font-size: 80px;
}

.pt-1px {
  padding-top: 1px;
}

.pt-2px {
  padding-top: 2px;
}

.pt-3px {
  padding-top: 3px;
}

.pb-1px {
  padding-bottom: 1px;
}

.pb-2px {
  padding-bottom: 2px;
}

.pb-3px {
  padding-bottom: 3px;
}

.mt-1px {
  margin-top: 1px;
}

.mt-2px {
  margin-top: 2px;
}

.mt-3px {
  margin-top: 3px;
}

.mb-1px {
  margin-bottom: 1px;
}

.mb-2px {
  margin-bottom: 2px;
}

.mb-3px {
  margin-bottom: 3px;
}

.w-15 {
  width: 15%;
}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.w-90 {
  width: 90%;
}

.border-top {
  border-top: 1px solid #e8ebf1;
}

.border-right {
  border-right: 1px solid #e8ebf1;
}

.border-bottom {
  border-bottom: 1px solid #e8ebf1;
}

.border-left {
  border-left: 1px solid #e8ebf1;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-x {
  border-left: 1px solid #e8ebf1;
  border-right: 1px solid #e8ebf1;
}

.border-y {
  border-top: 1px solid #e8ebf1;
  border-bottom: 1px solid #e8ebf1;
}

@media (min-width: 576px) {
  .border-sm-top {
    border-top: 1px solid #e8ebf1;
  }
  .border-sm-right {
    border-right: 1px solid #e8ebf1;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #e8ebf1;
  }
  .border-sm-left {
    border-left: 1px solid #e8ebf1;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-right-0 {
    border-right: 0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-left-0 {
    border-left: 0 !important;
  }
  .border-sm-x {
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
  }
  .border-sm-y {
    border-top: 1px solid #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
  }
}

@media (min-width: 768px) {
  .border-md-top {
    border-top: 1px solid #e8ebf1;
  }
  .border-md-right {
    border-right: 1px solid #e8ebf1;
  }
  .border-md-bottom {
    border-bottom: 1px solid #e8ebf1;
  }
  .border-md-left {
    border-left: 1px solid #e8ebf1;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-right-0 {
    border-right: 0 !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-left-0 {
    border-left: 0 !important;
  }
  .border-md-x {
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
  }
  .border-md-y {
    border-top: 1px solid #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
  }
}

@media (min-width: 992px) {
  .border-lg-top {
    border-top: 1px solid #e8ebf1;
  }
  .border-lg-right {
    border-right: 1px solid #e8ebf1;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #e8ebf1;
  }
  .border-lg-left {
    border-left: 1px solid #e8ebf1;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-right-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-left-0 {
    border-left: 0 !important;
  }
  .border-lg-x {
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
  }
  .border-lg-y {
    border-top: 1px solid #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
  }
}

@media (min-width: 1200px) {
  .border-xl-top {
    border-top: 1px solid #e8ebf1;
  }
  .border-xl-right {
    border-right: 1px solid #e8ebf1;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #e8ebf1;
  }
  .border-xl-left {
    border-left: 1px solid #e8ebf1;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-right-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-left-0 {
    border-left: 0 !important;
  }
  .border-xl-x {
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
  }
  .border-xl-y {
    border-top: 1px solid #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
  }
}

.ht-3 {
  height: 3px;
}

.ht-5 {
  height: 5px;
}

.ht-10 {
  height: 10px;
}

.ht-15 {
  height: 15px;
}

.ht-12 {
  height: 12px;
}

.ht-20 {
  height: 20px;
}

.ht-25 {
  height: 25px;
}

.ht-30 {
  height: 30px;
}

.ht-35 {
  height: 35px;
}

.ht-40 {
  height: 40px;
}

.ht-45 {
  height: 45px;
}

.ht-50 {
  height: 50px;
}

.ht-55 {
  height: 55px;
}

.ht-60 {
  height: 60px;
}

.ht-65 {
  height: 65px;
}

.ht-70 {
  height: 70px;
}

.ht-75 {
  height: 75px;
}

.ht-80 {
  height: 80px;
}

.ht-85 {
  height: 85px;
}

.ht-90 {
  height: 90px;
}

.ht-95 {
  height: 95px;
}

.ht-100 {
  height: 100px;
}

.wd-5 {
  width: 5px;
}

.wd-10 {
  width: 10px;
}

.wd-15 {
  width: 15px;
}

.wd-12 {
  width: 12px;
}

.wd-20 {
  width: 20px;
}

.wd-25 {
  width: 25px;
}

.wd-30 {
  width: 30px;
}

.wd-35 {
  width: 35px;
}

.wd-40 {
  width: 40px;
}

.wd-45 {
  width: 45px;
}

.wd-50 {
  width: 50px;
}

.wd-55 {
  width: 55px;
}

.wd-60 {
  width: 60px;
}

.wd-65 {
  width: 65px;
}

.wd-70 {
  width: 70px;
}

.wd-75 {
  width: 75px;
}

.wd-80 {
  width: 80px;
}

.wd-85 {
  width: 85px;
}

.wd-90 {
  width: 90px;
}

.wd-95 {
  width: 95px;
}

.wd-100 {
  width: 100px;
}

.bd {
  border: 1px solid #e8ebf1;
}

.bd-t {
  border-top: 1px solid #e8ebf1;
}

.bd-r {
  border-right: 1px solid #e8ebf1;
}

.bd-b {
  border-bottom: 1px solid #e8ebf1;
}

.bd-l {
  border-left: 1px solid #e8ebf1;
}

.bd-y {
  border-top: 1px solid #e8ebf1;
  border-bottom: 1px solid #e8ebf1;
}

.bd-x {
  border-left: 1px solid #e8ebf1;
  border-right: 1px solid #e8ebf1;
}

.bd-w-2 {
  border-width: 2px;
}

.bd-w-3 {
  border-width: 3px;
}

.bd-w-4 {
  border-width: 4px;
}

.bd-w-5 {
  border-width: 5px;
}

.bd-primary {
  border-color: #727cf5;
}

.bd-secondary {
  border-color: #7987a1;
}

.bd-success {
  border-color: #10b759;
}

.bd-info {
  border-color: #66d1d1;
}

.bd-warning {
  border-color: #fbbc06;
}

.bd-danger {
  border-color: #ff3366;
}

.bd-light {
  border-color: #ececec;
}

.bd-dark {
  border-color: #282f3a;
}

.bd-primary-muted {
  border-color: #b1cfec;
}

.bd-info-muted {
  border-color: #7ee5e5;
}

.bd-danger-muted {
  border-color: #f77eb9;
}
/* Demo Styles */

.main-content {
  color: #000;
  font-size: 16px;
}

.main-content > .page-title {
  margin-bottom: 1rem;
  font-weight: 400;
}

.main-content > h4 {
  margin-top: 1.5rem;
  margin-bottom: 0.875rem;
}

.main-content > h4::before {
  display: block;
  height: 5.4rem;
  margin-top: -6rem;
  content: "";
}

.main-content > hr {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main-content .example {
  font-size: 0.875rem;
  letter-spacing: normal;
  padding: 10px;
  background-color: #fff;
  border: 4px solid #e8ebf1;
  position: relative;
}

@media (min-width: 576px) {
  .main-content .example {
    padding: 25px;
  }
}

.main-content .highlight {
  position: relative;
  background-color: #fff;
  padding: 15px;
}

.main-content .highlight pre {
  padding: 15px;
  font-size: 0.875rem;
  font-family: "Overpass", sans-serif;
  background: transparent;
  line-height: 1.4;
  margin: 0;
}

.main-content .highlight pre code {
  font-family: "Overpass", sans-serif;
  padding: 0;
  tab-size: 8;
  color: #000;
  text-shadow: none;
}

.main-content .highlight pre code .token.url,
.main-content .highlight pre code .token.string,
.main-content .highlight pre code .token.entity,
.main-content .highlight pre code .token.operator {
  background: none;
}

.main-content .highlight .btn-clipboard {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 14px;
  padding: 4px 8px;
  background: #e7ebef;
}

@media (max-width: 991px) {
  .main-content .highlight .btn-clipboard {
    padding: 2px 6px;
  }
}

.main-content .highlight .btn-clipboard:hover {
  background: #e1e6eb;
}

.main-content .highlight .btn-clipboard svg {
  width: 18px;
  height: 18px;
}

@media (max-width: 991px) {
  .main-content .highlight .btn-clipboard svg {
    width: 15px;
    height: 15px;
  }
}

.example .btn-toolbar + .btn-toolbar {
  margin-top: 0.5rem;
}

.example .modal.static {
  position: static;
  display: block;
}

.example .navbar {
  position: relative;
  padding: 0.5rem 1rem;
  left: auto;
  width: 100%;
  height: auto;
  z-index: 9;
}

.example .navbar .navbar-brand {
  font-size: 1.25rem;
}

.example .progress + .progress {
  margin-top: 10px;
}

.example .perfect-scrollbar-example {
  position: relative;
  max-height: 250px;
  background: #fff;
}

.example .scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}

.example .scrollspy-example-2 {
  position: relative;
  height: 350px;
  overflow: auto;
}

.example nav .breadcrumb {
  margin-bottom: 0.75rem;
}

.example nav:last-child .breadcrumb {
  margin-bottom: 0;
}

.page-breadcrumb {
  margin-bottom: 15px;
}

.page-breadcrumb .breadcrumb {
  padding: 0;
  background: #f9fafb;
}

.noble-ui-logo {
  font-weight: 900;
  font-size: 25px;
  letter-spacing: -1px;
  color: #031a61;
}

.noble-ui-logo span {
  color: #727cf5;
  font-weight: 300;
}

.noble-ui-logo:hover {
  color: #031a61;
}

.noble-ui-logo.logo-light {
  color: #000;
}

.rtl .page-content .main-content .example .navbar {
  right: 0;
}
/* Typography */

body {
  font-family: "Overpass", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
  line-height: 1.5;
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.5rem;
}

h3,
.h3 {
  font-size: 1.875rem;
}

h4,
.h4 {
  font-size: 1.4rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.9375rem;
}

.display-1 {
  font-size: 3.75rem;
}

@media (max-width: 991px) {
  .display-1 {
    font-size: 3rem;
  }
}

.display-2 {
  font-size: 3.125rem;
}

@media (max-width: 991px) {
  .display-2 {
    font-size: 2.5rem;
  }
}

.display-3 {
  font-size: 2.5rem;
}

@media (max-width: 991px) {
  .display-3 {
    font-size: 2rem;
  }
}

.display-4 {
  font-size: 1.875rem;
}

@media (max-width: 991px) {
  .display-4 {
    font-size: 1.5rem;
  }
}

.display-5 {
  font-size: 1.25rem;
}

@media (max-width: 991px) {
  .display-5 {
    font-size: 1rem;
  }
}

.blockquote {
  padding: 1.25rem;
}

address p {
  margin-bottom: 0;
}

.blockquote-primary {
  border-color: #727cf5;
}

.blockquote-primary .blockquote-footer {
  color: #727cf5;
}

.blockquote-secondary {
  border-color: #7987a1;
}

.blockquote-secondary .blockquote-footer {
  color: #7987a1;
}

.blockquote-success {
  border-color: #10b759;
}

.blockquote-success .blockquote-footer {
  color: #10b759;
}

.blockquote-info {
  border-color: #66d1d1;
}

.blockquote-info .blockquote-footer {
  color: #66d1d1;
}

.blockquote-warning {
  border-color: #fbbc06;
}

.blockquote-warning .blockquote-footer {
  color: #fbbc06;
}

.blockquote-danger {
  border-color: #ff3366;
}

.blockquote-danger .blockquote-footer {
  color: #ff3366;
}

.blockquote-light {
  border-color: #ececec;
}

.blockquote-light .blockquote-footer {
  color: #ececec;
}

.blockquote-dark {
  border-color: #282f3a;
}

.blockquote-dark .blockquote-footer {
  color: #282f3a;
}

.blockquote-primary-muted {
  border-color: #b1cfec;
}

.blockquote-primary-muted .blockquote-footer {
  color: #b1cfec;
}

.blockquote-info-muted {
  border-color: #7ee5e5;
}

.blockquote-info-muted .blockquote-footer {
  color: #7ee5e5;
}

.blockquote-danger-muted {
  border-color: #f77eb9;
}

.blockquote-danger-muted .blockquote-footer {
  color: #f77eb9;
}

.error-page h1 {
  font-size: 12rem;
}

@media (max-width: 991px) {
  .error-page h1 {
    font-size: 8rem;
  }
}

.text-twitter,
.profile-page .profile-body .left-wrapper .social-links a.twitter {
  color: #2caae1;
}

.text-facebook {
  color: #3b579d;
}

.text-google {
  color: #dc4a38;
}

.text-linkedin {
  color: #0177b5;
}

.text-pinterest {
  color: #cc2127;
}

.text-youtube {
  color: #e52d27;
}

.text-github,
.profile-page .profile-body .left-wrapper .social-links a.github {
  color: #333333;
}

.text-behance {
  color: #1769ff;
}

.text-dribbble {
  color: #ea4c89;
}

.text-reddit {
  color: #ff4500;
}

.text-instagram,
.profile-page .profile-body .left-wrapper .social-links a.instagram {
  color: #fb3958;
}

.font-weight-medium {
  font-weight: 600;
}

.main-wrapper {
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
}

.main-wrapper .page-wrapper {
  min-height: 100vh;
  background: #eee;
  width: calc(100% - 240px);
  margin-left: 240px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-transition: margin 0.1s ease, width 0.1s ease;
  transition: margin 0.1s ease, width 0.1s ease;
}

.main-wrapper .bgblue {
  background: #054d96;
}

.main-wrapper .page-wrapper .page-content {
  flex-grow: 1;
  padding: 25px;
}

@media (max-width: 767px) {
  .main-wrapper .page-wrapper .page-content {
    padding: 25px 15px;
  }
}

.main-wrapper .page-wrapper.full-page {
  width: 100%;
  margin-left: 0;
}

.main-wrapper .page-wrapper.full-page .page-content {
  margin-top: 0;
}

@media (max-width: 991px) {
  .main-wrapper .page-wrapper {
    margin-left: 0;
    width: 100%;
  }
}

.page-content .content-nav-wrapper {
  padding: 0;
  position: fixed;
  top: calc(60px + 30px);
  right: 0;
  width: 220px;
  max-height: calc(100% - 60px - 51px);
  border-left: 1px solid #e8ebf1;
  display: none;
}

@media (min-width: 1200px) {
  .page-content .content-nav-wrapper {
    display: block;
  }
}

.page-content .content-nav-wrapper .content-nav {
  padding: 0px 25px;
}

.page-content .content-nav-wrapper .content-nav .nav-item .nav-link {
  padding: 0;
  height: 30px;
  white-space: nowrap;
  color: #383838;
  font-size: 0.875rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.navbar {
  width: calc(100% - 240px);
  height: 60px;
  background: #ffffff;
  border-bottom: 1px solid #f2f4f9;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  position: fixed;
  right: 0;
  left: 240px;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
}

@media (max-width: 991px) {
  .navbar {
    width: 100%;
    left: 0;
  }
  .navbar .navbar-content {
    width: calc(100% - 70px - 1px);
  }
}

.navbar .sidebar-toggler {
  height: 100%;
  border-right: 1px solid #e8ebf1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 25px;
  display: none;
}

.navbar .sidebar-toggler svg {
  width: 20px;
  height: 20px;
  color: #9b9b9b;
}

@media (max-width: 991px) {
  .navbar .sidebar-toggler {
    display: flex;
  }
}

.navbar .search-form {
  width: 100%;
  margin-right: 60px;
}

.navbar .search-form .input-group .input-group-prepend .input-group-text {
  padding: 0;
  border: 0;
  color: #535353;
  background: #fff;
}

.navbar .search-form .input-group .input-group-prepend .input-group-text svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.navbar .search-form .input-group .form-control,
.navbar .search-form .input-group select,
.navbar
  .search-form
  .input-group
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .navbar
  .search-form
  .input-group
  .select2-selection--multiple,
.navbar
  .search-form
  .input-group
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .navbar
  .search-form
  .input-group
  .select2-selection--single,
.navbar
  .search-form
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .navbar
  .search-form
  .input-group
  .select2-search__field,
.navbar .search-form .input-group .typeahead,
.navbar .search-form .input-group .tt-query,
.navbar .search-form .input-group .tt-hint {
  border: 0;
  background: #fff;
  padding: 0 0.8rem;
  margin-top: 3px;
}

.navbar .search-form .input-group .form-control::placeholder,
.navbar .search-form .input-group select::placeholder,
.navbar
  .search-form
  .input-group
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple::placeholder,
.email-compose-fields
  .select2-container--default
  .navbar
  .search-form
  .input-group
  .select2-selection--multiple::placeholder,
.navbar
  .search-form
  .input-group
  .select2-container--default
  .select2-selection--single::placeholder,
.select2-container--default
  .navbar
  .search-form
  .input-group
  .select2-selection--single::placeholder,
.navbar
  .search-form
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field::placeholder,
.select2-container--default
  .select2-selection--single
  .navbar
  .search-form
  .input-group
  .select2-search__field::placeholder,
.navbar .search-form .input-group .typeahead::placeholder,
.navbar .search-form .input-group .tt-query::placeholder,
.navbar .search-form .input-group .tt-hint::placeholder {
  color: #c0ccda;
}

.navbar .navbar-content {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 991px) {
  .navbar .navbar-content {
    width: calc(100% - 70px - 1px);
  }
}

.navbar .navbar-content .navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  margin-left: auto;
}

.navbar .navbar-content .navbar-nav .nav-item {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link {
  color: #535353;
  padding: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link:hover,
.navbar .navbar-content .navbar-nav .nav-item .nav-link[aria-expanded="true"] {
  color: #727cf5;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link::after {
  display: none;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link svg {
  width: 20px;
  height: 20px;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link .indicator {
  position: absolute;
  top: 0px;
  right: 2px;
}

.navbar .navbar-content .navbar-nav .nav-item .nav-link .indicator .circle {
  background: #727cf5;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item
  .nav-link
  .indicator
  .circle::before {
  background-color: #727cf5;
  content: "";
  display: table;
  border-radius: 50%;
  position: absolute;
}

@media (max-width: 767px) {
  .navbar .navbar-content .navbar-nav .nav-item.dropdown {
    position: static;
  }
}

.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
  max-width: 280px;
  position: absolute;
  right: -20px;
  left: auto;
  border-radius: 0;
  margin-top: 0;
  border: 1px solid #f2f4f9;
  box-shadow: 0 5px 10px 0 rgba(183, 192, 206, 0.2);
  font-size: 0.875rem;
}

.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu::before {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -7px;
  right: 28px;
  transform: rotate(45deg);
  border-top: 1px solid #f2f4f9;
  border-left: 1px solid #f2f4f9;
}

@media (max-width: 767px) {
  .navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
    right: 20px;
    width: calc(100% - 40px);
  }
  .navbar
    .navbar-content
    .navbar-nav
    .nav-item.dropdown
    .dropdown-menu::before {
    display: none;
  }
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu
  .dropdown-header {
  border-bottom: 1px solid #f2f4f9;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .apps
  a {
  color: #000;
  width: 65px;
  height: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .apps
  a
  svg {
  color: #686868;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .apps
  a
  p {
  font-size: 12px;
  margin-top: 5px;
  color: #686868;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .apps
  a:hover {
  background-color: #f3f3fe;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .apps
  a:hover
  svg {
  color: #727cf5;
}

.navbar .navbar-content .navbar-nav .nav-item.nav-apps .dropdown-menu,
.navbar .navbar-content .navbar-nav .nav-item.nav-notifications .dropdown-menu,
.navbar .navbar-content .navbar-nav .nav-item.nav-messages .dropdown-menu {
  padding: 0;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-header,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-header,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-header {
  padding: 12px 20px;
  font-size: 13px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body {
  padding: 5px;
  max-height: 300px;
  position: relative;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 16px;
  transition: background 0.2s ease-in-out;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover {
  background: #f8f8f8;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .arrow {
  color: #727cf5;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: #f7f7f7;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon
  svg {
  width: 15px;
  height: 15px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .figure
  img,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .figure
  img,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .figure
  img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1):hover {
  background: rgba(114, 124, 245, 0.07);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1):hover
  .arrow {
  color: #727cf5;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1)
  .icon {
  background: rgba(114, 124, 245, 0.1);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(1)
  .icon
  svg {
  color: #727cf5;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover {
  background: rgba(16, 183, 89, 0.07);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .arrow {
  color: #10b759;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon {
  background: rgba(16, 183, 89, 0.1);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon
  svg {
  color: #10b759;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover {
  background: rgba(255, 51, 102, 0.07);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover
  .arrow {
  color: #ff3366;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon {
  background: rgba(255, 51, 102, 0.1);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon
  svg {
  color: #ff3366;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover {
  background: rgba(251, 188, 6, 0.07);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover
  .arrow {
  color: #fbbc06;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon {
  background: rgba(251, 188, 6, 0.1);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon
  svg {
  color: #fbbc06;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover {
  background: rgba(102, 209, 209, 0.07);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover
  .arrow {
  color: #66d1d1;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon {
  background: rgba(102, 209, 209, 0.1);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon
  svg {
  color: #66d1d1;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6):hover {
  background: rgba(16, 183, 89, 0.07);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6):hover
  .arrow {
  color: #10b759;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6)
  .icon {
  background: rgba(16, 183, 89, 0.1);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(6)
  .icon
  svg {
  color: #10b759;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7):hover,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7):hover {
  background: rgba(255, 51, 102, 0.07);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7):hover
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7):hover
  .arrow {
  color: #ff3366;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7)
  .icon,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7)
  .icon {
  background: rgba(255, 51, 102, 0.1);
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7)
  .icon
  svg,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(7)
  .icon
  svg {
  color: #ff3366;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  margin-left: 15px;
  width: 200px;
  white-space: normal;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  p,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  p,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  p {
  margin-bottom: 0;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  .sub-text,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  .sub-text,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  .sub-text {
  font-size: 12px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .arrow,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .arrow {
  width: 15px;
  height: 15px;
  margin-left: auto;
  color: #ddd;
  transition: all 0.3s ease-out;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-apps
  .dropdown-menu
  .dropdown-footer,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-footer,
.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-footer {
  padding: 10px 20px;
  border-top: 1px solid #f2f4f9;
  font-size: 14px;
}

.navbar .navbar-content .navbar-nav .nav-item.nav-profile .nav-link img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.navbar .navbar-content .navbar-nav .nav-item.nav-profile .dropdown-menu {
  padding: 20px;
  min-width: 230px;
}

@media (max-width: 767px) {
  .navbar .navbar-content .navbar-nav .nav-item.nav-profile .dropdown-menu {
    width: auto;
  }
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-header {
  padding: 0;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-header
  .figure {
  position: relative;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-header
  .figure
  img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 3px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-header
  .info
  .name {
  font-size: 16px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-header
  .info
  .email {
  font-size: 13px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item {
  width: auto;
  margin: 0 0 5px 0;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item:last-child {
  margin-bottom: 0;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item
  .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  color: #383838;
  transition: color 0.2s ease-in-out;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item
  .nav-link
  svg {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item
  .nav-link
  span {
  font-size: 14px;
  margin-top: 2px;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item:hover
  .nav-link {
  color: #3764f8;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  button
  svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.sidebar {
  width: 240px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  -webkit-transition: width 0.1s ease, margin 0.1s ease-out;
  transition: width 0.1s ease, margin 0.1s ease-out;
  z-index: 999;
}

.sidebar .sidebar-header {
  background: #ffffff;
  height: 60px;
  border-bottom: 1px solid #f2f4f9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 25px;
  border-right: 1px solid #f2f4f9;
  z-index: 999;
  width: 100%;
  -webkit-transition: width 0.1s ease;
  transition: width 0.1s ease;
}

.sidebar-open .sidebar .sidebar-header {
  border-bottom: 1px solid #e8ebf1;
}

.sidebar .sidebar-header .sidebar-brand {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  font-weight: 900;
  font-size: 25px;
  letter-spacing: -1px;
  color: #031a61;
}

.sidebar .sidebar-header .sidebar-brand span {
  color: #727cf5;
  font-weight: 300;
}

.sidebar .sidebar-header .sidebar-toggler {
  cursor: pointer;
  width: 18px;
}

.sidebar .sidebar-header .sidebar-toggler span {
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 2px;
  background: #535353;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}

.sidebar .sidebar-header .sidebar-toggler span + span {
  margin-top: 4px;
}

.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(1) {
  -webkit-animation: ease 0.6s top forwards;
  animation: ease 0.6s top forwards;
}

.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(1) {
  -webkit-animation: ease 0.6s top-2 forwards;
  animation: ease 0.6s top-2 forwards;
}

.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(2) {
  -webkit-animation: ease 0.6s scaled forwards;
  animation: ease 0.6s scaled forwards;
}

.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(2) {
  -webkit-animation: ease 0.6s scaled-2 forwards;
  animation: ease 0.6s scaled-2 forwards;
}

.sidebar .sidebar-header .sidebar-toggler.active span:nth-child(3) {
  -webkit-animation: ease 0.6s bottom forwards;
  animation: ease 0.6s bottom forwards;
}

.sidebar .sidebar-header .sidebar-toggler.not-active span:nth-child(3) {
  -webkit-animation: ease 0.6s bottom-2 forwards;
  animation: ease 0.6s bottom-2 forwards;
}

@-webkit-keyframes top {
  0% {
    top: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    top: 6px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@keyframes top {
  0% {
    top: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    top: 6px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-webkit-keyframes top-2 {
  0% {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes top-2 {
  0% {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes bottom {
  0% {
    bottom: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    bottom: 6px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    bottom: 6px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    bottom: 6px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    bottom: 6px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@-webkit-keyframes bottom-2 {
  0% {
    bottom: 6px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 6px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes scaled {
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes scaled {
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes scaled-2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaled-2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.sidebar .sidebar-body {
  max-height: calc(100% - 60px);
  position: relative;
  border-right: 1px solid #f2f4f9;
  height: 100%;
  -webkit-box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
  background: #ffffff;
  overflow-y: auto;
}

.sidebar .sidebar-body .nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 25px 25px 50px 25px;
}

.sidebar .sidebar-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sidebar .sidebar-body::-webkit-scrollbar:vertical {
  width: 11px;
}

.sidebar .sidebar-body::-webkit-scrollbar:horizontal {
  height: 11px;
}

.sidebar .sidebar-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar .sidebar-body .nav .nav-item {
  position: relative;
}

.sidebar .sidebar-body .nav .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  height: 32px;
  white-space: nowrap;
  color: #000;
}

.sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
  width: 16px;
  height: 16px;
  fill: rgba(233, 236, 239, 0.21);
  position: absolute;
  color: inherit;
}

.sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  margin-left: 30px;
  font-size: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
  width: 14px;
  height: 14px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: inherit;
}

.sidebar .sidebar-body .nav .nav-item .nav-link .link-icon,
.sidebar .sidebar-body .nav .nav-item .nav-link .link-title,
.sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar .sidebar-body .nav .nav-item .nav-link[aria-expanded="true"] {
  color: #727cf5;
}

.sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link[aria-expanded="true"]
  .link-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}

.sidebar .sidebar-body .nav .nav-item.nav-category {
  color: #686868;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  height: 15px;
}

.sidebar .sidebar-body .nav .nav-item.nav-category:not(:first-child) {
  margin-top: 20px;
}

.sidebar .sidebar-body .nav .nav-item:hover .nav-link {
  color: #727cf5;
}

.sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-title {
  margin-left: 31px;
}

.sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-icon {
  color: #727cf5;
  fill: rgba(239, 243, 255, 0.5);
}

.sidebar .sidebar-body .nav .nav-item.active .nav-link {
  color: #727cf5;
}

.sidebar .sidebar-body .nav .nav-item.active .nav-link::before {
  content: "";
  width: 3px;
  height: 26px;
  background: #727cf5;
  position: absolute;
  left: -25px;
}

.sidebar .sidebar-body .nav .nav-item.active .nav-link .link-icon {
  fill: rgba(239, 243, 255, 0.5);
  color: #727cf5;
}

.sidebar .sidebar-body .nav.sub-menu {
  padding: 0 0 15px 33px;
}

.sidebar .sidebar-body .nav.sub-menu .nav-item {
  position: relative;
}

.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link {
  height: 25px;
  color: #000;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #a9b4ca;
  position: absolute;
  left: -29px;
  top: 10px;
  -webkit-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link.active {
  color: #727cf5;
}

.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link.active::before {
  border: 1px solid #727cf5;
  background: #727cf5;
}

.sidebar .sidebar-body .nav.sub-menu .nav-item:hover .nav-link {
  color: #727cf5;
  margin-left: 3px;
}

.sidebar .sidebar-body .nav.sub-menu .nav-item:hover .nav-link::before {
  border: 1px solid #727cf5;
  background: #727cf5;
}

@media (max-width: 991px) {
  .sidebar {
    z-index: 999;
    margin-left: -240px;
    visibility: hidden;
  }
  .sidebar-open .sidebar {
    margin-left: 0;
    visibility: visible;
  }
  .sidebar .sidebar-body .nav .nav-item {
    width: auto;
  }
  .sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
    -webkit-transition: none;
    transition: none;
    margin: 0;
  }
}

.sidebar-dark .sidebar .sidebar-header {
  background: #0c1427;
  border-bottom: 1px solid #344258;
  border-right: 1px solid #344258;
}

.sidebar-dark .sidebar .sidebar-header .sidebar-brand {
  color: #f8f9fc;
}

.sidebar-dark .sidebar .sidebar-header .sidebar-toggler span {
  background: #9fa2ab;
}

.sidebar-dark .sidebar .sidebar-body {
  background: #0c1427;
  border-right: 1px solid #344258;
}

.sidebar-dark .sidebar .sidebar-body .nav .nav-item.nav-category {
  color: #ffffff;
}

.sidebar-dark .sidebar .sidebar-body .nav .nav-item .nav-link {
  color: #bfc3ce;
}

.sidebar-dark .sidebar .sidebar-body .nav .nav-item .nav-link svg {
  fill: none;
}

.sidebar-dark
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link[aria-expanded="true"] {
  color: #727cf5;
}

.sidebar-dark .sidebar .sidebar-body .nav .nav-item:hover .nav-link svg,
.sidebar-dark .sidebar .sidebar-body .nav .nav-item.active .nav-link svg {
  fill: rgba(114, 124, 245, 0.2);
}

.sidebar-dark .sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-title,
.sidebar-dark
  .sidebar
  .sidebar-body
  .nav
  .nav-item.active
  .nav-link
  .link-title {
  color: #727cf5;
}

.settings-sidebar {
  position: fixed;
  right: -232px;
  top: 130px;
  width: 232px;
  background: #ffffff;
  -webkit-box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  z-index: 999;
  border-radius: 0 0 0 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.settings-open .settings-sidebar {
  right: 0;
}

.settings-sidebar .sidebar-body {
  position: relative;
  padding: 18px;
}

.settings-sidebar .sidebar-body .settings-sidebar-toggler {
  position: absolute;
  left: -44px;
  top: 0;
  padding: 12px;
  border-radius: 4px 0 0 4px;
  background: #ffffff;
  -webkit-box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
}

.settings-sidebar .sidebar-body .settings-sidebar-toggler svg {
  width: 20px;
  height: 20px;
  color: #686868;
}

.settings-sidebar .sidebar-body .theme-wrapper .theme-item {
  position: relative;
  display: block;
  margin-bottom: 19px;
  border-radius: 6px;
  border: 3px solid #ececec;
}

.settings-sidebar .sidebar-body .theme-wrapper .theme-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(114, 124, 245, 0);
}

.settings-sidebar .sidebar-body .theme-wrapper .theme-item:last-child {
  margin-bottom: 0;
}

.settings-sidebar .sidebar-body .theme-wrapper .theme-item.active {
  border: 3px solid #b9befa;
}

.settings-sidebar .sidebar-body .theme-wrapper .theme-item img {
  width: 100%;
  border-radius: 3px;
}

.settings-sidebar .sidebar-body .theme-wrapper .theme-item:hover::after {
  background: rgba(114, 124, 245, 0.2);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar-folded .sidebar .sidebar-header {
  width: 70px;
}

.sidebar-folded .sidebar .sidebar-header .sidebar-brand {
  display: none;
}

.sidebar-folded .page-wrapper {
  width: calc(100% - 70px);
  margin-left: 70px;
}

.sidebar-folded .page-wrapper .navbar {
  width: calc(100% - 70px);
  left: 70px;
  right: 0;
}

.sidebar-folded:not(.open-sidebar-folded) .sidebar {
  width: 70px;
}

.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-header {
  width: 70px;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-header
  .sidebar-brand {
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link
  .link-title,
.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item
  .nav-link
  .link-arrow {
  visibility: hidden;
  opacity: 0;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item.nav-category {
  visibility: hidden;
}

.sidebar-folded:not(.open-sidebar-folded)
  .sidebar
  .sidebar-body
  .nav
  .nav-item.nav-category::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #9b9b9b;
  position: absolute;
  top: 5px;
  left: 6px;
  visibility: visible;
}

.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav.sub-menu {
  display: none;
}

@media (max-width: 991px) {
  .sidebar-open .main-wrapper::before,
  .settings-open .main-wrapper::before {
    content: "";
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    -webkit-transition: all 3s ease;
    transition: all 3s ease;
    z-index: 980;
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}

.rtl .main-wrapper .sidebar {
  right: 0;
  left: auto;
}

@media (max-width: 991px) {
  .rtl .main-wrapper .sidebar {
    margin-right: -240px;
  }
  .sidebar-open.rtl .main-wrapper .sidebar {
    margin-right: 0;
  }
}

.rtl .main-wrapper .sidebar .sidebar-header {
  border-right: 0;
  border-left: 1px solid #f2f4f9;
}

.sidebar-dark.rtl .main-wrapper .sidebar .sidebar-header {
  border-left: 1px solid #344258;
}

.rtl .main-wrapper .sidebar .sidebar-body {
  direction: ltr;
}

.rtl .main-wrapper .sidebar .sidebar-body .nav {
  width: 100%;
}

@media (min-width: 992px) {
  .rtl .main-wrapper .sidebar .sidebar-body .nav {
    direction: rtl;
  }
}

.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item {
  width: 100%;
  direction: ltr;
}

.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item .nav-link {
  direction: rtl;
}

.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  margin-left: 0;
  margin-right: 30px;
}

.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
  margin-left: 0;
  margin-right: auto;
}

.rtl
  .main-wrapper
  .sidebar
  .sidebar-body
  .nav
  .nav-item.active
  .nav-link::before {
  right: -25px;
  left: auto;
}

.rtl .main-wrapper .sidebar .sidebar-body .nav .nav-item.nav-category::before {
  right: 6px;
}

.rtl .main-wrapper .sidebar .sidebar-body .nav.sub-menu {
  padding: 0 33px 15px 0;
}

.rtl
  .main-wrapper
  .sidebar
  .sidebar-body
  .nav.sub-menu
  .nav-item
  .nav-link::before {
  left: auto;
  right: -29px;
}

.rtl .main-wrapper .settings-sidebar {
  right: auto;
  left: -232px;
  border-radius: 0 0 4px 0;
  -webkit-box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
}

.settings-open.rtl .main-wrapper .settings-sidebar {
  right: auto;
  left: 0;
}

.rtl .main-wrapper .settings-sidebar .sidebar-body .settings-sidebar-toggler {
  left: auto;
  right: -44px;
  -webkit-box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  border-radius: 0 4px 4px 0;
}

@media (min-width: 992px) {
  .rtl .main-wrapper .page-wrapper {
    margin-left: 0;
    margin-right: 240px;
  }
  .sidebar-folded.rtl .main-wrapper .page-wrapper {
    margin-right: 70px;
  }
}

@media (max-width: 991px) {
  .rtl .main-wrapper .page-wrapper {
    margin-right: 0;
    width: 100%;
  }
}

.rtl .main-wrapper .page-wrapper.full-page {
  margin-right: 0;
  margin-left: 0;
}

.rtl .main-wrapper .page-wrapper .navbar {
  -webkit-transition: width 0.1s ease, right 0.1s ease;
  transition: width 0.1s ease, right 0.1s ease;
}

@media (min-width: 992px) {
  .rtl .main-wrapper .page-wrapper .navbar {
    left: 0;
    right: 240px;
    -webkit-transition: width 0.1s ease, right 0.1s ease;
    transition: width 0.1s ease, right 0.1s ease;
  }
  .sidebar-folded.rtl .main-wrapper .page-wrapper .navbar {
    right: 70px;
    width: calc(100% - 70px);
  }
}

@media (max-width: 991px) {
  .rtl .main-wrapper .page-wrapper .navbar {
    right: 0;
  }
}

.rtl .main-wrapper .page-wrapper .navbar .navbar-content .search-form {
  margin-right: 0;
}

.rtl .main-wrapper .page-wrapper .navbar .navbar-content .navbar-nav {
  margin-left: 0;
  margin-right: auto;
}

.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu {
  right: auto;
  left: -20px;
}

@media (max-width: 991px) {
  .rtl
    .main-wrapper
    .page-wrapper
    .navbar
    .navbar-content
    .navbar-nav
    .nav-item.dropdown
    .dropdown-menu {
    left: 20px;
  }
}

.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu::before {
  right: auto;
  left: 28px;
}

.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item
  .nav-link
  svg {
  margin-right: 0;
  margin-left: 20px;
}

.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-messages
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  margin-left: 0;
  margin-right: 15px;
  text-align: right;
}

.rtl
  .main-wrapper
  .page-wrapper
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-notifications
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  margin-left: 0;
  margin-right: 15px;
  text-align: right;
}

.rtl .main-wrapper .page-wrapper .page-content .main-content p {
  direction: ltr;
}

.rtl .main-wrapper .page-wrapper .page-content .main-content .highlight pre {
  direction: ltr;
}

.rtl .main-wrapper .page-wrapper .page-content .content-nav-wrapper {
  right: auto;
  left: 0;
  border-left: 0;
  border-right: 1px solid #e8ebf1;
}

.accordion > .card {
  margin-bottom: 0.75rem;
  border-radius: 0.25rem;
}

.accordion > .card .card-header {
  background-color: transparent;
  padding: 1.3rem 2rem;
}

.accordion > .card .card-header * {
  font-weight: 400;
  font-size: 0.95rem;
}

.accordion > .card .card-header a {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  position: relative;
  -webkit-transition: color 0.5s ease;
  -moz-transition: color 0.5s ease;
  -ms-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
  padding-right: 1.5rem;
}

.accordion > .card .card-header a:before {
  font-family: feather;
  position: absolute;
  right: 7px;
  top: 0;
  font-size: 14px;
  display: block;
}

.accordion > .card .card-header a[aria-expanded="true"] {
  color: #727cf5;
}

.accordion > .card .card-header a[aria-expanded="true"]:before {
  content: "\e842";
}

.accordion > .card .card-header a[aria-expanded="false"]:before {
  content: "\e845";
}

.accordion > .card .card-body {
  font-size: 0.875rem;
  padding: 1.3rem 2rem;
  font-weight: 400;
}

.accordion > .card .card-body i {
  font-size: 1.25rem;
}

.accordion > .card:first-of-type {
  border-bottom: 1px solid #f2f4f9;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid #f2f4f9;
}

.badge-outlineprimary {
  color: #727cf5;
  border: 1px solid #727cf5;
}

.badge-outlinesecondary {
  color: #7987a1;
  border: 1px solid #7987a1;
}

.badge-outlinesuccess {
  color: #10b759;
  border: 1px solid #10b759;
}

.badge-outlineinfo {
  color: #66d1d1;
  border: 1px solid #66d1d1;
}

.badge-outlinewarning {
  color: #fbbc06;
  border: 1px solid #fbbc06;
}

.badge-outlinedanger {
  color: #ff3366;
  border: 1px solid #ff3366;
}

.badge-outlinelight {
  color: #ececec;
  border: 1px solid #ececec;
}

.badge-outlinedark {
  color: #282f3a;
  border: 1px solid #282f3a;
}

.badge-outlineprimary-muted {
  color: #b1cfec;
  border: 1px solid #b1cfec;
}

.badge-outlineinfo-muted {
  color: #7ee5e5;
  border: 1px solid #7ee5e5;
}

.badge-outlinedanger-muted {
  color: #f77eb9;
  border: 1px solid #f77eb9;
}

.badge {
  padding: 6px 5px 3px;
}

.alert-fill-primary {
  color: #ffffff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.alert-fill-primary hr {
  border-top-color: #5a66f3;
}

.alert-fill-primary .alert-link {
  color: #e6e6e6;
}

.alert-fill-primary.alert-fill-light {
  color: #282f3a;
}

.alert-fill-secondary {
  color: #ffffff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.alert-fill-secondary hr {
  border-top-color: #6a7a96;
}

.alert-fill-secondary .alert-link {
  color: #e6e6e6;
}

.alert-fill-secondary.alert-fill-light {
  color: #282f3a;
}

.alert-fill-success {
  color: #ffffff;
  background-color: #10b759;
  border-color: #10b759;
}

.alert-fill-success hr {
  border-top-color: #0ea04e;
}

.alert-fill-success .alert-link {
  color: #e6e6e6;
}

.alert-fill-success.alert-fill-light {
  color: #282f3a;
}

.alert-fill-info {
  color: #ffffff;
  background-color: #66d1d1;
  border-color: #66d1d1;
}

.alert-fill-info hr {
  border-top-color: #52cbcb;
}

.alert-fill-info .alert-link {
  color: #e6e6e6;
}

.alert-fill-info.alert-fill-light {
  color: #282f3a;
}

.alert-fill-warning {
  color: #ffffff;
  background-color: #fbbc06;
  border-color: #fbbc06;
}

.alert-fill-warning hr {
  border-top-color: #e4aa04;
}

.alert-fill-warning .alert-link {
  color: #e6e6e6;
}

.alert-fill-warning.alert-fill-light {
  color: #282f3a;
}

.alert-fill-danger {
  color: #ffffff;
  background-color: #ff3366;
  border-color: #ff3366;
}

.alert-fill-danger hr {
  border-top-color: #ff1a53;
}

.alert-fill-danger .alert-link {
  color: #e6e6e6;
}

.alert-fill-danger.alert-fill-light {
  color: #282f3a;
}

.alert-fill-light {
  color: #ffffff;
  background-color: #ececec;
  border-color: #ececec;
}

.alert-fill-light hr {
  border-top-color: #dfdfdf;
}

.alert-fill-light .alert-link {
  color: #e6e6e6;
}

.alert-fill-light.alert-fill-light {
  color: #282f3a;
}

.alert-fill-dark {
  color: #ffffff;
  background-color: #282f3a;
  border-color: #282f3a;
}

.alert-fill-dark hr {
  border-top-color: #1e232b;
}

.alert-fill-dark .alert-link {
  color: #e6e6e6;
}

.alert-fill-dark.alert-fill-light {
  color: #282f3a;
}

.alert-fill-primary-muted {
  color: #ffffff;
  background-color: #b1cfec;
  border-color: #b1cfec;
}

.alert-fill-primary-muted hr {
  border-top-color: #9cc2e7;
}

.alert-fill-primary-muted .alert-link {
  color: #e6e6e6;
}

.alert-fill-primary-muted.alert-fill-light {
  color: #282f3a;
}

.alert-fill-info-muted {
  color: #ffffff;
  background-color: #7ee5e5;
  border-color: #7ee5e5;
}

.alert-fill-info-muted hr {
  border-top-color: #69e1e1;
}

.alert-fill-info-muted .alert-link {
  color: #e6e6e6;
}

.alert-fill-info-muted.alert-fill-light {
  color: #282f3a;
}

.alert-fill-danger-muted {
  color: #ffffff;
  background-color: #f77eb9;
  border-color: #f77eb9;
}

.alert-fill-danger-muted hr {
  border-top-color: #f666ac;
}

.alert-fill-danger-muted .alert-link {
  color: #e6e6e6;
}

.alert-fill-danger-muted.alert-fill-light {
  color: #282f3a;
}

.alert-icon-primary {
  color: #3b407f;
  background-color: #e3e5fd;
  border-color: #d8dafc;
}

.alert-icon-primary hr {
  border-top-color: #c0c4fa;
}

.alert-icon-primary .alert-link {
  color: #2b2e5c;
}

.alert-icon-primary svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #3b407f;
  margin-right: 1.25rem;
}

.alert-icon-secondary {
  color: #3f4654;
  background-color: #e4e7ec;
  border-color: #d9dde5;
}

.alert-icon-secondary hr {
  border-top-color: #cacfdb;
}

.alert-icon-secondary .alert-link {
  color: #292e37;
}

.alert-icon-secondary svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #3f4654;
  margin-right: 1.25rem;
}

.alert-icon-success {
  color: #085f2e;
  background-color: #cff1de;
  border-color: #bcebd1;
}

.alert-icon-success hr {
  border-top-color: #a8e5c4;
}

.alert-icon-success .alert-link {
  color: #043017;
}

.alert-icon-success svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #085f2e;
  margin-right: 1.25rem;
}

.alert-icon-info {
  color: #356d6d;
  background-color: #e0f6f6;
  border-color: #d4f2f2;
}

.alert-icon-info hr {
  border-top-color: #c0ecec;
}

.alert-icon-info .alert-link {
  color: #244b4b;
}

.alert-icon-info svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #356d6d;
  margin-right: 1.25rem;
}

.alert-icon-warning {
  color: #836203;
  background-color: #fef2cd;
  border-color: #feecb9;
}

.alert-icon-warning hr {
  border-top-color: #fee5a0;
}

.alert-icon-warning .alert-link {
  color: #513d02;
}

.alert-icon-warning svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #836203;
  margin-right: 1.25rem;
}

.alert-icon-danger {
  color: #851b35;
  background-color: #ffd6e0;
  border-color: #ffc6d4;
}

.alert-icon-danger hr {
  border-top-color: #ffadc1;
}

.alert-icon-danger .alert-link {
  color: #5b1224;
}

.alert-icon-danger svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #851b35;
  margin-right: 1.25rem;
}

.alert-icon-light {
  color: #7b7b7b;
  background-color: #fbfbfb;
  border-color: #fafafa;
}

.alert-icon-light hr {
  border-top-color: #ededed;
}

.alert-icon-light .alert-link {
  color: #626262;
}

.alert-icon-light svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #7b7b7b;
  margin-right: 1.25rem;
}

.alert-icon-dark {
  color: #15181e;
  background-color: #d4d5d8;
  border-color: #c3c5c8;
}

.alert-icon-dark hr {
  border-top-color: #b6b8bc;
}

.alert-icon-dark .alert-link {
  color: black;
}

.alert-icon-dark svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #15181e;
  margin-right: 1.25rem;
}

.alert-icon-primary-muted {
  color: #5c6c7b;
  background-color: #eff5fb;
  border-color: #e9f2fa;
}

.alert-icon-primary-muted hr {
  border-top-color: #d4e6f5;
}

.alert-icon-primary-muted .alert-link {
  color: #46525e;
}

.alert-icon-primary-muted svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #5c6c7b;
  margin-right: 1.25rem;
}

.alert-icon-info-muted {
  color: #427777;
  background-color: #e5fafa;
  border-color: #dbf8f8;
}

.alert-icon-info-muted hr {
  border-top-color: #c6f4f4;
}

.alert-icon-info-muted .alert-link {
  color: #305656;
}

.alert-icon-info-muted svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #427777;
  margin-right: 1.25rem;
}

.alert-icon-danger-muted {
  color: #804260;
  background-color: #fde5f1;
  border-color: #fddbeb;
}

.alert-icon-danger-muted hr {
  border-top-color: #fcc3de;
}

.alert-icon-danger-muted .alert-link {
  color: #5e3147;
}

.alert-icon-danger-muted svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: #804260;
  margin-right: 1.25rem;
}

.rtl .alert-icon-primary svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-secondary svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-success svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-info svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-warning svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-danger svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-light svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-dark svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-primary-muted svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-info-muted svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.rtl .alert-icon-danger-muted svg {
  margin-right: 0;
  margin-left: 1.25rem;
}

.breadcrumb.bg-success,
.breadcrumb.bg-dark,
.breadcrumb.bg-danger,
.breadcrumb.bg-warning,
.breadcrumb.bg-primary,
.breadcrumb.bg-info {
  border: none;
}

.breadcrumb.bg-success .breadcrumb-item,
.breadcrumb.bg-dark .breadcrumb-item,
.breadcrumb.bg-danger .breadcrumb-item,
.breadcrumb.bg-warning .breadcrumb-item,
.breadcrumb.bg-primary .breadcrumb-item,
.breadcrumb.bg-info .breadcrumb-item {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumb.bg-success .breadcrumb-item:before,
.breadcrumb.bg-dark .breadcrumb-item:before,
.breadcrumb.bg-danger .breadcrumb-item:before,
.breadcrumb.bg-warning .breadcrumb-item:before,
.breadcrumb.bg-primary .breadcrumb-item:before,
.breadcrumb.bg-info .breadcrumb-item:before {
  color: inherit;
}

.breadcrumb.bg-success .breadcrumb-item a,
.breadcrumb.bg-success .breadcrumb-item span,
.breadcrumb.bg-dark .breadcrumb-item a,
.breadcrumb.bg-dark .breadcrumb-item span,
.breadcrumb.bg-danger .breadcrumb-item a,
.breadcrumb.bg-danger .breadcrumb-item span,
.breadcrumb.bg-warning .breadcrumb-item a,
.breadcrumb.bg-warning .breadcrumb-item span,
.breadcrumb.bg-primary .breadcrumb-item a,
.breadcrumb.bg-primary .breadcrumb-item span,
.breadcrumb.bg-info .breadcrumb-item a,
.breadcrumb.bg-info .breadcrumb-item span {
  color: inherit;
}

.breadcrumb.bg-success .breadcrumb-item.active,
.breadcrumb.bg-dark .breadcrumb-item.active,
.breadcrumb.bg-danger .breadcrumb-item.active,
.breadcrumb.bg-warning .breadcrumb-item.active,
.breadcrumb.bg-primary .breadcrumb-item.active,
.breadcrumb.bg-info .breadcrumb-item.active {
  color: #ffffff;
}
/* inverse breadcrumb */

.bg-inverse-primary {
  background: #e9ebfd;
  border-color: #727cf5;
}

.bg-inverse-primary .breadcrumb-item {
  color: #727cf5;
}

.bg-inverse-primary .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-primary .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-primary.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-secondary {
  background: #c4cad6;
  border-color: #7987a1;
}

.bg-inverse-secondary .breadcrumb-item {
  color: #7987a1;
}

.bg-inverse-secondary .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-secondary .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-secondary.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-success {
  background: #56f09a;
  border-color: #10b759;
}

.bg-inverse-success .breadcrumb-item {
  color: #10b759;
}

.bg-inverse-success .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-success .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-success.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-info {
  background: #c8eeee;
  border-color: #66d1d1;
}

.bg-inverse-info .breadcrumb-item {
  color: #66d1d1;
}

.bg-inverse-info .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-info .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-info.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-warning {
  background: #fdde83;
  border-color: #fbbc06;
}

.bg-inverse-warning .breadcrumb-item {
  color: #fbbc06;
}

.bg-inverse-warning .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-warning .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-warning.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-danger {
  background: #ffb3c6;
  border-color: #ff3366;
}

.bg-inverse-danger .breadcrumb-item {
  color: #ff3366;
}

.bg-inverse-danger .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-danger .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-danger.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-light {
  background: white;
  border-color: #ececec;
}

.bg-inverse-light .breadcrumb-item {
  color: #ececec;
}

.bg-inverse-light .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-light .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-light.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-dark {
  background: #5c6c85;
  border-color: #282f3a;
}

.bg-inverse-dark .breadcrumb-item {
  color: #282f3a;
}

.bg-inverse-dark .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-dark .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-dark.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-primary-muted {
  background: white;
  border-color: #b1cfec;
}

.bg-inverse-primary-muted .breadcrumb-item {
  color: #b1cfec;
}

.bg-inverse-primary-muted .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-primary-muted .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-primary-muted.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-info-muted {
  background: #e8fafa;
  border-color: #7ee5e5;
}

.bg-inverse-info-muted .breadcrumb-item {
  color: #7ee5e5;
}

.bg-inverse-info-muted .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-info-muted .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-info-muted.bg-inverse-dark {
  background: #bfbfbf;
}

.bg-inverse-danger-muted {
  background: #fef6fa;
  border-color: #f77eb9;
}

.bg-inverse-danger-muted .breadcrumb-item {
  color: #f77eb9;
}

.bg-inverse-danger-muted .breadcrumb-item:before {
  color: inherit;
}

.bg-inverse-danger-muted .breadcrumb-item a {
  color: inherit;
}

.bg-inverse-danger-muted.bg-inverse-dark {
  background: #bfbfbf;
}

.rtl .breadcrumb .breadcrumb-item {
  padding-left: 0.5rem;
}

.rtl .breadcrumb .breadcrumb-item:last-child {
  padding-left: 0;
}

.rtl .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0;
  padding-left: 0.5rem;
}
/* Buttons */

.btn,
.fc .fc-button,
.swal2-modal .swal2-actions button,
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions .disabled a {
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.5rem 1rem 0.4rem;
  /* Buttons with only icons */
  /* Buttons with icon and text */
}

.btn i,
.fc .fc-button i,
.swal2-modal .swal2-actions button i,
.wizard > .actions a i,
.wizard > .actions a:hover i,
.wizard > .actions .disabled a i {
  font-size: 1rem;
}

.btn.btn-rounded,
.fc .btn-rounded.fc-button,
.swal2-modal .swal2-actions button.btn-rounded,
.wizard > .actions a.btn-rounded {
  border-radius: 50px;
}

.btn.btn-xs,
.fc .btn-xs.fc-button,
.swal2-modal .swal2-actions button.btn-xs,
.wizard > .actions a.btn-xs {
  padding: 0.35rem 0.75rem;
  font-size: 0.625rem;
}

.btn.btn-sm,
.btn-group-sm > .btn,
.fc .btn-group-sm > .fc-button,
.swal2-modal .swal2-actions .btn-group-sm > button,
.wizard > .actions .btn-group-sm > a,
.wizard > .actions .disabled .btn-group-sm > a,
.fc .btn-sm.fc-button,
.swal2-modal .swal2-actions button.btn-sm,
.wizard > .actions a.btn-sm {
  font-size: 0.75rem;
}

.btn.btn-lg,
.btn-group-lg > .btn,
.fc .btn-group-lg > .fc-button,
.swal2-modal .swal2-actions .btn-group-lg > button,
.wizard > .actions .btn-group-lg > a,
.wizard > .actions .disabled .btn-group-lg > a,
.fc .btn-lg.fc-button,
.swal2-modal .swal2-actions button.btn-lg,
.wizard > .actions a.btn-lg {
  font-size: 1.3rem;
}

.btn.btn-icon,
.fc .btn-icon.fc-button,
.swal2-modal .swal2-actions button.btn-icon,
.wizard > .actions a.btn-icon {
  width: 32px;
  height: 32px;
  padding: 0;
}

.btn.btn-icon svg,
.fc .btn-icon.fc-button svg,
.swal2-modal .swal2-actions button.btn-icon svg,
.wizard > .actions a.btn-icon svg {
  height: 15px;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon,
.fc .btn-group-sm > .btn-icon.fc-button,
.swal2-modal .swal2-actions .btn-group-sm > button.btn-icon,
.wizard > .actions .btn-group-sm > a.btn-icon,
.wizard > .actions .disabled .btn-group-sm > a.btn-icon,
.fc .btn-icon.btn-sm.fc-button,
.swal2-modal .swal2-actions button.btn-icon.btn-sm,
.wizard > .actions a.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
}

.btn.btn-icon.btn-sm svg,
.btn-group-sm > .btn.btn-icon svg,
.fc .btn-group-sm > .btn-icon.fc-button svg,
.swal2-modal .swal2-actions .btn-group-sm > button.btn-icon svg,
.wizard > .actions .btn-group-sm > a.btn-icon svg,
.wizard > .actions .disabled .btn-group-sm > a.btn-icon svg,
.fc .btn-icon.btn-sm.fc-button svg,
.swal2-modal .swal2-actions button.btn-icon.btn-sm svg,
.wizard > .actions a.btn-icon.btn-sm svg {
  height: 13px;
}

.btn.btn-icon.btn-lg,
.btn-group-lg > .btn.btn-icon,
.fc .btn-group-lg > .btn-icon.fc-button,
.swal2-modal .swal2-actions .btn-group-lg > button.btn-icon,
.wizard > .actions .btn-group-lg > a.btn-icon,
.wizard > .actions .disabled .btn-group-lg > a.btn-icon,
.fc .btn-icon.btn-lg.fc-button,
.swal2-modal .swal2-actions button.btn-icon.btn-lg,
.wizard > .actions a.btn-icon.btn-lg {
  width: 48px;
  height: 48px;
}

.btn.btn-icon.btn-lg svg,
.btn-group-lg > .btn.btn-icon svg,
.fc .btn-group-lg > .btn-icon.fc-button svg,
.swal2-modal .swal2-actions .btn-group-lg > button.btn-icon svg,
.wizard > .actions .btn-group-lg > a.btn-icon svg,
.wizard > .actions .disabled .btn-group-lg > a.btn-icon svg,
.fc .btn-icon.btn-lg.fc-button svg,
.swal2-modal .swal2-actions button.btn-icon.btn-lg svg,
.wizard > .actions a.btn-icon.btn-lg svg {
  height: 20px;
}

.btn.btn-icon-text .btn-icon-prepend,
.fc .btn-icon-text.fc-button .btn-icon-prepend,
.swal2-modal .swal2-actions button.btn-icon-text .btn-icon-prepend,
.wizard > .actions a.btn-icon-text .btn-icon-prepend {
  margin-right: 0.5rem;
}

.btn.btn-icon-text .btn-icon-append,
.fc .btn-icon-text.fc-button .btn-icon-append,
.swal2-modal .swal2-actions button.btn-icon-text .btn-icon-append,
.wizard > .actions a.btn-icon-text .btn-icon-append {
  margin-left: 0.5rem;
}

.btn.btn-icon-text .btn-icon-prepend,
.fc .btn-icon-text.fc-button .btn-icon-prepend,
.swal2-modal .swal2-actions button.btn-icon-text .btn-icon-prepend,
.wizard > .actions a.btn-icon-text .btn-icon-prepend,
.btn.btn-icon-text .btn-icon-append,
.fc .btn-icon-text.fc-button .btn-icon-append,
.swal2-modal .swal2-actions button.btn-icon-text .btn-icon-append,
.wizard > .actions a.btn-icon-text .btn-icon-append {
  width: 15px;
  height: 15px;
}

.btn.btn-icon-text.btn-sm .btn-icon-prepend,
.btn-group-sm > .btn.btn-icon-text .btn-icon-prepend,
.fc .btn-group-sm > .btn-icon-text.fc-button .btn-icon-prepend,
.swal2-modal
  .swal2-actions
  .btn-group-sm
  > button.btn-icon-text
  .btn-icon-prepend,
.wizard > .actions .btn-group-sm > a.btn-icon-text .btn-icon-prepend,
.wizard > .actions .disabled .btn-group-sm > a.btn-icon-text .btn-icon-prepend,
.fc .btn-icon-text.btn-sm.fc-button .btn-icon-prepend,
.swal2-modal .swal2-actions button.btn-icon-text.btn-sm .btn-icon-prepend,
.wizard > .actions a.btn-icon-text.btn-sm .btn-icon-prepend,
.btn.btn-icon-text.btn-sm .btn-icon-append,
.btn-group-sm > .btn.btn-icon-text .btn-icon-append,
.fc .btn-group-sm > .btn-icon-text.fc-button .btn-icon-append,
.swal2-modal
  .swal2-actions
  .btn-group-sm
  > button.btn-icon-text
  .btn-icon-append,
.wizard > .actions .btn-group-sm > a.btn-icon-text .btn-icon-append,
.wizard > .actions .disabled .btn-group-sm > a.btn-icon-text .btn-icon-append,
.fc .btn-icon-text.btn-sm.fc-button .btn-icon-append,
.swal2-modal .swal2-actions button.btn-icon-text.btn-sm .btn-icon-append,
.wizard > .actions a.btn-icon-text.btn-sm .btn-icon-append {
  width: 12px;
  height: 12px;
}

.btn.btn-icon-text.btn-lg .btn-icon-prepend,
.btn-group-lg > .btn.btn-icon-text .btn-icon-prepend,
.fc .btn-group-lg > .btn-icon-text.fc-button .btn-icon-prepend,
.swal2-modal
  .swal2-actions
  .btn-group-lg
  > button.btn-icon-text
  .btn-icon-prepend,
.wizard > .actions .btn-group-lg > a.btn-icon-text .btn-icon-prepend,
.wizard > .actions .disabled .btn-group-lg > a.btn-icon-text .btn-icon-prepend,
.fc .btn-icon-text.btn-lg.fc-button .btn-icon-prepend,
.swal2-modal .swal2-actions button.btn-icon-text.btn-lg .btn-icon-prepend,
.wizard > .actions a.btn-icon-text.btn-lg .btn-icon-prepend,
.btn.btn-icon-text.btn-lg .btn-icon-append,
.btn-group-lg > .btn.btn-icon-text .btn-icon-append,
.fc .btn-group-lg > .btn-icon-text.fc-button .btn-icon-append,
.swal2-modal
  .swal2-actions
  .btn-group-lg
  > button.btn-icon-text
  .btn-icon-append,
.wizard > .actions .btn-group-lg > a.btn-icon-text .btn-icon-append,
.wizard > .actions .disabled .btn-group-lg > a.btn-icon-text .btn-icon-append,
.fc .btn-icon-text.btn-lg.fc-button .btn-icon-append,
.swal2-modal .swal2-actions button.btn-icon-text.btn-lg .btn-icon-append,
.wizard > .actions a.btn-icon-text.btn-lg .btn-icon-append {
  width: 20px;
  height: 20px;
}

.btn.btn-social-icon,
.fc .btn-social-icon.fc-button,
.swal2-modal .swal2-actions button.btn-social-icon,
.wizard > .actions a.btn-social-icon {
  width: 50px;
  height: 50px;
  padding: 0;
}

.btn-group .btn + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .btn + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .btn + .btn,
.btn-group .fc .fc-button + .btn,
.fc .btn-group .fc-button + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .fc-button + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .fc-button + .btn,
.btn-group .swal2-modal .swal2-actions button + .btn,
.swal2-modal .swal2-actions .btn-group button + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + .btn,
.btn-group .wizard > .actions a + .btn,
.wizard > .actions .btn-group a + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .btn,
.btn-group .wizard > .actions a:hover + .btn,
.wizard > .actions .btn-group a:hover + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + .btn,
.btn-group .wizard > .actions .disabled a + .btn,
.wizard > .actions .disabled .btn-group a + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .btn,
.btn-group .fc .btn + .fc-button,
.fc .btn-group .btn + .fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .btn + .fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .btn + .fc-button,
.btn-group .fc .fc-button + .fc-button,
.fc .btn-group .fc-button + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + .fc-button,
.btn-group .swal2-modal .swal2-actions .fc button + .fc-button,
.fc .btn-group .swal2-modal .swal2-actions button + .fc-button,
.swal2-modal .swal2-actions .btn-group .fc button + .fc-button,
.fc .swal2-modal .swal2-actions .btn-group button + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + .fc-button,
.btn-group .wizard > .actions .fc a + .fc-button,
.fc .btn-group .wizard > .actions a + .fc-button,
.wizard > .actions .btn-group .fc a + .fc-button,
.fc .wizard > .actions .btn-group a + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .fc-button,
.btn-group .wizard > .actions .fc a:hover + .fc-button,
.fc .btn-group .wizard > .actions a:hover + .fc-button,
.wizard > .actions .btn-group .fc a:hover + .fc-button,
.fc .wizard > .actions .btn-group a:hover + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + .fc-button,
.btn-group .wizard > .actions .disabled .fc a + .fc-button,
.fc .btn-group .wizard > .actions .disabled a + .fc-button,
.wizard > .actions .disabled .btn-group .fc a + .fc-button,
.fc .wizard > .actions .disabled .btn-group a + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .fc-button,
.btn-group .swal2-modal .swal2-actions .btn + button,
.swal2-modal .swal2-actions .btn-group .btn + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + button,
.btn-group .fc .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .btn-group .fc .fc-button + button,
.fc .btn-group .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .fc .btn-group .fc-button + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + button,
.btn-group .swal2-modal .swal2-actions button + button,
.swal2-modal .swal2-actions .btn-group button + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + button,
.btn-group .wizard > .actions .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .btn-group .wizard > .actions a + button,
.wizard > .actions .btn-group .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .wizard > .actions .btn-group a + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + button,
.btn-group .wizard > .actions .swal2-modal .swal2-actions a:hover + button,
.swal2-modal .swal2-actions .btn-group .wizard > .actions a:hover + button,
.wizard > .actions .btn-group .swal2-modal .swal2-actions a:hover + button,
.swal2-modal .swal2-actions .wizard > .actions .btn-group a:hover + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + button,
.btn-group .wizard > .actions .disabled .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .btn-group .wizard > .actions .disabled a + button,
.wizard > .actions .disabled .btn-group .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .wizard > .actions .disabled .btn-group a + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + button,
.btn-group .wizard > .actions .btn + a,
.wizard > .actions .btn-group .btn + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a,
.btn-group .fc .wizard > .actions .fc-button + a,
.wizard > .actions .btn-group .fc .fc-button + a,
.fc .btn-group .wizard > .actions .fc-button + a,
.wizard > .actions .fc .btn-group .fc-button + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a,
.btn-group .swal2-modal .swal2-actions .wizard > .actions button + a,
.wizard > .actions .btn-group .swal2-modal .swal2-actions button + a,
.swal2-modal .swal2-actions .btn-group .wizard > .actions button + a,
.wizard > .actions .swal2-modal .swal2-actions .btn-group button + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a,
.btn-group .wizard > .actions a + a,
.wizard > .actions .btn-group a + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.btn-group .wizard > .actions a:hover + a,
.wizard > .actions .btn-group a:hover + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a,
.btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .disabled .btn-group a + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.btn-group .wizard > .actions .btn + a:hover,
.wizard > .actions .btn-group .btn + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a:hover,
.btn-group .fc .wizard > .actions .fc-button + a:hover,
.wizard > .actions .btn-group .fc .fc-button + a:hover,
.fc .btn-group .wizard > .actions .fc-button + a:hover,
.wizard > .actions .fc .btn-group .fc-button + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a:hover,
.btn-group .swal2-modal .swal2-actions .wizard > .actions button + a:hover,
.wizard > .actions .btn-group .swal2-modal .swal2-actions button + a:hover,
.swal2-modal .swal2-actions .btn-group .wizard > .actions button + a:hover,
.wizard > .actions .swal2-modal .swal2-actions .btn-group button + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a:hover,
.btn-group .wizard > .actions a + a:hover,
.wizard > .actions .btn-group a + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a:hover,
.btn-group .wizard > .actions a:hover + a:hover,
.wizard > .actions .btn-group a:hover + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a:hover,
.btn-group .wizard > .actions .disabled a + a:hover,
.wizard > .actions .disabled .btn-group a + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a:hover,
.btn-group .wizard > .actions .disabled .btn + a,
.wizard > .actions .disabled .btn-group .btn + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a,
.btn-group .fc .wizard > .actions .disabled .fc-button + a,
.wizard > .actions .disabled .btn-group .fc .fc-button + a,
.fc .btn-group .wizard > .actions .disabled .fc-button + a,
.wizard > .actions .disabled .fc .btn-group .fc-button + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a,
.btn-group .swal2-modal .swal2-actions .wizard > .actions .disabled button + a,
.wizard > .actions .disabled .btn-group .swal2-modal .swal2-actions button + a,
.swal2-modal .swal2-actions .btn-group .wizard > .actions .disabled button + a,
.wizard > .actions .disabled .swal2-modal .swal2-actions .btn-group button + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a,
.btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .btn-group .disabled a + a,
.wizard > .actions .disabled .btn-group a + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.btn-group .wizard > .actions .disabled a:hover + a,
.wizard > .actions .btn-group .disabled a:hover + a,
.wizard > .actions .disabled .btn-group a:hover + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a,
.btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .disabled .btn-group a + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a {
  border-left: 0;
}

.rtl .btn-group .btn + .btn,
.rtl .fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .btn + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-left .rtl .fc-button-group .btn + .btn,
.rtl .fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .btn + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-right .rtl .fc-button-group .btn + .btn,
.rtl .btn-group .fc .fc-button + .btn,
.fc .rtl .btn-group .fc-button + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + .btn,
.rtl .btn-group .swal2-modal .swal2-actions button + .btn,
.swal2-modal .swal2-actions .rtl .btn-group button + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + .btn,
.rtl .btn-group .wizard > .actions a + .btn,
.wizard > .actions .rtl .btn-group a + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl .btn-group .wizard > .actions a:hover + .btn,
.wizard > .actions .rtl .btn-group a:hover + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + .btn,
.rtl .btn-group .wizard > .actions .disabled a + .btn,
.wizard > .actions .disabled .rtl .btn-group a + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl .btn-group .fc .btn + .fc-button,
.fc .rtl .btn-group .btn + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + .fc-button,
.rtl .btn-group .fc .fc-button + .fc-button,
.fc .rtl .btn-group .fc-button + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + .fc-button,
.rtl .btn-group .swal2-modal .swal2-actions .fc button + .fc-button,
.fc .rtl .btn-group .swal2-modal .swal2-actions button + .fc-button,
.swal2-modal .swal2-actions .rtl .btn-group .fc button + .fc-button,
.fc .swal2-modal .swal2-actions .rtl .btn-group button + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + .fc-button,
.rtl .btn-group .wizard > .actions .fc a + .fc-button,
.fc .rtl .btn-group .wizard > .actions a + .fc-button,
.wizard > .actions .rtl .btn-group .fc a + .fc-button,
.fc .wizard > .actions .rtl .btn-group a + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl .btn-group .wizard > .actions .fc a:hover + .fc-button,
.fc .rtl .btn-group .wizard > .actions a:hover + .fc-button,
.wizard > .actions .rtl .btn-group .fc a:hover + .fc-button,
.fc .wizard > .actions .rtl .btn-group a:hover + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + .fc-button,
.rtl .btn-group .wizard > .actions .disabled .fc a + .fc-button,
.fc .rtl .btn-group .wizard > .actions .disabled a + .fc-button,
.wizard > .actions .disabled .rtl .btn-group .fc a + .fc-button,
.fc .wizard > .actions .disabled .rtl .btn-group a + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl .btn-group .swal2-modal .swal2-actions .btn + button,
.swal2-modal .swal2-actions .rtl .btn-group .btn + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + button,
.rtl .btn-group .fc .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .rtl .btn-group .fc .fc-button + button,
.fc .rtl .btn-group .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .fc .rtl .btn-group .fc-button + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + button,
.rtl .btn-group .swal2-modal .swal2-actions button + button,
.swal2-modal .swal2-actions .rtl .btn-group button + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + button,
.rtl .btn-group .wizard > .actions .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions a + button,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .wizard > .actions .rtl .btn-group a + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + button,
.rtl .btn-group .wizard > .actions .swal2-modal .swal2-actions a:hover + button,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions a:hover + button,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions a:hover + button,
.swal2-modal .swal2-actions .wizard > .actions .rtl .btn-group a:hover + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + button,
.rtl
  .btn-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .rtl
  .btn-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .rtl
  .btn-group
  a
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + button,
.rtl .btn-group .wizard > .actions .btn + a,
.wizard > .actions .rtl .btn-group .btn + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl .btn-group .fc .wizard > .actions .fc-button + a,
.wizard > .actions .rtl .btn-group .fc .fc-button + a,
.fc .rtl .btn-group .wizard > .actions .fc-button + a,
.wizard > .actions .fc .rtl .btn-group .fc-button + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl .btn-group .swal2-modal .swal2-actions .wizard > .actions button + a,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions button + a,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions button + a,
.wizard > .actions .swal2-modal .swal2-actions .rtl .btn-group button + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + a,
.rtl .btn-group .wizard > .actions a + a,
.wizard > .actions .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a,
.rtl .btn-group .wizard > .actions a:hover + a,
.wizard > .actions .rtl .btn-group a:hover + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl .btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .disabled .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a,
.rtl .btn-group .wizard > .actions .btn + a:hover,
.wizard > .actions .rtl .btn-group .btn + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + a:hover,
.rtl .btn-group .fc .wizard > .actions .fc-button + a:hover,
.wizard > .actions .rtl .btn-group .fc .fc-button + a:hover,
.fc .rtl .btn-group .wizard > .actions .fc-button + a:hover,
.wizard > .actions .fc .rtl .btn-group .fc-button + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + a:hover,
.rtl .btn-group .swal2-modal .swal2-actions .wizard > .actions button + a:hover,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions button + a:hover,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions button + a:hover,
.wizard > .actions .swal2-modal .swal2-actions .rtl .btn-group button + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + a:hover,
.rtl .btn-group .wizard > .actions a + a:hover,
.wizard > .actions .rtl .btn-group a + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl .btn-group .wizard > .actions a:hover + a:hover,
.wizard > .actions .rtl .btn-group a:hover + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + a:hover,
.rtl .btn-group .wizard > .actions .disabled a + a:hover,
.wizard > .actions .disabled .rtl .btn-group a + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl .btn-group .wizard > .actions .disabled .btn + a,
.wizard > .actions .disabled .rtl .btn-group .btn + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl .btn-group .fc .wizard > .actions .disabled .fc-button + a,
.wizard > .actions .disabled .rtl .btn-group .fc .fc-button + a,
.fc .rtl .btn-group .wizard > .actions .disabled .fc-button + a,
.wizard > .actions .disabled .fc .rtl .btn-group .fc-button + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl
  .btn-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .btn-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + a,
.rtl .btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .rtl .btn-group .disabled a + a,
.wizard > .actions .disabled .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a,
.rtl .btn-group .wizard > .actions .disabled a:hover + a,
.wizard > .actions .rtl .btn-group .disabled a:hover + a,
.wizard > .actions .disabled .rtl .btn-group a:hover + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl .btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .disabled .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a {
  border-left: 1px solid;
  border-right: 0;
}

.rtl .btn-group > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .btn-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .rtl
  .btn-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .wizard
  > .actions
  .btn-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .rtl
  .btn-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle) {
  border-radius: 0;
}

.rtl .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .fc .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc .rtl .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.rtl .wizard > .actions .btn-group > a:not(:last-child):not(.dropdown-toggle),
.wizard > .actions .rtl .btn-group > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl .btn-group > .btn-group:not(:last-child) > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl .fc .btn-group > .btn-group:not(:last-child) > .fc-button,
.fc .rtl .btn-group > .btn-group:not(:last-child) > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > .btn-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl .wizard > .actions .btn-group > .btn-group:not(:last-child) > a,
.wizard > .actions .rtl .btn-group > .btn-group:not(:last-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl .wizard > .actions .disabled .btn-group > .btn-group:not(:last-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a {
  border-radius: 0.1875rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .btn-group > .btn:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn:not(:first-child),
.rtl .fc .btn-group > .fc-button:not(:first-child),
.fc .rtl .btn-group > .fc-button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child),
.rtl .swal2-modal .swal2-actions .btn-group > button:not(:first-child),
.swal2-modal .swal2-actions .rtl .btn-group > button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > button:not(:first-child),
.rtl .wizard > .actions .btn-group > a:not(:first-child),
.wizard > .actions .rtl .btn-group > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > a:not(:first-child),
.rtl .wizard > .actions .disabled .btn-group > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.rtl .btn-group > .btn-group:not(:first-child) > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl .fc .btn-group > .btn-group:not(:first-child) > .fc-button,
.fc .rtl .btn-group > .btn-group:not(:first-child) > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > .btn-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl .wizard > .actions .btn-group > .btn-group:not(:first-child) > a,
.wizard > .actions .rtl .btn-group > .btn-group:not(:first-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl .wizard > .actions .disabled .btn-group > .btn-group:not(:first-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a {
  border-radius: 0.1875rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/*social buttons*/

.btn-twitter {
  background: #2caae1;
  color: #ffffff;
}

.btn-twitter:hover,
.btn-twitter:focus {
  background: #1b8dbf;
  color: #ffffff;
}

.btn-twitter.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #59bce7;
}

.btn-twitter.btn-social-icon-text i {
  background: #2caae1;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-twitter {
  border: 1px solid #2caae1;
  color: #2caae1;
}

.btn-outline-twitter:hover {
  background: #2caae1;
  color: #ffffff;
}

.btn-facebook {
  background: #3b579d;
  color: #ffffff;
}

.btn-facebook:hover,
.btn-facebook:focus {
  background: #2d4278;
  color: #ffffff;
}

.btn-facebook.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4e6ebd;
}

.btn-facebook.btn-social-icon-text i {
  background: #3b579d;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-facebook {
  border: 1px solid #3b579d;
  color: #3b579d;
}

.btn-outline-facebook:hover {
  background: #3b579d;
  color: #ffffff;
}

.btn-google {
  background: #dc4a38;
  color: #ffffff;
}

.btn-google:hover,
.btn-google:focus {
  background: #bf3322;
  color: #ffffff;
}

.btn-google.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #e47163;
}

.btn-google.btn-social-icon-text i {
  background: #dc4a38;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-google {
  border: 1px solid #dc4a38;
  color: #dc4a38;
}

.btn-outline-google:hover {
  background: #dc4a38;
  color: #ffffff;
}

.btn-linkedin {
  background: #0177b5;
  color: #ffffff;
}

.btn-linkedin:hover,
.btn-linkedin:focus {
  background: #015682;
  color: #ffffff;
}

.btn-linkedin.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #0198e8;
}

.btn-linkedin.btn-social-icon-text i {
  background: #0177b5;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-linkedin {
  border: 1px solid #0177b5;
  color: #0177b5;
}

.btn-outline-linkedin:hover {
  background: #0177b5;
  color: #ffffff;
}

.btn-pinterest {
  background: #cc2127;
  color: #ffffff;
}

.btn-pinterest:hover,
.btn-pinterest:focus {
  background: #a01a1f;
  color: #ffffff;
}

.btn-pinterest.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #e04046;
}

.btn-pinterest.btn-social-icon-text i {
  background: #cc2127;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-pinterest {
  border: 1px solid #cc2127;
  color: #cc2127;
}

.btn-outline-pinterest:hover {
  background: #cc2127;
  color: #ffffff;
}

.btn-youtube {
  background: #e52d27;
  color: #ffffff;
}

.btn-youtube:hover,
.btn-youtube:focus {
  background: #c21d17;
  color: #ffffff;
}

.btn-youtube.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ea5955;
}

.btn-youtube.btn-social-icon-text i {
  background: #e52d27;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-youtube {
  border: 1px solid #e52d27;
  color: #e52d27;
}

.btn-outline-youtube:hover {
  background: #e52d27;
  color: #ffffff;
}

.btn-github {
  background: #333333;
  color: #ffffff;
}

.btn-github:hover,
.btn-github:focus {
  background: #1a1a1a;
  color: #ffffff;
}

.btn-github.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4d4d4d;
}

.btn-github.btn-social-icon-text i {
  background: #333333;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-github {
  border: 1px solid #333333;
  color: #333333;
}

.btn-outline-github:hover {
  background: #333333;
  color: #ffffff;
}

.btn-behance {
  background: #1769ff;
  color: #ffffff;
}

.btn-behance:hover,
.btn-behance:focus {
  background: #0050e3;
  color: #ffffff;
}

.btn-behance.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4a8aff;
}

.btn-behance.btn-social-icon-text i {
  background: #1769ff;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-behance {
  border: 1px solid #1769ff;
  color: #1769ff;
}

.btn-outline-behance:hover {
  background: #1769ff;
  color: #ffffff;
}

.btn-dribbble {
  background: #ea4c89;
  color: #ffffff;
}

.btn-dribbble:hover,
.btn-dribbble:focus {
  background: #e51e6b;
  color: #ffffff;
}

.btn-dribbble.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ef7aa7;
}

.btn-dribbble.btn-social-icon-text i {
  background: #ea4c89;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-dribbble {
  border: 1px solid #ea4c89;
  color: #ea4c89;
}

.btn-outline-dribbble:hover {
  background: #ea4c89;
  color: #ffffff;
}

.btn-reddit {
  background: #ff4500;
  color: #ffffff;
}

.btn-reddit:hover,
.btn-reddit:focus {
  background: #cc3700;
  color: #ffffff;
}

.btn-reddit.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ff6a33;
}

.btn-reddit.btn-social-icon-text i {
  background: #ff4500;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-reddit {
  border: 1px solid #ff4500;
  color: #ff4500;
}

.btn-outline-reddit:hover {
  background: #ff4500;
  color: #ffffff;
}

.btn-instagram {
  background: #fb3958;
  color: #ffffff;
}

.btn-instagram:hover,
.btn-instagram:focus {
  background: #fa072e;
  color: #ffffff;
}

.btn-instagram.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #fc6b82;
}

.btn-instagram.btn-social-icon-text i {
  background: #fb3958;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-instagram {
  border: 1px solid #fb3958;
  color: #fb3958;
}

.btn-outline-instagram:hover {
  background: #fb3958;
  color: #ffffff;
}
/* inverse buttons */

.btn-inverse-primary {
  background-color: rgba(114, 124, 245, 0.2);
  background-image: none;
  border-color: rgba(114, 124, 245, 0);
}

.btn-inverse-primary:not(.btn-inverse-light) {
  color: #727cf5;
}

.btn-inverse-primary:hover {
  color: #ffffff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-inverse-primary.focus,
.btn-inverse-primary:focus {
  box-shadow: 0 0 0 3px rgba(114, 124, 245, 0.5);
}

.btn-inverse-primary.disabled,
.btn-inverse-primary:disabled {
  color: #727cf5;
  background-color: transparent;
}

.btn-inverse-primary.active,
.btn-inverse-primary:active,
.show > .btn-inverse-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.btn-inverse-secondary {
  background-color: rgba(121, 135, 161, 0.2);
  background-image: none;
  border-color: rgba(121, 135, 161, 0);
}

.btn-inverse-secondary:not(.btn-inverse-light) {
  color: #7987a1;
}

.btn-inverse-secondary:hover {
  color: #ffffff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-inverse-secondary.focus,
.btn-inverse-secondary:focus {
  box-shadow: 0 0 0 3px rgba(121, 135, 161, 0.5);
}

.btn-inverse-secondary.disabled,
.btn-inverse-secondary:disabled {
  color: #7987a1;
  background-color: transparent;
}

.btn-inverse-secondary.active,
.btn-inverse-secondary:active,
.show > .btn-inverse-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-inverse-success {
  background-color: rgba(16, 183, 89, 0.2);
  background-image: none;
  border-color: rgba(16, 183, 89, 0);
}

.btn-inverse-success:not(.btn-inverse-light) {
  color: #10b759;
}

.btn-inverse-success:hover {
  color: #ffffff;
  background-color: #10b759;
  border-color: #10b759;
}

.btn-inverse-success.focus,
.btn-inverse-success:focus {
  box-shadow: 0 0 0 3px rgba(16, 183, 89, 0.5);
}

.btn-inverse-success.disabled,
.btn-inverse-success:disabled {
  color: #10b759;
  background-color: transparent;
}

.btn-inverse-success.active,
.btn-inverse-success:active,
.show > .btn-inverse-success.dropdown-toggle {
  color: #ffffff;
  background-color: #10b759;
  border-color: #10b759;
}

.btn-inverse-info {
  background-color: rgba(102, 209, 209, 0.2);
  background-image: none;
  border-color: rgba(102, 209, 209, 0);
}

.btn-inverse-info:not(.btn-inverse-light) {
  color: #66d1d1;
}

.btn-inverse-info:hover {
  color: #ffffff;
  background-color: #66d1d1;
  border-color: #66d1d1;
}

.btn-inverse-info.focus,
.btn-inverse-info:focus {
  box-shadow: 0 0 0 3px rgba(102, 209, 209, 0.5);
}

.btn-inverse-info.disabled,
.btn-inverse-info:disabled {
  color: #66d1d1;
  background-color: transparent;
}

.btn-inverse-info.active,
.btn-inverse-info:active,
.show > .btn-inverse-info.dropdown-toggle {
  color: #ffffff;
  background-color: #66d1d1;
  border-color: #66d1d1;
}

.btn-inverse-warning {
  background-color: rgba(251, 188, 6, 0.2);
  background-image: none;
  border-color: rgba(251, 188, 6, 0);
}

.btn-inverse-warning:not(.btn-inverse-light) {
  color: #fbbc06;
}

.btn-inverse-warning:hover {
  color: #ffffff;
  background-color: #fbbc06;
  border-color: #fbbc06;
}

.btn-inverse-warning.focus,
.btn-inverse-warning:focus {
  box-shadow: 0 0 0 3px rgba(251, 188, 6, 0.5);
}

.btn-inverse-warning.disabled,
.btn-inverse-warning:disabled {
  color: #fbbc06;
  background-color: transparent;
}

.btn-inverse-warning.active,
.btn-inverse-warning:active,
.show > .btn-inverse-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #fbbc06;
  border-color: #fbbc06;
}

.btn-inverse-danger {
  background-color: rgba(255, 51, 102, 0.2);
  background-image: none;
  border-color: rgba(255, 51, 102, 0);
}

.btn-inverse-danger:not(.btn-inverse-light) {
  color: #ff3366;
}

.btn-inverse-danger:hover {
  color: #ffffff;
  background-color: #ff3366;
  border-color: #ff3366;
}

.btn-inverse-danger.focus,
.btn-inverse-danger:focus {
  box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.5);
}

.btn-inverse-danger.disabled,
.btn-inverse-danger:disabled {
  color: #ff3366;
  background-color: transparent;
}

.btn-inverse-danger.active,
.btn-inverse-danger:active,
.show > .btn-inverse-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ff3366;
  border-color: #ff3366;
}

.btn-inverse-light {
  background-color: rgba(236, 236, 236, 0.2);
  background-image: none;
  border-color: rgba(236, 236, 236, 0);
}

.btn-inverse-light:not(.btn-inverse-light) {
  color: #ececec;
}

.btn-inverse-light:hover {
  color: #ffffff;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-inverse-light.focus,
.btn-inverse-light:focus {
  box-shadow: 0 0 0 3px rgba(236, 236, 236, 0.5);
}

.btn-inverse-light.disabled,
.btn-inverse-light:disabled {
  color: #ececec;
  background-color: transparent;
}

.btn-inverse-light.active,
.btn-inverse-light:active,
.show > .btn-inverse-light.dropdown-toggle {
  color: #ffffff;
  background-color: #ececec;
  border-color: #ececec;
}

.btn-inverse-dark {
  background-color: rgba(40, 47, 58, 0.2);
  background-image: none;
  border-color: rgba(40, 47, 58, 0);
}

.btn-inverse-dark:not(.btn-inverse-light) {
  color: #282f3a;
}

.btn-inverse-dark:hover {
  color: #ffffff;
  background-color: #282f3a;
  border-color: #282f3a;
}

.btn-inverse-dark.focus,
.btn-inverse-dark:focus {
  box-shadow: 0 0 0 3px rgba(40, 47, 58, 0.5);
}

.btn-inverse-dark.disabled,
.btn-inverse-dark:disabled {
  color: #282f3a;
  background-color: transparent;
}

.btn-inverse-dark.active,
.btn-inverse-dark:active,
.show > .btn-inverse-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #282f3a;
  border-color: #282f3a;
}

.btn-inverse-primary-muted {
  background-color: rgba(177, 207, 236, 0.2);
  background-image: none;
  border-color: rgba(177, 207, 236, 0);
}

.btn-inverse-primary-muted:not(.btn-inverse-light) {
  color: #b1cfec;
}

.btn-inverse-primary-muted:hover {
  color: #ffffff;
  background-color: #b1cfec;
  border-color: #b1cfec;
}

.btn-inverse-primary-muted.focus,
.btn-inverse-primary-muted:focus {
  box-shadow: 0 0 0 3px rgba(177, 207, 236, 0.5);
}

.btn-inverse-primary-muted.disabled,
.btn-inverse-primary-muted:disabled {
  color: #b1cfec;
  background-color: transparent;
}

.btn-inverse-primary-muted.active,
.btn-inverse-primary-muted:active,
.show > .btn-inverse-primary-muted.dropdown-toggle {
  color: #ffffff;
  background-color: #b1cfec;
  border-color: #b1cfec;
}

.btn-inverse-info-muted {
  background-color: rgba(126, 229, 229, 0.2);
  background-image: none;
  border-color: rgba(126, 229, 229, 0);
}

.btn-inverse-info-muted:not(.btn-inverse-light) {
  color: #7ee5e5;
}

.btn-inverse-info-muted:hover {
  color: #ffffff;
  background-color: #7ee5e5;
  border-color: #7ee5e5;
}

.btn-inverse-info-muted.focus,
.btn-inverse-info-muted:focus {
  box-shadow: 0 0 0 3px rgba(126, 229, 229, 0.5);
}

.btn-inverse-info-muted.disabled,
.btn-inverse-info-muted:disabled {
  color: #7ee5e5;
  background-color: transparent;
}

.btn-inverse-info-muted.active,
.btn-inverse-info-muted:active,
.show > .btn-inverse-info-muted.dropdown-toggle {
  color: #ffffff;
  background-color: #7ee5e5;
  border-color: #7ee5e5;
}

.btn-inverse-danger-muted {
  background-color: rgba(247, 126, 185, 0.2);
  background-image: none;
  border-color: rgba(247, 126, 185, 0);
}

.btn-inverse-danger-muted:not(.btn-inverse-light) {
  color: #f77eb9;
}

.btn-inverse-danger-muted:hover {
  color: #ffffff;
  background-color: #f77eb9;
  border-color: #f77eb9;
}

.btn-inverse-danger-muted.focus,
.btn-inverse-danger-muted:focus {
  box-shadow: 0 0 0 3px rgba(247, 126, 185, 0.5);
}

.btn-inverse-danger-muted.disabled,
.btn-inverse-danger-muted:disabled {
  color: #f77eb9;
  background-color: transparent;
}

.btn-inverse-danger-muted.active,
.btn-inverse-danger-muted:active,
.show > .btn-inverse-danger-muted.dropdown-toggle {
  color: #ffffff;
  background-color: #f77eb9;
  border-color: #f77eb9;
}

.btn-outline-info:hover {
  color: #fff;
}

.rtl .btn,
.rtl .fc .fc-button,
.fc .rtl .fc-button,
.rtl .swal2-modal .swal2-actions button,
.swal2-modal .swal2-actions .rtl button,
.rtl .wizard > .actions a,
.wizard > .actions .rtl a {
  /* Buttons with icon and text */
}

.rtl .btn.btn-icon-text .btn-icon-prepend,
.rtl .fc .btn-icon-text.fc-button .btn-icon-prepend,
.fc .rtl .btn-icon-text.fc-button .btn-icon-prepend,
.rtl .swal2-modal .swal2-actions button.btn-icon-text .btn-icon-prepend,
.swal2-modal .swal2-actions .rtl button.btn-icon-text .btn-icon-prepend,
.rtl .wizard > .actions a.btn-icon-text .btn-icon-prepend,
.wizard > .actions .rtl a.btn-icon-text .btn-icon-prepend {
  margin-right: 0;
  margin-left: 0.5rem;
}

.rtl .btn.btn-icon-text .btn-icon-append,
.rtl .fc .btn-icon-text.fc-button .btn-icon-append,
.fc .rtl .btn-icon-text.fc-button .btn-icon-append,
.rtl .swal2-modal .swal2-actions button.btn-icon-text .btn-icon-append,
.swal2-modal .swal2-actions .rtl button.btn-icon-text .btn-icon-append,
.rtl .wizard > .actions a.btn-icon-text .btn-icon-append,
.wizard > .actions .rtl a.btn-icon-text .btn-icon-append {
  margin-left: 0;
  margin-right: 0.5rem;
}

.rtl .btn-group .btn + .btn,
.rtl .fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .btn + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-left .rtl .fc-button-group .btn + .btn,
.rtl .fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .btn + .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-right .rtl .fc-button-group .btn + .btn,
.rtl .btn-group .fc .fc-button + .btn,
.fc .rtl .btn-group .fc-button + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + .btn,
.rtl .btn-group .swal2-modal .swal2-actions button + .btn,
.swal2-modal .swal2-actions .rtl .btn-group button + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .btn,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + .btn,
.rtl .btn-group .wizard > .actions a + .btn,
.wizard > .actions .rtl .btn-group a + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl .btn-group .wizard > .actions a:hover + .btn,
.wizard > .actions .rtl .btn-group a:hover + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .btn,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + .btn,
.rtl .btn-group .wizard > .actions .disabled a + .btn,
.wizard > .actions .disabled .rtl .btn-group a + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .btn,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .btn,
.rtl .btn-group .fc .btn + .fc-button,
.fc .rtl .btn-group .btn + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + .fc-button,
.rtl .btn-group .fc .fc-button + .fc-button,
.fc .rtl .btn-group .fc-button + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + .fc-button,
.rtl .btn-group .swal2-modal .swal2-actions .fc button + .fc-button,
.fc .rtl .btn-group .swal2-modal .swal2-actions button + .fc-button,
.swal2-modal .swal2-actions .rtl .btn-group .fc button + .fc-button,
.fc .swal2-modal .swal2-actions .rtl .btn-group button + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + .fc-button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + .fc-button,
.rtl .btn-group .wizard > .actions .fc a + .fc-button,
.fc .rtl .btn-group .wizard > .actions a + .fc-button,
.wizard > .actions .rtl .btn-group .fc a + .fc-button,
.fc .wizard > .actions .rtl .btn-group a + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl .btn-group .wizard > .actions .fc a:hover + .fc-button,
.fc .rtl .btn-group .wizard > .actions a:hover + .fc-button,
.wizard > .actions .rtl .btn-group .fc a:hover + .fc-button,
.fc .wizard > .actions .rtl .btn-group a:hover + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + .fc-button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + .fc-button,
.rtl .btn-group .wizard > .actions .disabled .fc a + .fc-button,
.fc .rtl .btn-group .wizard > .actions .disabled a + .fc-button,
.wizard > .actions .disabled .rtl .btn-group .fc a + .fc-button,
.fc .wizard > .actions .disabled .rtl .btn-group a + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + .fc-button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + .fc-button,
.rtl .btn-group .swal2-modal .swal2-actions .btn + button,
.swal2-modal .swal2-actions .rtl .btn-group .btn + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .btn
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + button,
.rtl .btn-group .fc .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .rtl .btn-group .fc .fc-button + button,
.fc .rtl .btn-group .swal2-modal .swal2-actions .fc-button + button,
.swal2-modal .swal2-actions .fc .rtl .btn-group .fc-button + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .fc-button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + button,
.rtl .btn-group .swal2-modal .swal2-actions button + button,
.swal2-modal .swal2-actions .rtl .btn-group button + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + button,
.rtl .btn-group .wizard > .actions .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions a + button,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions a + button,
.swal2-modal .swal2-actions .wizard > .actions .rtl .btn-group a + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + button,
.rtl .btn-group .wizard > .actions .swal2-modal .swal2-actions a:hover + button,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions a:hover + button,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions a:hover + button,
.swal2-modal .swal2-actions .wizard > .actions .rtl .btn-group a:hover + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + button,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a:hover
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + button,
.rtl
  .btn-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .rtl
  .btn-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .rtl
  .btn-group
  a
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + button,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  a
  + button,
.swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + button,
.rtl .btn-group .wizard > .actions .btn + a,
.wizard > .actions .rtl .btn-group .btn + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl .btn-group .fc .wizard > .actions .fc-button + a,
.wizard > .actions .rtl .btn-group .fc .fc-button + a,
.fc .rtl .btn-group .wizard > .actions .fc-button + a,
.wizard > .actions .fc .rtl .btn-group .fc-button + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl .btn-group .swal2-modal .swal2-actions .wizard > .actions button + a,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions button + a,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions button + a,
.wizard > .actions .swal2-modal .swal2-actions .rtl .btn-group button + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + a,
.rtl .btn-group .wizard > .actions a + a,
.wizard > .actions .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a,
.rtl .btn-group .wizard > .actions a:hover + a,
.wizard > .actions .rtl .btn-group a:hover + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl .btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .disabled .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a,
.rtl .btn-group .wizard > .actions .btn + a:hover,
.wizard > .actions .rtl .btn-group .btn + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .btn
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + a:hover,
.rtl .btn-group .fc .wizard > .actions .fc-button + a:hover,
.wizard > .actions .rtl .btn-group .fc .fc-button + a:hover,
.fc .rtl .btn-group .wizard > .actions .fc-button + a:hover,
.wizard > .actions .fc .rtl .btn-group .fc-button + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .fc-button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + a:hover,
.rtl .btn-group .swal2-modal .swal2-actions .wizard > .actions button + a:hover,
.wizard > .actions .rtl .btn-group .swal2-modal .swal2-actions button + a:hover,
.swal2-modal .swal2-actions .rtl .btn-group .wizard > .actions button + a:hover,
.wizard > .actions .swal2-modal .swal2-actions .rtl .btn-group button + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a:hover,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  button
  + a:hover,
.wizard
  > .actions
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + a:hover,
.rtl .btn-group .wizard > .actions a + a:hover,
.wizard > .actions .rtl .btn-group a + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl .btn-group .wizard > .actions a:hover + a:hover,
.wizard > .actions .rtl .btn-group a:hover + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  a:hover
  + a:hover,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + a:hover,
.rtl .btn-group .wizard > .actions .disabled a + a:hover,
.wizard > .actions .disabled .rtl .btn-group a + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a:hover,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a:hover,
.rtl .btn-group .wizard > .actions .disabled .btn + a,
.wizard > .actions .disabled .rtl .btn-group .btn + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .btn
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .btn
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .btn
  + a,
.rtl .btn-group .fc .wizard > .actions .disabled .fc-button + a,
.wizard > .actions .disabled .rtl .btn-group .fc .fc-button + a,
.fc .rtl .btn-group .wizard > .actions .disabled .fc-button + a,
.wizard > .actions .disabled .fc .rtl .btn-group .fc-button + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  .fc-button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .fc-button
  + a,
.rtl
  .btn-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .btn-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  button
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  button
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .swal2-modal
  .swal2-actions
  button
  + a,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  button
  + a,
.wizard
  > .actions
  .disabled
  .swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  button
  + a,
.rtl .btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .rtl .btn-group .disabled a + a,
.wizard > .actions .disabled .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a,
.rtl .btn-group .wizard > .actions .disabled a:hover + a,
.wizard > .actions .rtl .btn-group .disabled a:hover + a,
.wizard > .actions .disabled .rtl .btn-group a:hover + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a:hover
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .disabled
  a:hover
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a:hover
  + a,
.rtl .btn-group .wizard > .actions .disabled a + a,
.wizard > .actions .disabled .rtl .btn-group a + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  a
  + a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  a
  + a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .wizard
  > .actions
  .disabled
  a
  + a,
.wizard
  > .actions
  .disabled
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  a
  + a {
  border-left: 1px solid;
}

.rtl .btn-group > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .btn-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .rtl
  .btn-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > button:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .wizard
  > .actions
  .btn-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .rtl
  .btn-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child):not(:last-child):not(.dorpdown-toggle) {
  border-radius: 0;
}

.rtl .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .fc .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc .rtl .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button:not(:last-child):not(.dropdown-toggle),
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > button:not(:last-child):not(.dropdown-toggle),
.rtl .wizard > .actions .btn-group > a:not(:last-child):not(.dropdown-toggle),
.wizard > .actions .rtl .btn-group > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.rtl .btn-group > .btn-group:not(:last-child) > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .btn,
.rtl .fc .btn-group > .btn-group:not(:last-child) > .fc-button,
.fc .rtl .btn-group > .btn-group:not(:last-child) > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .fc-button,
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > .btn-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > button,
.rtl .wizard > .actions .btn-group > .btn-group:not(:last-child) > a,
.wizard > .actions .rtl .btn-group > .btn-group:not(:last-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl .wizard > .actions .disabled .btn-group > .btn-group:not(:last-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > a {
  border-radius: 0.1875rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .btn-group > .btn:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn:not(:first-child),
.rtl .fc .btn-group > .fc-button:not(:first-child),
.fc .rtl .btn-group > .fc-button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button:not(:first-child),
.rtl .swal2-modal .swal2-actions .btn-group > button:not(:first-child),
.swal2-modal .swal2-actions .rtl .btn-group > button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > button:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > button:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > button:not(:first-child),
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > button:not(:first-child),
.rtl .wizard > .actions .btn-group > a:not(:first-child),
.wizard > .actions .rtl .btn-group > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > a:not(:first-child),
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > a:not(:first-child),
.rtl .wizard > .actions .disabled .btn-group > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > a:not(:first-child),
.rtl .btn-group > .btn-group:not(:first-child) > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .btn,
.rtl .fc .btn-group > .btn-group:not(:first-child) > .fc-button,
.fc .rtl .btn-group > .btn-group:not(:first-child) > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .fc-button,
.rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .btn-group
  > .btn-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .swal2-modal
  .swal2-actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.swal2-modal
  .swal2-actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > button,
.rtl .wizard > .actions .btn-group > .btn-group:not(:first-child) > a,
.wizard > .actions .rtl .btn-group > .btn-group:not(:first-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl .wizard > .actions .disabled .btn-group > .btn-group:not(:first-child) > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .btn-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-left
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .btn-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .wizard
  > .actions
  .disabled
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a {
  border-radius: 0.1875rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* Cards */

.card {
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
}

.card .card-body {
  padding: 1.5rem 1.5rem;
}

.card .card-body + .card-body {
  padding-top: 1rem;
}

.card .card-title {
  color: #000;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

.card .card-subtitle {
  font-weight: 400;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.card .card-description {
  margin-bottom: 0.875rem;
  font-weight: 400;
  color: #76838f;
}

.card.card-outline-success {
  border: 1px solid #10b759;
}

.card.card-outline-primary {
  border: 1px solid #727cf5;
}

.card.card-outline-warning {
  border: 1px solid #fbbc06;
}

.card.card-outline-danger {
  border: 1px solid #ff3366;
}

.card.card-rounded {
  border-radius: 5px;
}

.card.card-faded {
  background: #b5b0b2;
  border-color: #b5b0b2;
}

.card.card-circle-progress {
  color: #ffffff;
  text-align: center;
}

.card.card-img-holder {
  position: relative;
}

.card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.card.card-weather .weather-daily .weather-day {
  opacity: 0.5;
  font-weight: 900;
}

.card.card-weather .weather-daily i {
  font-size: 20px;
}

.card.card-weather .weather-daily .weather-temp {
  margin-top: 0.5rem;
  margin-bottom: 0;
  opacity: 0.5;
  font-size: 0.75rem;
}

.card-group {
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
}

.card-group .card {
  box-shadow: none;
}

.card-inverse-primary {
  background: rgba(114, 124, 245, 0.2);
  border: 1px solid #575eba;
  color: #575eba;
}

.card-inverse-secondary {
  background: rgba(121, 135, 161, 0.2);
  border: 1px solid #5c677a;
  color: #5c677a;
}

.card-inverse-success {
  background: rgba(16, 183, 89, 0.2);
  border: 1px solid #0c8b44;
  color: #0c8b44;
}

.card-inverse-info {
  background: rgba(102, 209, 209, 0.2);
  border: 1px solid #4e9f9f;
  color: #4e9f9f;
}

.card-inverse-warning {
  background: rgba(251, 188, 6, 0.2);
  border: 1px solid #bf8f05;
  color: #bf8f05;
}

.card-inverse-danger {
  background: rgba(255, 51, 102, 0.2);
  border: 1px solid #c2274e;
  color: #c2274e;
}

.card-inverse-light {
  background: rgba(236, 236, 236, 0.2);
  border: 1px solid #b3b3b3;
  color: #b3b3b3;
}

.card-inverse-dark {
  background: rgba(40, 47, 58, 0.2);
  border: 1px solid #1e242c;
  color: #1e242c;
}

.card-inverse-primary-muted {
  background: rgba(177, 207, 236, 0.2);
  border: 1px solid #879db3;
  color: #879db3;
}

.card-inverse-info-muted {
  background: rgba(126, 229, 229, 0.2);
  border: 1px solid #60aeae;
  color: #60aeae;
}

.card-inverse-danger-muted {
  background: rgba(247, 126, 185, 0.2);
  border: 1px solid #bc608d;
  color: #bc608d;
}

.data-icon-card-primary {
  background: #727cf5;
  color: #ffffff;
}

.data-icon-card-primary .card-title {
  color: #ffffff;
}

.data-icon-card-primary .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-primary .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-secondary {
  background: #7987a1;
  color: #ffffff;
}

.data-icon-card-secondary .card-title {
  color: #ffffff;
}

.data-icon-card-secondary .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-secondary .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-success {
  background: #10b759;
  color: #ffffff;
}

.data-icon-card-success .card-title {
  color: #ffffff;
}

.data-icon-card-success .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-success .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-info {
  background: #66d1d1;
  color: #ffffff;
}

.data-icon-card-info .card-title {
  color: #ffffff;
}

.data-icon-card-info .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-info .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-warning {
  background: #fbbc06;
  color: #ffffff;
}

.data-icon-card-warning .card-title {
  color: #ffffff;
}

.data-icon-card-warning .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-warning .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-danger {
  background: #ff3366;
  color: #ffffff;
}

.data-icon-card-danger .card-title {
  color: #ffffff;
}

.data-icon-card-danger .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-danger .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-light {
  background: #ececec;
  color: #ffffff;
}

.data-icon-card-light .card-title {
  color: #ffffff;
}

.data-icon-card-light .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-light .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-dark {
  background: #282f3a;
  color: #ffffff;
}

.data-icon-card-dark .card-title {
  color: #ffffff;
}

.data-icon-card-dark .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-dark .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-primary-muted {
  background: #b1cfec;
  color: #ffffff;
}

.data-icon-card-primary-muted .card-title {
  color: #ffffff;
}

.data-icon-card-primary-muted .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-primary-muted .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-info-muted {
  background: #7ee5e5;
  color: #ffffff;
}

.data-icon-card-info-muted .card-title {
  color: #ffffff;
}

.data-icon-card-info-muted .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-info-muted .background-icon i {
  z-index: 1;
  color: #ffffff;
}

.data-icon-card-danger-muted {
  background: #f77eb9;
  color: #ffffff;
}

.data-icon-card-danger-muted .card-title {
  color: #ffffff;
}

.data-icon-card-danger-muted .background-icon::before {
  content: url("https://via.placeholder.com/410x251");
  position: absolute;
}

.data-icon-card-danger-muted .background-icon i {
  z-index: 1;
  color: #ffffff;
}
/* Checkboxes and Radios */

.form-check {
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 0;
}

.form-check .form-check-label {
  min-height: 18px;
  display: block;
  margin-left: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  position: relative;
  top: 4px;
}

.form-check .form-check-label input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.form-check .form-check-label input[type="checkbox"] + .input-frame:before,
.form-check .form-check-label input[type="checkbox"] + .input-frame:after {
  position: absolute;
  top: 0;
  left: 0;
}

.form-check .form-check-label input[type="checkbox"] + .input-frame:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #727cf5;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.form-check .form-check-label input[type="checkbox"] + .input-frame:after {
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  font-family: feather;
  content: "\e83f";
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  font-size: 0.9375rem;
  font-weight: bold;
  color: #ffffff;
}

.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-frame:before {
  background: #727cf5;
  border-width: 0;
}

.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-frame:after {
  width: 18px;
  opacity: 1;
  line-height: 18px;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.form-check
  .form-check-label
  input[type="checkbox"]:disabled
  + .input-frame:before {
  border-color: #e8ebf1;
}

.form-check
  .form-check-label
  input[type="checkbox"]:disabled:checked
  + .input-frame:after {
  background: #e8ebf1;
  color: #ffffff;
}

.form-check .form-check-label input[type="radio"] + .input-frame:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: solid #727cf5;
  border-width: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.form-check .form-check-label input[type="radio"] + .input-frame:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
}

.form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-frame:before {
  background: #727cf5;
  border-width: 0;
}

.form-check .form-check-label input[type="radio"]:checked + .input-frame:after {
  opacity: 1;
  line-height: 1.5;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.form-check
  .form-check-label
  input[type="radio"]:disabled
  + .input-frame:before {
  border-color: #e8ebf1;
}

.form-check
  .form-check-label
  input[type="radio"]:disabled:checked
  + .input-frame:before {
  background: #e8ebf1;
}

.form-check
  .form-check-label
  input[type="radio"]:disabled:checked
  + .input-frame:after {
  background: #ffffff;
}

.form-check-primary.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-primary.form-check label input[type="radio"] + .input-frame:before {
  border-color: #727cf5;
}

.form-check-primary.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-primary.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #727cf5;
}

.form-check-secondary.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-secondary.form-check
  label
  input[type="radio"]
  + .input-frame:before {
  border-color: #7987a1;
}

.form-check-secondary.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-secondary.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #7987a1;
}

.form-check-success.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-success.form-check label input[type="radio"] + .input-frame:before {
  border-color: #10b759;
}

.form-check-success.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-success.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #10b759;
}

.form-check-info.form-check label input[type="checkbox"] + .input-frame:before,
.form-check-info.form-check label input[type="radio"] + .input-frame:before {
  border-color: #66d1d1;
}

.form-check-info.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-info.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #66d1d1;
}

.form-check-warning.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-warning.form-check label input[type="radio"] + .input-frame:before {
  border-color: #fbbc06;
}

.form-check-warning.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-warning.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #fbbc06;
}

.form-check-danger.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-danger.form-check label input[type="radio"] + .input-frame:before {
  border-color: #ff3366;
}

.form-check-danger.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-danger.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #ff3366;
}

.form-check-light.form-check label input[type="checkbox"] + .input-frame:before,
.form-check-light.form-check label input[type="radio"] + .input-frame:before {
  border-color: #ececec;
}

.form-check-light.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-light.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #ececec;
}

.form-check-dark.form-check label input[type="checkbox"] + .input-frame:before,
.form-check-dark.form-check label input[type="radio"] + .input-frame:before {
  border-color: #282f3a;
}

.form-check-dark.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-dark.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #282f3a;
}

.form-check-primary-muted.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-primary-muted.form-check
  label
  input[type="radio"]
  + .input-frame:before {
  border-color: #b1cfec;
}

.form-check-primary-muted.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-primary-muted.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #b1cfec;
}

.form-check-info-muted.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-info-muted.form-check
  label
  input[type="radio"]
  + .input-frame:before {
  border-color: #7ee5e5;
}

.form-check-info-muted.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-info-muted.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #7ee5e5;
}

.form-check-danger-muted.form-check
  label
  input[type="checkbox"]
  + .input-frame:before,
.form-check-danger-muted.form-check
  label
  input[type="radio"]
  + .input-frame:before {
  border-color: #f77eb9;
}

.form-check-danger-muted.form-check
  label
  input[type="checkbox"]:checked
  + .input-frame:before,
.form-check-danger-muted.form-check
  label
  input[type="radio"]:checked
  + .input-frame:before {
  border-color: #f77eb9;
}

.rtl .form-check .form-check-label {
  margin-left: 0;
  margin-right: 1.75rem;
}

.rtl .form-check .form-check-label input[type="checkbox"] + .input-frame:before,
.rtl .form-check .form-check-label input[type="checkbox"] + .input-frame:after {
  left: auto;
  right: 0;
}

.rtl
  .form-check
  .form-check-label
  input[type="checkbox"]
  + .input-frame::after {
  right: 0;
  transform: scale(-1, 1);
  text-align: center;
}

.rtl .form-check .form-check-label input[type="radio"] + .input-frame:before,
.rtl .form-check .form-check-label input[type="radio"] + .input-frame:after {
  left: auto;
  right: 0;
}

.rtl .form-check .form-check-label input[type="radio"] + .input-frame::after {
  right: 6px;
}

.dashboard-date {
  width: 211px;
}

.dashboard-date.datepicker.input-group {
  border: 1px solid #727cf5;
}

.dashboard-date.datepicker.input-group input {
  height: 32px;
  background: transparent;
  color: #686868;
}

.dashboard-date.datepicker.input-group span {
  border-left: none;
  border-right: 1px solid #727cf5;
}

#flotChart1 {
  height: 350px;
}

@media (max-width: 767px) {
  #flotChart1 {
    height: 200px;
  }
}

#progressbar1 {
  width: 200px;
  height: 200px;
  position: relative;
}

#progressbar1 svg {
  height: 200px;
  width: 200px;
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
}

.monthly-sales-chart-wrapper {
  height: 270px;
}

@media (max-width: 767px) {
  .monthly-sales-chart-wrapper {
    height: 200px;
  }
}

.rtl .dashboard-date.datepicker.input-group span {
  border-right: 0;
  border-left: 1px solid #727cf5;
}
/* Dropdowns */

.dropdown .dropdown-toggle:after,
.btn-group .dropdown-toggle:after,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-toggle:after,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-toggle:after {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font: normal normal normal 24px/1 "feather";
  content: "\e842";
  width: auto;
  height: auto;
  vertical-align: middle;
  line-height: 0.625rem;
  font-size: 0.875rem;
  margin-left: 0.3rem;
}

.dropdown .dropdown-menu,
.btn-group .dropdown-menu,
.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .dropdown-menu,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .dropdown-menu {
  padding: 0.35rem;
  margin-top: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  border-radius: 0;
}

.dropdown .dropdown-menu .dropdown-item,
.btn-group .dropdown-menu .dropdown-item,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item {
  font-size: 0.812rem;
  padding: 0.25rem 0.875rem;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
}

.dropdown .dropdown-menu .dropdown-item i,
.dropdown .dropdown-menu .dropdown-item svg,
.btn-group .dropdown-menu .dropdown-item i,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item
  i,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item
  i,
.btn-group .dropdown-menu .dropdown-item svg,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item
  svg,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item
  svg {
  color: #686868;
  transition: all 0.2s ease-in-out;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.btn-group .dropdown-menu .dropdown-item:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover {
  background-color: rgba(114, 124, 245, 0.1);
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:hover i,
.dropdown .dropdown-menu .dropdown-item:hover svg,
.btn-group .dropdown-menu .dropdown-item:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover,
.btn-group .dropdown-menu .dropdown-item:hover i,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover
  i,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover
  i,
.btn-group .dropdown-menu .dropdown-item:hover svg,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover
  svg,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item:hover
  svg {
  color: #727cf5;
}

.dropdown .dropdown-menu .dropdown-item:active,
.btn-group .dropdown-menu .dropdown-item:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-menu
  .dropdown-item:active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-menu
  .dropdown-item:active {
  background: initial;
}

.rtl .dropdown .dropdown-toggle::after,
.rtl .btn-group .dropdown-toggle::after,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .dropdown-toggle::after,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .rtl
  .fc-button-group
  .dropdown-toggle::after,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .dropdown-toggle::after,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .rtl
  .fc-button-group
  .dropdown-toggle::after {
  margin-left: 0;
  margin-right: 0.3rem;
}

.rtl .dropdown-menu {
  text-align: right;
}
/* Forms */

.input-group-append,
.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none;
}

.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  border-color: #e8ebf1;
  padding: 0.5rem 0.75rem;
  color: #c9c8c8;
}

.form-control,
select,
.email-compose-fields .select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint {
  border: 1px solid #e8ebf1;
  font-weight: 400;
  font-size: 0.875rem;
}

.form-control.form-control-sm,
select.form-control-sm,
.email-compose-fields
  .select2-container--default
  .form-control-sm.select2-selection--multiple,
.select2-container--default .form-control-sm.select2-selection--single,
.select2-container--default
  .select2-selection--single
  .form-control-sm.select2-search__field,
.form-control-sm.typeahead,
.form-control-sm.tt-query,
.form-control-sm.tt-hint {
  padding: 0.25rem 0.75rem;
}

select {
  border-radius: 0;
}

select.form-control,
select,
.email-compose-fields
  .select2-container--default
  select.select2-selection--multiple,
.select2-container--default select.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.select2-search__field,
select.typeahead,
select.tt-query,
select.tt-hint {
  padding: 0 0.75rem;
  border: 1px solid #e8ebf1;
  border-radius: 0px;
  outline: none;
  color: #c9c8c8;
}

select.form-control.border-primary,
select.border-primary,
.email-compose-fields
  .select2-container--default
  select.border-primary.select2-selection--multiple,
.select2-container--default select.border-primary.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-primary.select2-search__field {
  outline: 1px solid #727cf5;
}

select.form-control.border-primary:focus,
select.border-primary:focus,
.email-compose-fields
  .select2-container--default
  select.border-primary.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-primary.select2-search__field:focus {
  outline: 1px solid #727cf5;
}

select.form-control.border-secondary,
select.border-secondary,
.email-compose-fields
  .select2-container--default
  select.border-secondary.select2-selection--multiple,
.select2-container--default select.border-secondary.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-secondary.select2-search__field {
  outline: 1px solid #7987a1;
}

select.form-control.border-secondary:focus,
select.border-secondary:focus,
.email-compose-fields
  .select2-container--default
  select.border-secondary.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-secondary.select2-search__field:focus {
  outline: 1px solid #7987a1;
}

select.form-control.border-success,
select.border-success,
.email-compose-fields
  .select2-container--default
  select.border-success.select2-selection--multiple,
.select2-container--default select.border-success.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-success.select2-search__field {
  outline: 1px solid #10b759;
}

select.form-control.border-success:focus,
select.border-success:focus,
.email-compose-fields
  .select2-container--default
  select.border-success.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-success.select2-search__field:focus {
  outline: 1px solid #10b759;
}

select.form-control.border-info,
select.border-info,
.email-compose-fields
  .select2-container--default
  select.border-info.select2-selection--multiple,
.select2-container--default select.border-info.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-info.select2-search__field {
  outline: 1px solid #66d1d1;
}

select.form-control.border-info:focus,
select.border-info:focus,
.email-compose-fields
  .select2-container--default
  select.border-info.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-info.select2-search__field:focus {
  outline: 1px solid #66d1d1;
}

select.form-control.border-warning,
select.border-warning,
.email-compose-fields
  .select2-container--default
  select.border-warning.select2-selection--multiple,
.select2-container--default select.border-warning.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-warning.select2-search__field {
  outline: 1px solid #fbbc06;
}

select.form-control.border-warning:focus,
select.border-warning:focus,
.email-compose-fields
  .select2-container--default
  select.border-warning.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-warning.select2-search__field:focus {
  outline: 1px solid #fbbc06;
}

select.form-control.border-danger,
select.border-danger,
.email-compose-fields
  .select2-container--default
  select.border-danger.select2-selection--multiple,
.select2-container--default select.border-danger.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-danger.select2-search__field {
  outline: 1px solid #ff3366;
}

select.form-control.border-danger:focus,
select.border-danger:focus,
.email-compose-fields
  .select2-container--default
  select.border-danger.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-danger.select2-search__field:focus {
  outline: 1px solid #ff3366;
}

select.form-control.border-light,
select.border-light,
.email-compose-fields
  .select2-container--default
  select.border-light.select2-selection--multiple,
.select2-container--default select.border-light.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-light.select2-search__field {
  outline: 1px solid #ececec;
}

select.form-control.border-light:focus,
select.border-light:focus,
.email-compose-fields
  .select2-container--default
  select.border-light.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-light.select2-search__field:focus {
  outline: 1px solid #ececec;
}

select.form-control.border-dark,
select.border-dark,
.email-compose-fields
  .select2-container--default
  select.border-dark.select2-selection--multiple,
.select2-container--default select.border-dark.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-dark.select2-search__field {
  outline: 1px solid #282f3a;
}

select.form-control.border-dark:focus,
select.border-dark:focus,
.email-compose-fields
  .select2-container--default
  select.border-dark.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-dark.select2-search__field:focus {
  outline: 1px solid #282f3a;
}

select.form-control.border-primary-muted,
select.border-primary-muted,
.email-compose-fields
  .select2-container--default
  select.border-primary-muted.select2-selection--multiple,
.select2-container--default
  select.border-primary-muted.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-primary-muted.select2-search__field {
  outline: 1px solid #b1cfec;
}

select.form-control.border-primary-muted:focus,
select.border-primary-muted:focus,
.email-compose-fields
  .select2-container--default
  select.border-primary-muted.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-primary-muted.select2-search__field:focus {
  outline: 1px solid #b1cfec;
}

select.form-control.border-info-muted,
select.border-info-muted,
.email-compose-fields
  .select2-container--default
  select.border-info-muted.select2-selection--multiple,
.select2-container--default select.border-info-muted.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-info-muted.select2-search__field {
  outline: 1px solid #7ee5e5;
}

select.form-control.border-info-muted:focus,
select.border-info-muted:focus,
.email-compose-fields
  .select2-container--default
  select.border-info-muted.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-info-muted.select2-search__field:focus {
  outline: 1px solid #7ee5e5;
}

select.form-control.border-danger-muted,
select.border-danger-muted,
.email-compose-fields
  .select2-container--default
  select.border-danger-muted.select2-selection--multiple,
.select2-container--default
  select.border-danger-muted.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-danger-muted.select2-search__field {
  outline: 1px solid #f77eb9;
}

select.form-control.border-danger-muted:focus,
select.border-danger-muted:focus,
.email-compose-fields
  .select2-container--default
  select.border-danger-muted.select2-selection--multiple:focus,
.select2-container--default
  .select2-selection--single
  select.border-danger-muted.select2-search__field:focus {
  outline: 1px solid #f77eb9;
}

.form-group label {
  font-size: 0.875rem;
  line-height: 1.4rem;
  vertical-align: top;
  margin-bottom: 0.5rem;
}

.form-group.has-danger .form-control,
.form-group.has-danger select,
.form-group.has-danger
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .form-group.has-danger
  .select2-selection--multiple,
.form-group.has-danger .select2-container--default .select2-selection--single,
.select2-container--default .form-group.has-danger .select2-selection--single,
.form-group.has-danger
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .form-group.has-danger
  .select2-search__field,
.form-group.has-danger .typeahead,
.form-group.has-danger .tt-query,
.form-group.has-danger .tt-hint {
  border-color: #ff3366;
}

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute;
}

.form-group .file-upload-info {
  background: transparent;
}
/* Icons */

.icons-list {
  border-left: 1px solid #e8ebf1;
  border-top: 1px solid #e8ebf1;
}

.icons-list > div {
  border-bottom: 1px solid #e8ebf1;
  border-right: 1px solid #e8ebf1;
  background: #f9fafb;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 20px;
  font-weight: 400;
  font-size: 0.875rem;
  transition: all 0.3s ease-in-out;
}

.icons-list > div i {
  display: inline-block;
  font-size: 20px;
  text-align: left;
  margin-right: 12px;
  color: #7987a1;
  transition: all 0.3s ease-in-out;
}

.icons-list > div svg {
  width: 20px;
  margin-right: 12px;
  color: #7987a1;
  transition: all 0.3s ease-in-out;
}

.icons-list > div:hover {
  cursor: all-scroll;
  box-shadow: 0 0 10px 0 #e6e6e6;
  z-index: 9;
}

.icons-list > div:hover i,
.icons-list > div:hover svg {
  transform: scale(1.3);
  color: #727cf5;
}

.icon-in-bg {
  width: 55px;
  height: 55px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.icon-lg,
.data-icon-card-primary .background-icon i,
.data-icon-card-secondary .background-icon i,
.data-icon-card-success .background-icon i,
.data-icon-card-info .background-icon i,
.data-icon-card-warning .background-icon i,
.data-icon-card-danger .background-icon i,
.data-icon-card-light .background-icon i,
.data-icon-card-dark .background-icon i,
.data-icon-card-primary-muted .background-icon i,
.data-icon-card-info-muted .background-icon i,
.data-icon-card-danger-muted .background-icon i {
  font-size: 2.875rem;
}

.icon-md {
  font-size: 2rem;
}

.icon-sm {
  font-size: 1rem;
}

.rtl .icons-list > div svg,
.rtl .icons-list > div i {
  margin-right: 0;
  margin-left: 12px;
}

.rtl .input-group > .input-group-append > .btn,
.rtl .fc .input-group > .input-group-append > .fc-button,
.fc .rtl .input-group > .input-group-append > .fc-button,
.rtl .swal2-modal .swal2-actions .input-group > .input-group-append > button,
.swal2-modal .swal2-actions .rtl .input-group > .input-group-append > button,
.rtl .wizard > .actions .input-group > .input-group-append > a,
.wizard > .actions .rtl .input-group > .input-group-append > a,
.rtl .wizard > .actions .disabled .input-group > .input-group-append > a {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .input-group > .form-control:not(:last-child),
.rtl .input-group > select:not(:last-child),
.rtl
  .email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple:not(:last-child),
.email-compose-fields
  .select2-container--default
  .rtl
  .input-group
  > .select2-selection--multiple:not(:last-child),
.rtl
  .select2-container--default
  .input-group
  > .select2-selection--single:not(:last-child),
.select2-container--default
  .rtl
  .input-group
  > .select2-selection--single:not(:last-child),
.rtl
  .select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:last-child),
.select2-container--default
  .select2-selection--single
  .rtl
  .input-group
  > .select2-search__field:not(:last-child),
.rtl .input-group > .typeahead:not(:last-child),
.rtl .input-group > .tt-query:not(:last-child),
.rtl .input-group > .tt-hint:not(:last-child),
.rtl .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .input-group > .form-control:not(:first-child),
.rtl .input-group > select:not(:first-child),
.rtl
  .email-compose-fields
  .select2-container--default
  .input-group
  > .select2-selection--multiple:not(:first-child),
.email-compose-fields
  .select2-container--default
  .rtl
  .input-group
  > .select2-selection--multiple:not(:first-child),
.rtl
  .select2-container--default
  .input-group
  > .select2-selection--single:not(:first-child),
.select2-container--default
  .rtl
  .input-group
  > .select2-selection--single:not(:first-child),
.rtl
  .select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:first-child),
.select2-container--default
  .select2-selection--single
  .rtl
  .input-group
  > .select2-search__field:not(:first-child),
.rtl .input-group > .typeahead:not(:first-child),
.rtl .input-group > .tt-query:not(:first-child),
.rtl .input-group > .tt-hint:not(:first-child),
.rtl .input-group > .custom-select:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rtl .list-group {
  padding-right: 0;
}

.modal .modal-dialog .modal-header .close {
  margin: 0;
  padding: 0;
}

.rtl .modal-footer > :not(:last-child) {
  margin-left: 0.25rem;
}

.pagination .page-item .page-link {
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-item .page-link svg {
  width: 17px;
  height: 17px;
}

.pagination.pagination-separated .page-item {
  margin-left: 2px;
  margin-right: 2px;
}

.pagination.pagination-separated .page-item:first-child {
  margin-left: 0;
}

.pagination.pagination-separated .page-item:last-child {
  margin-right: 0;
}

.pagination.pagination-separated .page-item .page-link {
  border-radius: 2px;
}

.pagination.pagination-rounded .page-item {
  margin-right: 3px;
  margin-left: 3px;
}

.pagination.pagination-rounded .page-item .page-link {
  border-radius: 50px;
}

.rtl .pagination .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .pagination .page-item:last-child .page-link {
  margin-left: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.popover .popover-header {
  font-size: 14px;
}

.popover .popover-body {
  font-size: 13px;
}

.profile-page .profile-header {
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  border: 1px solid #f2f4f9;
}

.profile-page .profile-header .cover {
  position: relative;
  border-radius: 0.25rem 0.25rem 0 0;
}

.profile-page .profile-header .cover figure {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .profile-page .profile-header .cover figure {
    height: 110px;
    overflow: hidden;
  }
}

@media (min-width: 2400px) {
  .profile-page .profile-header .cover figure {
    height: 280px;
    overflow: hidden;
  }
}

.profile-page .profile-header .cover figure img {
  border-radius: 0.25rem 0.25rem 0 0;
  width: 100%;
}

@media (max-width: 767px) {
  .profile-page .profile-header .cover figure img {
    -webkit-transform: scale(2);
    transform: scale(2);
    margin-top: 15px;
  }
}

@media (min-width: 2400px) {
  .profile-page .profile-header .cover figure img {
    margin-top: -55px;
  }
}

.profile-page .profile-header .cover .gray-shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(rgba(255, 255, 255, 0.1), #fff 99%);
}

.profile-page .profile-header .cover .cover-body {
  position: absolute;
  bottom: -20px;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 0 20px;
}

.profile-page .profile-header .cover .cover-body .profile-pic {
  border-radius: 50%;
  width: 100px;
}

@media (max-width: 767px) {
  .profile-page .profile-header .cover .cover-body .profile-pic {
    width: 70px;
  }
}

.profile-page .profile-header .cover .cover-body .profile-name {
  font-size: 20px;
  font-weight: 600;
  margin-left: 17px;
}

.profile-page .profile-header .header-links {
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #fff;
  border-radius: 0 0 0.25rem 0.25rem;
}

.profile-page .profile-header .header-links ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.profile-page .profile-header .header-links ul li a {
  color: #000;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.profile-page .profile-header .header-links ul li:hover,
.profile-page .profile-header .header-links ul li.active {
  color: #727cf5;
}

.profile-page .profile-header .header-links ul li:hover a,
.profile-page .profile-header .header-links ul li.active a {
  color: #727cf5;
}

.profile-page .profile-body .left-wrapper .social-links a {
  width: 30px;
  height: 30px;
}

.profile-page .profile-body .right-wrapper .latest-photos > .row {
  margin-right: 0;
  margin-left: 0;
}

.profile-page .profile-body .right-wrapper .latest-photos > .row > div {
  padding-left: 3px;
  padding-right: 3px;
}

.profile-page .profile-body .right-wrapper .latest-photos > .row > div figure {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 6px;
}

.profile-page
  .profile-body
  .right-wrapper
  .latest-photos
  > .row
  > div
  figure:hover {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}

.profile-page
  .profile-body
  .right-wrapper
  .latest-photos
  > .row
  > div
  figure
  img {
  border-radius: 0.25rem;
}

.rtl .profile-page .profile-header .cover .cover-body .profile-name {
  margin-left: 0;
  margin-right: 17px;
}
/* Tables */

.table {
  margin-bottom: 0;
}

.table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #686868;
}

.table thead th i {
  margin-left: 0.325rem;
}

.table th,
.table td {
  vertical-align: middle;
  line-height: 1;
  white-space: nowrap;
}

.table td {
  font-size: 0.875rem;
}

.table td img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.table td .badge {
  margin-bottom: 0;
}

.table.table-borderless {
  border: none;
}

.table.table-borderless tr,
.table.table-borderless td,
.table.table-borderless th {
  border: none;
}

.table.table-bordered {
  border-top: 1px solid #e8ebf1;
}

.table.table-dark thead tr th {
  color: #b5b5b5;
}

.timeline {
  border-left: 3px solid #727cf5;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(114, 124, 245, 0.09);
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 40%;
}

@media (max-width: 767px) {
  .timeline {
    max-width: 98%;
    padding: 25px;
  }
}

.timeline h1 {
  font-weight: 300;
  font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline .event {
  border-bottom: 1px dashed #e8ebf1;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline .event {
    padding-top: 30px;
  }
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline .event:before {
    left: 0px;
    text-align: left;
  }
}

.timeline .event:after {
  -webkit-box-shadow: 0 0 0 3px #727cf5;
  box-shadow: 0 0 0 3px #727cf5;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline .event:after {
    left: -31.8px;
  }
}

.rtl .timeline {
  border-left: 0;
  text-align: right;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
  left: 0;
  right: -170px;
}

.rtl .timeline .event::after {
  left: 0;
  right: -55.8px;
}

.chat-wrapper {
  height: calc(100vh - 60px - 102px);
}

@media (max-width: 991px) {
  .chat-wrapper {
    min-height: 100%;
  }
}

@media (max-width: 991px) {
  .chat-wrapper {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .chat-wrapper .chat-aside {
    padding-right: 23px;
  }
}

.chat-wrapper .chat-aside .aside-body .nav-tabs .nav-item a {
  color: #000;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.chat-wrapper .chat-aside .aside-body .nav-tabs .nav-item a.active {
  color: #727cf5;
}

.chat-wrapper .chat-aside .aside-body .tab-content {
  position: relative;
  max-height: calc(100vh - 385px);
}

@media (max-width: 767px) {
  .chat-wrapper .chat-aside .aside-body .tab-content {
    min-height: calc(100vh - 360px);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .chat-wrapper .chat-aside .aside-body .tab-content {
    min-height: calc(100vh - 388px);
  }
}

.chat-wrapper
  .chat-aside
  .aside-body
  .tab-content
  .tab-pane
  .chat-list
  .chat-item
  a
  > div {
  padding-top: 11px;
  padding-bottom: 11px;
}

@media (max-width: 991px) {
  .chat-wrapper .chat-content {
    position: absolute;
    background: #fff;
    left: 0;
    bottom: -1px;
    top: 0;
    right: 0;
    display: none;
  }
  .chat-wrapper .chat-content.show {
    display: block;
  }
}

.chat-wrapper .chat-content .chat-header {
  padding: 0 10px;
}

.chat-wrapper .chat-content .chat-body {
  position: relative;
  max-height: calc(100vh - 340px);
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .chat-wrapper .chat-content .chat-body {
    max-height: calc(100vh - 315px);
  }
}

@media (max-width: 991px) {
  .chat-wrapper .chat-content .chat-body {
    max-height: calc(100vh - 342px);
  }
}

.chat-wrapper .chat-content .chat-body .messages {
  padding: 0 10px;
  list-style-type: none;
}

.chat-wrapper .chat-content .chat-body .messages .message-item {
  display: -webkit-flex;
  display: flex;
  max-width: 80%;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .chat-wrapper .chat-content .chat-body .messages .message-item {
    max-width: 95%;
  }
}

.chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item
  .content
  .bubble {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.chat-wrapper .chat-content .chat-body .messages .message-item .content span {
  font-size: 12px;
  color: #686868;
}

.chat-wrapper .chat-content .chat-body .messages .message-item.friend img {
  -webkit-order: 1;
  order: 1;
  margin-right: 15px;
}

.chat-wrapper .chat-content .chat-body .messages .message-item.friend .content {
  -webkit-order: 2;
  order: 2;
}

.chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.friend
  .content
  .bubble {
  background: rgba(114, 124, 245, 0.1);
  border-radius: 0 5px 5px;
}

.chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.friend
  .content
  .bubble::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -10px;
  top: 0;
  border-top: 5px solid rgba(114, 124, 245, 0.1);
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid rgba(114, 124, 245, 0.1);
}

.chat-wrapper .chat-content .chat-body .messages .message-item.me {
  margin-left: auto;
}

.chat-wrapper .chat-content .chat-body .messages .message-item.me img {
  -webkit-order: 2;
  order: 2;
  margin-left: 15px;
}

.chat-wrapper .chat-content .chat-body .messages .message-item.me .content {
  -webkit-order: 1;
  order: 1;
  margin-left: auto;
}

.chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.me
  .content
  .bubble {
  background: rgba(102, 209, 209, 0.1);
  border-radius: 5px 0 5px 5px;
  margin-left: auto;
}

.chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.me
  .content
  .bubble::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: -10px;
  top: 0;
  border-top: 5px solid rgba(102, 209, 209, 0.1);
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(102, 209, 209, 0.1);
  border-right: 5px solid transparent;
}

.chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.me
  .content
  span {
  text-align: right;
  display: block;
}

.chat-wrapper .chat-content .chat-footer button {
  width: 40px;
  height: 40px;
}

.chat-wrapper .chat-content .chat-footer button svg {
  height: 18px;
}

.chat-wrapper .chat-content .chat-footer .form-control,
.chat-wrapper .chat-content .chat-footer select,
.chat-wrapper
  .chat-content
  .chat-footer
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--multiple,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--single,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-search__field,
.chat-wrapper .chat-content .chat-footer .typeahead,
.chat-wrapper .chat-content .chat-footer .tt-query,
.chat-wrapper .chat-content .chat-footer .tt-hint {
  height: 40px;
}

.chat-wrapper
  .chat-content
  .chat-footer
  .form-control::-webkit-input-placeholder,
.chat-wrapper .chat-content .chat-footer select::-webkit-input-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple::-webkit-input-placeholder,
.email-compose-fields
  .select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--multiple::-webkit-input-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single::-webkit-input-placeholder,
.select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--single::-webkit-input-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single
  .select2-search__field::-webkit-input-placeholder,
.select2-container--default
  .select2-selection--single
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-search__field::-webkit-input-placeholder,
.chat-wrapper .chat-content .chat-footer .typeahead::-webkit-input-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-query::-webkit-input-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-hint::-webkit-input-placeholder {
  padding-top: 3px;
}

.chat-wrapper .chat-content .chat-footer .form-control:-moz-placeholder,
.chat-wrapper .chat-content .chat-footer select:-moz-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple:-moz-placeholder,
.email-compose-fields
  .select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--multiple:-moz-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single:-moz-placeholder,
.select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--single:-moz-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single
  .select2-search__field:-moz-placeholder,
.select2-container--default
  .select2-selection--single
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-search__field:-moz-placeholder,
.chat-wrapper .chat-content .chat-footer .typeahead:-moz-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-query:-moz-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-hint:-moz-placeholder {
  padding-top: 3px;
}

.chat-wrapper .chat-content .chat-footer .form-control::-moz-placeholder,
.chat-wrapper .chat-content .chat-footer select::-moz-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple::-moz-placeholder,
.email-compose-fields
  .select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--multiple::-moz-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single::-moz-placeholder,
.select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--single::-moz-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single
  .select2-search__field::-moz-placeholder,
.select2-container--default
  .select2-selection--single
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-search__field::-moz-placeholder,
.chat-wrapper .chat-content .chat-footer .typeahead::-moz-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-query::-moz-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-hint::-moz-placeholder {
  padding-top: 3px;
}

.chat-wrapper .chat-content .chat-footer .form-control:-ms-input-placeholder,
.chat-wrapper .chat-content .chat-footer select:-ms-input-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple:-ms-input-placeholder,
.email-compose-fields
  .select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--multiple:-ms-input-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single:-ms-input-placeholder,
.select2-container--default
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-selection--single:-ms-input-placeholder,
.chat-wrapper
  .chat-content
  .chat-footer
  .select2-container--default
  .select2-selection--single
  .select2-search__field:-ms-input-placeholder,
.select2-container--default
  .select2-selection--single
  .chat-wrapper
  .chat-content
  .chat-footer
  .select2-search__field:-ms-input-placeholder,
.chat-wrapper .chat-content .chat-footer .typeahead:-ms-input-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-query:-ms-input-placeholder,
.chat-wrapper .chat-content .chat-footer .tt-hint:-ms-input-placeholder {
  padding-top: 3px;
}

.chat-wrapper figure {
  position: relative;
}

.chat-wrapper figure .status {
  width: 11px;
  height: 11px;
  background: #7987a1;
  position: absolute;
  bottom: 0px;
  right: -2px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.chat-wrapper figure .status.online {
  background: #10b759;
}

.chat-wrapper figure .status.offline {
  background: #7987a1;
}

.rtl .chat-wrapper .chat-content .chat-body .messages .message-item.friend img {
  margin-right: 0;
  margin-left: 15px;
}

.rtl
  .chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.friend
  .content
  .bubble {
  border-radius: 5px 0 5px 5px;
  margin-right: 0;
  margin-left: auto;
}

.rtl
  .chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.friend
  .content
  .bubble::before {
  left: auto;
  right: -10px;
  border-right: 5px solid transparent;
  border-left: 5px solid rgba(114, 124, 245, 0.1);
}

.rtl .chat-wrapper .chat-content .chat-body .messages .message-item.me {
  margin-left: 0;
  margin-right: auto;
}

.rtl .chat-wrapper .chat-content .chat-body .messages .message-item.me img {
  margin-left: 0;
  margin-right: 15px;
}

.rtl
  .chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.me
  .content
  .bubble {
  border-radius: 0 5px 5px 5px;
  margin-left: 0;
  margin-right: auto;
}

.rtl
  .chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.me
  .content
  .bubble::before {
  right: auto;
  left: -10px;
  border-right: 5px solid rgba(102, 209, 209, 0.1);
  border-left: 5px solid transparent;
}

.rtl
  .chat-wrapper
  .chat-content
  .chat-body
  .messages
  .message-item.me
  .content
  span {
  text-align: left;
}

.auth-page .auth-left-wrapper {
  width: 100%;
  height: 100%;
  background-image: url(https://via.placeholder.com/219x452);
  background-size: cover;
}

@media (min-width: 992px) {
  .inbox-wrapper .email-aside .aside-content {
    padding-right: 10px;
  }
}

.inbox-wrapper .email-aside .aside-content .aside-header {
  padding: 0 0 5px;
  position: relative;
}

.inbox-wrapper .email-aside .aside-content .aside-header .title {
  display: block;
  margin: 3px 0 0;
  font-size: 1.1rem;
  line-height: 27px;
  color: #686868;
}

.inbox-wrapper .email-aside .aside-content .aside-header .navbar-toggle {
  background: 0 0;
  display: none;
  outline: 0;
  border: 0;
  padding: 0 11px 0 0;
  text-align: right;
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

@media (max-width: 991px) {
  .inbox-wrapper .email-aside .aside-content .aside-header .navbar-toggle {
    display: block;
  }
}

.inbox-wrapper .email-aside .aside-content .aside-header .navbar-toggle .icon {
  font-size: 24px;
  color: #71738d;
}

.inbox-wrapper .email-aside .aside-content .aside-compose {
  text-align: center;
  padding: 14px 0;
}

.inbox-wrapper .email-aside .aside-content .aside-compose .btn,
.inbox-wrapper .email-aside .aside-content .aside-compose .fc .fc-button,
.fc .inbox-wrapper .email-aside .aside-content .aside-compose .fc-button,
.inbox-wrapper
  .email-aside
  .aside-content
  .aside-compose
  .swal2-modal
  .swal2-actions
  button,
.swal2-modal
  .swal2-actions
  .inbox-wrapper
  .email-aside
  .aside-content
  .aside-compose
  button,
.inbox-wrapper .email-aside .aside-content .aside-compose .wizard > .actions a,
.wizard > .actions .inbox-wrapper .email-aside .aside-content .aside-compose a {
  padding: 11px;
}

.inbox-wrapper .email-aside .aside-content .aside-nav {
  visibility: visible;
  padding: 0 0;
}

.inbox-wrapper .email-aside .aside-content .aside-nav.collapse {
  display: block;
}

@media (max-width: 991px) {
  .inbox-wrapper .email-aside .aside-content .aside-nav.collapse {
    display: none;
  }
}

@media (max-width: 991px) {
  .inbox-wrapper .email-aside .aside-content .aside-nav.show {
    display: block;
  }
}

.inbox-wrapper .email-aside .aside-content .aside-nav .title {
  display: block;
  color: #3d405c;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 20px 0 0;
  padding: 8px 14px 4px;
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li {
  width: 100%;
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  color: #71748d;
  padding: 7px 14px;
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li a:hover {
  text-decoration: none;
  background-color: rgba(114, 124, 245, 0.1);
  color: #727cf5;
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li a .icon svg {
  width: 18px;
  margin-right: 10px;
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li a .badge {
  margin-left: auto;
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li a svg {
  width: 18px;
  margin-right: 10px;
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li.active a {
  color: #ff3366;
  background: rgba(255, 51, 102, 0.1);
}

.inbox-wrapper .email-aside .aside-content .aside-nav .nav li.active a .icon {
  color: #ff3366;
}

.inbox-wrapper .email-content .email-inbox-header {
  background-color: transparent;
  padding: 18px 18px;
}

.inbox-wrapper .email-content .email-inbox-header .email-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 1rem;
}

.inbox-wrapper .email-content .email-inbox-header .email-title svg {
  width: 20px;
  margin-right: 10px;
  color: #686868;
}

.inbox-wrapper .email-content .email-inbox-header .email-title .new-messages {
  font-size: 0.875rem;
  color: #686868;
  margin-left: 3px;
}

.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .btn,
.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .fc
  .fc-button,
.fc
  .inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .fc-button,
.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .swal2-modal
  .swal2-actions
  button,
.swal2-modal
  .swal2-actions
  .inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  button,
.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .wizard
  > .actions
  a,
.wizard
  > .actions
  .inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  a {
  border-radius: 0;
  padding: 4.5px 10px;
}

.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .btn
  svg,
.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .fc
  .fc-button
  svg,
.fc
  .inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .fc-button
  svg,
.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .swal2-modal
  .swal2-actions
  button
  svg,
.swal2-modal
  .swal2-actions
  .inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  button
  svg,
.inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  .wizard
  > .actions
  a
  svg,
.wizard
  > .actions
  .inbox-wrapper
  .email-content
  .email-inbox-header
  .input-search
  .input-group-btn
  a
  svg {
  width: 18px;
}

.inbox-wrapper .email-content .email-filters {
  padding: 20px;
  border-bottom: 1px solid #e8ebf1;
  background-color: transparent;
  width: 100%;
  border-top: 1px solid #e8ebf1;
}

.inbox-wrapper .email-content .email-filters > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.inbox-wrapper .email-content .email-filters .email-filters-left .btn-group,
.inbox-wrapper
  .email-content
  .email-filters
  .email-filters-left
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .inbox-wrapper
  .email-content
  .email-filters
  .email-filters-left
  .fc-button-group,
.inbox-wrapper
  .email-content
  .email-filters
  .email-filters-left
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .inbox-wrapper
  .email-content
  .email-filters
  .email-filters-left
  .fc-button-group {
  margin-right: 5px;
}

.inbox-wrapper .email-content .email-filters .email-filters-left input {
  margin-right: 8px;
}

.inbox-wrapper .email-content .email-filters .email-filters-right {
  text-align: right;
}

@media (max-width: 767px) {
  .inbox-wrapper .email-content .email-filters .email-filters-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-indicator {
  display: inline-block;
  vertical-align: middle;
  margin-right: 13px;
}

.inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-nav
  .btn
  svg,
.inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-nav
  .fc
  .fc-button
  svg,
.fc
  .inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-nav
  .fc-button
  svg,
.inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-nav
  .swal2-modal
  .swal2-actions
  button
  svg,
.swal2-modal
  .swal2-actions
  .inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-nav
  button
  svg,
.inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-nav
  .wizard
  > .actions
  a
  svg,
.wizard
  > .actions
  .inbox-wrapper
  .email-content
  .email-filters
  .email-filters-right
  .email-pagination-nav
  a
  svg {
  width: 18px;
}

.inbox-wrapper .email-content .email-filters .be-select-all.custom-checkbox {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0 30px 0 0;
}

.inbox-wrapper .email-content .email-list .email-list-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #e8ebf1;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.inbox-wrapper .email-content .email-list .email-list-item:hover {
  background: rgba(114, 124, 245, 0.08);
}

.inbox-wrapper .email-content .email-list .email-list-item:last-child {
  margin-bottom: 5px;
}

.inbox-wrapper .email-content .email-list .email-list-item .email-list-actions {
  width: 40px;
  vertical-align: top;
  display: table-cell;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-actions
  .form-check {
  margin-bottom: 0;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-actions
  .form-check
  i::before {
  width: 15px;
  height: 15px;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-actions
  .form-check
  i::after {
  font-size: 0.8rem;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-actions
  .favorite {
  display: block;
  padding-left: 1px;
  line-height: 15px;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-actions
  .favorite
  span
  svg {
  width: 14px;
  color: #686868;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-actions
  .favorite:hover
  span {
  color: #8d8d8d;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-actions
  .favorite.active
  span
  svg {
  color: #fbbc06;
}

.inbox-wrapper .email-content .email-list .email-list-item .email-list-detail {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-detail
  .from {
  display: block;
  font-weight: 400;
  margin: 0 0 1px 0;
  color: #000;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-detail
  .msg {
  margin: 0;
  color: #71738d;
  font-size: 0.8rem;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-detail
  .date {
  color: #000;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-detail
  .date
  .icon
  svg {
  width: 14px;
  margin-right: 7px;
  color: #3d405c;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item.email-list-item--unread {
  background-color: rgba(114, 124, 245, 0.09);
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item.email-list-item--unread
  .from {
  color: #000;
  font-weight: 800;
}

.inbox-wrapper
  .email-content
  .email-list
  .email-list-item.email-list-item--unread
  .msg {
  font-weight: 700;
  color: #686868;
}

.rtl
  .inbox-wrapper
  .email-aside
  .aside-content
  .aside-header
  .navbar-toggle
  .icon {
  position: absolute;
  top: 0;
  left: 0;
}

.rtl .inbox-wrapper .email-aside .aside-content .aside-nav .nav {
  padding-right: 0;
}

.rtl .inbox-wrapper .email-aside .aside-content .aside-nav .nav li a .icon svg {
  margin-right: 0;
  margin-left: 10px;
}

.rtl .inbox-wrapper .email-aside .aside-content .aside-nav .nav li a .badge {
  margin-left: 0;
  margin-right: auto;
}

.rtl .inbox-wrapper .email-aside .aside-content .aside-nav .nav li a svg {
  margin-right: 0;
  margin-left: 10px;
}

.rtl .inbox-wrapper .email-content .email-inbox-header .email-title svg {
  margin-right: 0;
  margin-left: 10px;
}

.rtl
  .inbox-wrapper
  .email-content
  .email-inbox-header
  .email-title
  .new-messages {
  margin-left: 0;
  margin-right: 3px;
}

.rtl .inbox-wrapper .email-content .email-filters .email-pagination-indicator {
  margin-right: 0;
  margin-left: 13px;
}

.rtl
  .inbox-wrapper
  .email-content
  .email-list
  .email-list-item
  .email-list-detail
  .date
  .icon
  svg {
  margin-right: 0;
  margin-left: 7px;
}

.email-head {
  background-color: transparent;
}

.email-head-subject {
  padding: 25px 25px;
  border-bottom: 1px solid #e8ebf1;
}

@media (max-width: 767px) {
  .email-head-subject {
    padding: 25px 10px;
  }
}

.email-head-subject .title {
  display: block;
  font-size: 0.99rem;
}

.email-head-subject .title a.active .icon {
  color: #fbbc06;
}

.email-head-subject .title a .icon {
  color: silver;
  margin-right: 6px;
}

.email-head-subject .title a .icon svg {
  width: 18px;
}

.email-head-subject .icons {
  font-size: 14px;
  float: right;
}

.email-head-subject .icons .icon {
  color: #000;
  margin-left: 12px;
}

.email-head-subject .icons .icon svg {
  width: 18px;
}

.email-head-sender {
  padding: 13px 25px;
}

@media (max-width: 767px) {
  .email-head-sender {
    padding: 25px 10px;
  }
}

.email-head-sender .avatar {
  float: left;
  margin-right: 10px;
}

.email-head-sender .date {
  float: right;
  font-size: 12px;
}

.email-head-sender .avatar {
  float: left;
  margin-right: 10px;
}

.email-head-sender .avatar img {
  width: 36px;
}

.email-head-sender .sender > a {
  color: #000;
}

.email-head-sender .sender span {
  margin-right: 5px;
  margin-left: 5px;
}

.email-head-sender .sender .actions {
  display: inline-block;
  position: relative;
}

.email-head-sender .sender .actions .icon {
  color: #686868;
  margin-left: 7px;
}

.email-head-sender .sender .actions .icon svg {
  width: 18px;
}

.email-body {
  background-color: transparent;
  border-top: 1px solid #e8ebf1;
  padding: 30px 28px;
}

@media (max-width: 767px) {
  .email-body {
    padding: 30px 10px;
  }
}

.email-attachments {
  background-color: transparent;
  padding: 25px 28px 5px;
  border-top: 1px solid #e8ebf1;
}

@media (max-width: 767px) {
  .email-attachments {
    padding: 25px 10px 0;
  }
}

.email-attachments .title {
  display: block;
  font-weight: 500;
}

.email-attachments .title span {
  font-weight: 400;
}

.email-attachments ul {
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}

.email-attachments ul > li {
  margin-bottom: 5px;
}

.email-attachments ul > li:last-child {
  margin-bottom: 0;
}

.email-attachments ul > li a {
  color: #000;
}

.email-attachments ul > li a svg {
  width: 18px;
  color: #686868;
}

.email-attachments ul > li .icon {
  color: #737373;
  margin-right: 2px;
}

.email-attachments ul > li span {
  font-weight: 400;
}

.rtl .email-head-subject .title a .icon {
  margin-right: 0;
  margin-left: 6px;
}

.rtl .email-head-subject .icons .icon {
  margin-left: 0;
  margin-right: 12px;
}

.rtl .email-head-sender .avatar {
  margin-right: 0;
  margin-left: 10px;
}

.rtl .email-head-sender .sender .actions .icon {
  margin-left: 0;
  margin-right: 7px;
}

.email-head-title {
  padding: 15px;
  border-bottom: 1px solid #e8ebf1;
  font-weight: 400;
  color: #3d405c;
  font-size: 0.99rem;
}

.email-head-title .icon {
  color: #696969;
  margin-right: 12px;
  vertical-align: middle;
  line-height: 31px;
  position: relative;
  top: -1px;
  float: left;
  font-size: 1.538rem;
}

.email-compose-fields {
  background-color: transparent;
  padding: 20px 15px;
}

.email-compose-fields
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  margin: -2px -14px;
}

.email-compose-fields
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  border-radius: 0;
  background: #727cf5;
  color: #ffffff;
  margin-top: 0px;
  padding: 4px 10px;
  font-size: 13px;
  border: 0;
}

.email-compose-fields
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  span {
  color: #ffffff;
}

.email-compose-fields
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-search {
  line-height: 15px;
}

.form-group.row {
  margin-bottom: 0;
  padding: 12px 0;
}

.form-group.row label {
  white-space: nowrap;
}

.email-compose-fields label {
  padding-top: 6px;
}

.email.editor {
  background-color: transparent;
}

.email.editor .editor-statusbar {
  display: none;
}

.email.action-send {
  padding: 8px 0px 0;
}

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

.breadcrumb {
  margin: 0;
  background-color: transparent;
}

.rtl
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  float: right;
}

.rtl .btn-space {
  margin-right: 0;
  margin-left: 5px;
}
/* Ace Editor */

.ace_editor {
  margin: auto;
  height: 300px;
  width: 100%;
  font: 14px / normal SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.ace_editor .ace_content {
  font-size: 0.875rem;
}

@media (max-width: 767px) {
  .apexcharts-canvas .apexcharts-toolbar {
    display: none;
  }
}

.rtl .apexcharts-canvas {
  direction: ltr;
}

.datepicker.datepicker-dropdown {
  padding: 10px;
  width: 25%;
  max-width: 320px;
  min-width: 250px;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.datepicker.datepicker-dropdown .datepicker-days {
  padding: 0;
}

.datepicker.datepicker-dropdown .datepicker-days table.table-condensed {
  width: 100%;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th {
  text-align: center;
  padding: 0.5rem 0;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.prev {
  color: #000;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: #ffffff;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.datepicker-switch {
  color: #000;
  background: #ffffff;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.next {
  color: #000;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: #ffffff;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.dow {
  font-family: "Overpass", sans-serif;
  color: #000;
  font-size: 0.875rem;
  font-weight: initial;
}

.datepicker.datepicker-dropdown .datepicker-days table.table-condensed tbody {
  position: relative;
  top: 13px;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td {
  text-align: center;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day {
  font-size: 0.9375rem;
  padding: 0.5rem 0;
  color: #000;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day:hover {
  background: #ffffff;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.active {
  color: #fff;
  background: transparent;
  position: relative;
  z-index: 1;
  text-shadow: none;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.active:before {
  content: "";
  width: 28px;
  height: 25px;
  background: #66d1d1;
  text-shadow: none;
  border-radius: 2px;
  display: block;
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 6px;
  z-index: -1;
  left: 0;
  right: 0;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.today {
  color: #fff;
  background: transparent;
  position: relative;
  z-index: 1;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.today:before {
  content: "";
  width: 28px;
  height: 25px;
  background: #727cf5;
  color: #fff;
  border-radius: 2px;
  box-shadow: 3px 3px 6px 0 rgba(147, 127, 201, 0.2);
  -webkit-box-shadow: 3px 3px 6px 0 rgba(147, 127, 201, 0.2);
  -moz-box-shadow: 3px 3px 6px 0 rgba(147, 127, 201, 0.2);
  display: block;
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 6px;
  z-index: -1;
  left: 0;
  right: 0;
}

.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.old.day {
  color: #d9dde3;
}

.datepicker > div {
  display: initial;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border-radius: 2px;
}

.datepicker.input-group {
  padding: 0;
  border: 1px solid #e8ebf1;
}

.datepicker.input-group .form-control,
.datepicker.input-group select,
.datepicker.input-group
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .datepicker.input-group
  .select2-selection--multiple,
.datepicker.input-group .select2-container--default .select2-selection--single,
.select2-container--default .datepicker.input-group .select2-selection--single,
.datepicker.input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .datepicker.input-group
  .select2-search__field,
.datepicker.input-group .typeahead,
.datepicker.input-group .tt-query,
.datepicker.input-group .tt-hint {
  border: 0;
}

.datepicker.input-group .input-group-addon {
  padding: 0 10px;
  border-left: 1px solid #e8ebf1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.datepicker.input-group .input-group-addon svg {
  width: 18px;
  color: #686868;
}

.datepicker-dropdown:after {
  border-bottom-color: #fff;
}

.datepicker-dropdown:before {
  border-bottom-color: #e8ebf1;
}

.datepicker-dropdown.datepicker-orient-top:before,
.datepicker-dropdown.datepicker-orient-top:after {
  top: auto;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top-color: #fff;
}

.datepicker-dropdown.datepicker-orient-top:before {
  border-top-color: #e8ebf1;
}

.rtl .datepicker {
  direction: rtl;
}

.rtl .datepicker.input-group .input-group-addon {
  border-left: 1px solid transparent;
  border-right: 0;
}

@media (max-width: 767px) {
  .dataTables_wrapper.dt-bootstrap4 .dataTables_length {
    text-align: left;
  }
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_length select {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .dataTables_wrapper.dt-bootstrap4 .dataTables_filter {
    text-align: left;
    margin-left: -19px;
  }
}

.rtl .dataTables_wrapper .dataTables_filter {
  text-align: left;
}

.rtl .dataTables_wrapper .dataTables_filter input {
  margin-left: 0;
  margin-right: 0.5rem;
}

.rtl .dataTables_wrapper > div.row div[class^="col-"]:first-child {
  padding-right: 0;
}

.rtl .dataTables_wrapper > div.row div[class^="col-"]:last-child {
  padding-left: 0;
}

.dropify-wrapper .dropify-message span.file-icon {
  font-size: 0.875rem;
  color: #686868;
}

.dropify-wrapper .dropify-message span.file-icon::before {
  font-family: feather;
  content: "\e8e3";
  font-size: 24px;
}

.dropzone {
  overflow: auto;
  max-height: 200px;
  padding: 0;
}

@media (min-width: 1400px) {
  .dropzone {
    min-height: 200px;
  }
}

.dropzone.dz-clickable .dz-message {
  margin-top: 65px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .fc .fc-toolbar.fc-header-toolbar .fc-left {
    margin-bottom: 15px;
  }
  .fc .fc-toolbar.fc-header-toolbar .fc-right {
    margin-bottom: 15px;
  }
}

.fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .fc-button,
.fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .fc-button {
  background-image: none;
  text-transform: capitalize;
  text-shadow: none;
}

.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  .fc-icon::after,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  .fc-icon::after {
  font-family: feather;
  font-size: 16px;
  top: 0;
}

.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  .fc-icon.fc-icon-left-single-arrow::after,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  .fc-icon.fc-icon-left-single-arrow::after {
  content: "\e843";
}

.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  .fc-icon.fc-icon-right-single-arrow::after,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  .fc-icon.fc-icon-right-single-arrow::after {
  content: "\e844";
}

.fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button
  .fc-icon.fc-state-disabled:disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button
  .fc-icon.fc-state-disabled:disabled {
  text-transform: capitalize;
  line-height: 0;
}

.fc .fc-toolbar.fc-header-toolbar .fc-center h2 {
  font-size: 26px;
}

.fc
  .fc-view-container
  .fc-view
  table
  .fc-body
  .fc-widget-content
  .fc-day-grid-container
  .fc-day-grid
  .fc-row
  .fc-bg
  table
  td.fc-other-month {
  background-color: #f8f9fc;
}

.fc
  .fc-view-container
  .fc-view
  table
  .fc-body
  .fc-widget-content
  .fc-day-grid-container
  .fc-day-grid
  .fc-row
  .fc-bg
  table
  td.fc-today {
  background-color: #c0ecec;
}

.fc
  .fc-view-container
  .fc-view
  table
  .fc-body
  .fc-widget-content
  .fc-day-grid-container
  .fc-day-grid
  .fc-row
  .fc-content-skeleton
  table
  .fc-day-top.fc-today {
  background: 0 0;
}

.fc
  .fc-view-container
  .fc-view
  table
  .fc-body
  .fc-widget-content
  .fc-day-grid-container
  .fc-day-grid
  .fc-row
  .fc-content-skeleton
  table
  .fc-day-top.fc-today
  .fc-day-number {
  padding: 3px 7px;
  background: #727cf5;
  color: #ffffff;
  font-weight: bold;
}

.fc
  .fc-view-container
  .fc-view
  table
  .fc-body
  .fc-widget-content
  .fc-day-grid-container
  .fc-day-grid
  .fc-row
  .fc-content-skeleton
  table
  .fc-event-container
  .fc-day-grid-event {
  border-radius: 0;
  padding: 3px 0 3px 5px;
  border-width: 2px;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}

.fc
  .fc-view-container
  .fc-view
  table
  .fc-body
  .fc-widget-content
  .fc-day-grid-container
  .fc-day-grid
  .fc-row
  .fc-content-skeleton
  table
  .fc-event-container
  .fc-day-grid-event.fc-event {
  background-color: #ffe6ec;
  border-color: #ff3366;
}

.external-events .fc-event {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 2px;
  background: #f4f5f7;
  border: 0;
  border-left: 3px solid #727cf5;
  color: #686868;
  font-weight: bold;
  font-size: 13px;
}

.external-events .fc-event:nth-child(2) {
  border-left: 3px solid #fbbc06;
  background: #fffbf2;
}

.external-events .fc-event:nth-child(3) {
  border-left: 3px solid #ff3366;
  background: #fff0f4;
}

.external-events .fc-event:nth-child(4) {
  border-left: 3px solid #66d1d1;
  background: #f7fdfd;
}

.external-events .fc-event:nth-child(5) {
  border-left: 3px solid #10b759;
  background: #f1fef7;
}

.external-events .fc-event:nth-child(6) {
  border-left: 3px solid #727cf5;
  background: #f3f3fe;
}

.rtl .fc {
  text-align: right;
}

.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button:last-child,
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button:last-child {
  border-right: 1px solid;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-left
  .fc-button-group
  .fc-button:first-child:not(:last-child),
.rtl
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-right
  .fc-button-group
  .fc-button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.rtl
  .fc
  .fc-view-container
  .fc-view
  table
  .fc-body
  .fc-widget-content
  .fc-day-grid-container
  .fc-day-grid
  .fc-row
  .fc-content-skeleton
  table
  .fc-event-container
  .fc-day-grid-event {
  padding: 3px 5px 3px 0;
  border-right: 3px solid;
  border-left: 0;
}

.rtl .external-events .fc-event {
  border-left: 0;
  border-right: 3px solid #727cf5;
}

.rtl .external-events .fc-event:nth-child(2) {
  border-left: 0;
  border-right: 3px solid #fbbc06;
}

.rtl .external-events .fc-event:nth-child(3) {
  border-left: 0;
  border-right: 3px solid #ff3366;
}

.rtl .external-events .fc-event:nth-child(4) {
  border-left: 0;
  border-right: 3px solid #66d1d1;
}

.rtl .external-events .fc-event:nth-child(5) {
  border-left: 0;
  border-right: 3px solid #10b759;
}

.rtl .external-events .fc-event:nth-child(6) {
  border-left: 0;
  border-right: 3px solid #727cf5;
}

.flot-chart-example-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
}

.flot-chart-example-wrapper .flot-chart-example {
  width: 100%;
  position: relative;
  max-width: none;
  height: 400px;
}

@media (max-width: 767px) {
  .flot-chart-example-wrapper {
    height: 200px;
    min-height: 200px;
  }
  .flot-chart-example-wrapper .flot-chart-example {
    height: 100%;
  }
}

.peity-custom svg {
  margin-right: 10px;
}

.ps > .ps__rail-y {
  width: 3px;
  background-color: rgba(72, 83, 114, 0.06);
  z-index: 10;
  position: absolute;
  left: auto;
  right: 0;
  opacity: 0;
  margin: 1px;
}

.rtl .ps > .ps__rail-y {
  right: auto;
  left: 0;
}

.ps > .ps__rail-y > .ps__thumb-y {
  position: absolute;
  border-radius: 0;
  width: 3px;
  left: 0;
  background-color: #dee1e7;
}

.ps > .ps__rail-x {
  width: 3px;
  background-color: rgba(72, 83, 114, 0.06);
  z-index: 10;
  position: absolute;
  left: auto;
  right: 0;
  opacity: 0;
  margin: 1px;
}

.ps > .ps__rail-x > .ps__thumb-x {
  position: absolute;
  border-radius: 0;
  width: 3px;
  left: 0;
  background-color: #97a3b9;
}
/* Sweet Alert */

.swal2-modal {
  min-height: 300px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  border-radius: 7px;
}

.swal2-modal .swal2-header .swal2-title {
  font-size: 25px;
  line-height: 1;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

.swal2-modal .swal2-header .swal2-icon,
.swal2-modal .swal2-header .swal2-success-ring {
  margin-top: 0;
  margin-bottom: 42px;
}

.swal2-modal .swal2-header .swal2-icon {
  margin-bottom: 25px;
}

.swal2-modal .swal2-header .swal2-icon::before {
  height: 100%;
  font-size: 2.5rem;
}

.swal2-modal .swal2-actions button svg {
  width: 16px;
  height: 16px;
}

.swal2-modal .swal2-content {
  font-size: 0.875rem;
  color: #686868;
  font-weight: initial;
  margin-top: 11px;
  text-decoration: none;
}

.swal2-modal .swal2-close {
  font-size: 20px;
}

.swal2-modal .swal2-success-ring {
  left: -30px;
}
/* Select2 */

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #727cf5;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-dropdown,
.select2-container--default .select2-selection--multiple {
  border-color: #e8ebf1;
}

.select2-container--default .select2-selection--single .select2-search__field,
.select2-container--default .select2-dropdown .select2-search__field,
.select2-container--default
  .select2-selection--multiple
  .select2-search__field {
  border-color: #e8ebf1;
}

.select2-container--default .select2-selection--single {
  height: auto;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 14px;
  padding: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b::before {
  content: "\e842";
  font-family: feather;
  position: absolute;
  right: 0;
  top: -2px;
  color: #686868;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b::before {
  content: "\e845";
}

.select2-container--default .select2-dropdown {
  font-size: 0.8125rem;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: #e8ebf1;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 0.625rem;
  font-family: inherit;
  line-height: 1;
  background: #727cf5;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove {
  color: #ffffff;
}

.rtl
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: auto;
  left: 18px;
}

.CodeMirror {
  background: #fff;
  border: 1px solid #e8ebf1;
  color: #000;
}

.editor-toolbar {
  border: 1px solid #e8ebf1;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
}

.editor-toolbar a:hover {
  background: rgba(114, 124, 245, 0.2);
  border: none;
}

.editor-toolbar i.separator {
  border-left: 1px solid #e8ebf1;
  border-right: 1px solid #e8ebf1;
}
/* Tags */

div.tagsinput {
  padding: 15px 15px 10px;
  border-color: #e8ebf1;
}

div.tagsinput span.tag {
  background: #727cf5;
  border: 0;
  color: #ffffff;
  padding: 6px 14px;
  font-size: 0.8125rem;
  font-family: inherit;
  line-height: 1;
}

div.tagsinput span.tag a {
  color: #ffffff;
}

.rtl div.tagsinput span.tag,
.rtl div.tagsinput div {
  float: right;
}

.timepicker.input-group {
  padding: 0;
  border: 1px solid #e8ebf1;
  border-radius: 5px;
}

.timepicker.input-group .form-control,
.timepicker.input-group select,
.timepicker.input-group
  .email-compose-fields
  .select2-container--default
  .select2-selection--multiple,
.email-compose-fields
  .select2-container--default
  .timepicker.input-group
  .select2-selection--multiple,
.timepicker.input-group .select2-container--default .select2-selection--single,
.select2-container--default .timepicker.input-group .select2-selection--single,
.timepicker.input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .timepicker.input-group
  .select2-search__field,
.timepicker.input-group .typeahead,
.timepicker.input-group .tt-query,
.timepicker.input-group .tt-hint {
  border: 0;
  border-radius: 5px 0 0 5px;
}

.timepicker.input-group .input-group-append .input-group-text {
  padding: 0.3rem 0.6rem;
  background: #ffffff;
  border: 0;
  border-left: 2px solid #e8ebf1;
  border-radius: 0 5px 5px 0;
}

.timepicker.input-group .input-group-append .input-group-text svg {
  width: 18px;
  color: #686868;
}

.rtl .timepicker.input-group .input-group-append .input-group-text {
  border-left: 1px solid #e8ebf1;
  border-right: 1px solid #e8ebf1;
  border-radius: 5px 0 0 5px;
}

.tox.tox-tinymce {
  border: 1px solid #e8ebf1;
}

.tox.tox-tinymce .tox-editor-container .tox-menubar {
  background-color: #fff;
}

.tox.tox-tinymce .tox-editor-container .tox-toolbar {
  background-color: #fff;
  border-top: 1px solid #e8ebf1;
}

.tox.tox-tinymce .tox-editor-container .tox-toolbar .tox-toolbar__group {
  border-right: 1px solid #e8ebf1;
}

.tox.tox-tinymce .tox-editor-container .tox-sidebar-wrap .tox-edit-area {
  border-top: 1px solid #e8ebf1;
}

.tox.tox-tinymce
  .tox-editor-container
  .tox-sidebar-wrap
  .tox-edit-area
  .tox-edit-area__iframe {
  background-color: #fff;
}

.tox.tox-tinymce .tox-editor-container .tox-mbtn {
  color: #000;
}

.tox.tox-tinymce .tox-editor-container .tox-mbtn:hover,
.tox.tox-tinymce .tox-editor-container .tox-mbtn.tox-mbtn--active {
  background: #f2f2f2;
}

.tox.tox-tinymce .tox-editor-container .tox-tbtn {
  color: #000;
}

.tox.tox-tinymce .tox-editor-container .tox-tbtn svg {
  fill: #000;
}

.tox.tox-tinymce .tox-editor-container .tox-tbtn:hover,
.tox.tox-tinymce .tox-editor-container .tox-tbtn.tox-tbtn--enabled {
  background: #f2f2f2;
}

.tox.tox-tinymce .tox-editor-container .tox-split-button:hover {
  box-shadow: none;
}

.tox.tox-tinymce .tox-statusbar {
  background-color: #fff;
  border-top: 1px solid #e8ebf1;
  color: #000;
}

.tox.tox-tinymce .tox-statusbar .tox-statusbar__path-item,
.tox.tox-tinymce .tox-statusbar .tox-statusbar__wordcount {
  color: #686868;
}

.tox .tox-tiered-menu .tox-menu {
  background-color: #fff;
  border: 1px solid #e8ebf1;
}

.tox .tox-tiered-menu .tox-menu .tox-collection__group {
  border-color: #e8ebf1;
}

.tox .tox-tiered-menu .tox-menu .tox-collection__group .tox-collection__item {
  color: #000;
}

.tox
  .tox-tiered-menu
  .tox-menu
  .tox-collection__group
  .tox-collection__item.tox-collection__item--active {
  background: #f2f2f2;
}

.rtl .tox .tox-menubar,
.rtl .tox .tox-toolbar {
  direction: rtl;
}

.tt-menu,
.gist {
  text-align: left;
}

.twitter-typeahead {
  max-width: 100%;
}

.typeahead {
  background-color: #ffffff;
}

.typeahead:focus {
  border-color: #e8ebf1;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #434a54;
}

.tt-menu {
  width: 100%;
  margin: 0 0 12px 0;
  padding: 8px 0;
  background-color: #ffffff;
  border: 1px solid #e8ebf1;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: inherit;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #727cf5;
}

.tt-suggestion .tt-cursor {
  color: #ffffff;
  background-color: #727cf5;
}

.tt-suggestion p {
  margin: 0;
}

.wizard > .content {
  min-height: 20rem;
  overflow: auto;
  background: #ffffff;
  border: 1px solid #e8ebf1;
  margin-left: 0;
}

.wizard > .steps ul li a {
  border-radius: 3px;
}

.wizard > .steps ul li a,
.wizard > .steps ul li a:hover {
  padding: 0.4rem 1rem;
}

.wizard > .steps ul li a .number {
  font-size: 0.875rem;
}

.wizard > .steps ul li.disabled a,
.wizard > .steps ul li.disabled a:hover {
  background: #f2f2f2;
}

.wizard > .steps ul li.current a {
  background: #727cf5;
}

.wizard > .steps ul li.current a:hover {
  background: #5a66f3;
}

.wizard > .steps ul li.done a {
  background: #1324ee;
}

.wizard > .steps ul li.done a:hover {
  background: #0f1fd8;
}

.wizard > .steps ul:first-child a {
  margin-left: 0;
}

.wizard > .actions {
  margin-top: 12px;
}

.wizard.vertical > .content {
  margin: 0 0 0.5em 1.5%;
  width: 68%;
}

.wizard.vertical > .actions {
  margin-top: 8px;
}

.rtl .wizard > .steps > ul > li,
.rtl .wizard > .actions > ul > li {
  float: right;
}

.logobx {
  height: 40px;
  display: block;
  text-align: center;
  margin: auto;
}

img {
  max-height: 100%;
}

.slctbox .ring-inset,
.slctbox1 input,
.slctbox_bt button {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.3rem !important;
  border: 1px solid #e8ebf1 !important;
  margin-top: -4px;
}

.slctbox input,
.dropds button {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.3rem !important;
  border: 1px solid #e8ebf1 !important;
  margin-top: -4px;
  width: 100%;
}

.dropds button {
  padding: 10px 10px !important;
}

.slctbox1 .mt-1 {
  margin-top: -4px !important;
}

.slctbox1 input {
  line-height: 1.7rem;
}

.chng_crd .form-group {
  padding: 0;
}

.form_feed {
  column-gap: 10px;
  align-items: center;
}

.form_feed label input,
.form_feed label select {
  border-radius: 4px;
  background: #f6f7f8;
}

.wdt {
  width: 150px;
  height: 39px;
}

.wdt1 {
  width: 120px;
}

.show_wt {
  width: 48%;
  margin-bottom: 20px !important;
}

.show_wt .form-control,
.selctws {
  border: 1px solid #ddd;
  padding: 8px 10px;
  margin: 0;
  height: auto;
  background: #f6f7f8;
}

.mtms {
  margin-top: -8px;
}

.text_show {
  font-size: 13px;
  font-weight: 500;
}

.close_show {
  background: #e41414;
  padding: 8px 8px;
  line-height: 1px;
  border-radius: 5px;
  color: #fff !important;
  cursor: pointer;
}

.withrgt {
  width: 26%;
}

.searchin input {
  padding: 6px 10px;
  height: 34px;
}

.tbds {
  border-bottom: 1px solid #ddd;
}

.tbds li span {
  display: flex;
  font-size: 15px;
  cursor: pointer;
  padding: 13px 30px;
  align-items: center;
}

.tbds {
  border-bottom: 1px solid #ddd;
  column-gap: 10px;
  margin-left: 4px;
}

.odeven .w-full {
  padding: 10px 10px;
}

.odeven .w-full:nth-child(odd) {
  background: #f8f8f8;
}

.odeven .w-full:nth-child(even) {
  background: #eee;
}

.odeven .w-full span {
  font-weight: 600;
}

.odeven .w-full a {
  color: #1d4ed8;
}

.poptext_p {
  background: #eee;
  padding: 15px 10px;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 600;
}

.closebx button {
  position: absolute;
  right: -1.5px;
  top: -4.5px;
  background: red;
  padding: 10px 10px;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 21px;
  color: #fff;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closebx button {
  position: absolute;
  right: -1.5px;
  top: -4.5px;
  background: red;
  padding: 10px 10px;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 21px;
  color: #fff;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.borderbtm {
  border-bottom: 1px solid #ddd;
  padding-bottom: 16px;
  margin-bottom: 10px;
}

.frmds label {
  font-size: 13px;
  font-weight: 500;
}

.frmds input,
.frmds select,
.frmds textarea {
  background: #fdfdfd;
  border-radius: 5px;
  padding: 10px 10px;
  height: auto;
}

.frmds .text-danger {
  font-size: 10px;
}

.hdngbtm {
  font-weight: 500;
  font-size: 17px;
  display: block;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  color: #031a61;
}

.imgcn1 {
  text-align: center;
  margin: auto;
  border: 1px solid #ddd;
  padding: 6px;
  border-radius: 5px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnall {
  padding: 13px 45px;
  font-size: 16px;
  font-weight: 500;
}

.show_wt1 {
  width: 38%;
  margin-bottom: 20px !important;
}

.poprts {
  background: #eee;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  padding: 13px 10px;
  font-size: 18px;
  font-weight: 500;
}
/* .slc_dvd {
            align-items: center;
            column-gap: 10px;
            width: 220px;
        } */

.slc_dvd label {
  margin: 0;
  white-space: nowrap;
}

.slc_dvd .relative {
  width: 100%;
}

.slc_dvd .relative button span,
.slc_dvd .relative ul {
  text-transform: capitalize;
}

.dntt canvas {
  height: 200px !important;
  width: 200px !important;
  margin: auto;
}

.wdht {
  width: 70px;
  height: 70px;
  padding: 13px;
}

.whbox_social {
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-right .relative div > button:after {
  -webkit-animation: ping 1s ease-in-out infinite both;
  animation: ping 1s ease-in-out infinite both;
  background: #f3be07;
  position: absolute;
  left: -6px;
  right: 0;
  top: -6px;
  bottom: 0;
  content: "";
  width: 60px;
  height: 60px;
  border: 1px solid #000;
  border-radius: 50%;
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

.select_mlt
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: auto !important;
  padding: 11px !important;
  box-shadow: none;
}

.select_mlt .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: #343434;
  font-size: 14px;
  font-weight: 500;
}

.inputp::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
}

.inputp::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}

.inputp:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
}

.inputp:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}

.imgrs {
  height: 100px;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 6px;
  border-radius: 5px;
}

.imgrs1 {
  height: 70px;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 6px;
  border-radius: 5px;
}

.timelinebox > li {
  position: relative;
}

.timelinebox > li::before,
.timelinebox > li::after {
  position: absolute;
  content: "";
  border-radius: 50px;
}

.timelinebox > li::before {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #ddd;
  left: -11px;
}

.timelinebox > li::after {
  width: 12px;
  height: 12px;
  background: #0050e3;
  border: 1px solid #0050e3;
  left: -7px;
  top: 4px;
}

.circlebox {
  width: 150px;
  height: 150px;
  padding: 10px;
  border-radius: 50%;
  overflow: hidden;
  border: 8px solid #f6f6f6;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: auto;
}

.dashed_br {
  margin-top: 10px;
  justify-content: center;
  padding: 8px 3px;
  border: 1px dashed #ddd;
}

.box_gct {
  border-radius: 10px;
  padding: 20px 10px;
  text-align: center;
}

.bg_g1 {
  background: #ffefef;
}

.bg_g2 {
  background: #fffbef;
}

.bg_g3 {
  background: #effdff;
}

.bg_g4 {
  background: #fff1d7;
}

.circle_gft {
  width: 75px;
  height: 75px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto auto 5px auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
}

.box_gct > span {
  text-align: center;
  display: block;
  font-weight: 600;
  text-transform: capitalize;
}

.brdergp {
  width: 90%;
  margin: auto;
}

.brdergp > li {
  border-right: 1px solid #fff;
  width: 25%;
  display: block;
}

.brdergp > li:last-child {
  border-right: none;
}

.dropar {
  position: relative;
}

.dropar::before {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #f3f4f6;
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  margin: auto;
  bottom: -18px;
}

.max_h {
  max-height: 500px;
}

.hmsl {
  max-height: 300px;
}

.odsbox tbody:nth-child(odd) tr {
  background: #fafafa;
}

.odsbox {
  max-height: 250px;
}

.heightb {
  height: 57px;
}

.bg1 {
  background: #004ede;
}

.bg2 {
  background: #f66b4e;
}

.bg3 {
  background: #1ca2d5;
}

.bg4 {
  background: #00a54d;
}

.bg5 {
  background: #f71049;
}

.bg6 {
  background: #06163a;
}

.bg7 {
  background: #059669;
}

.bg8 {
  background: #5b21b6;
}

.bg9 {
  background: #9d174d;
}

.bg10 {
  background: #854d0e;
}

.res-img {
  max-width: 100%;
  max-height: 100%;
}

.qrwidth {
  width: 50px;
  height: 50px;
}

.widthbox_chart {
  height: 380px;
  margin: auto;
  text-align: center;
}

.widthbox_chart canvas {
  margin: auto;
}

.fntchng > span {
  font-size: 12px;
  display: block;
  font-weight: 400;
  margin-top: 2px;
}

.datepd {
  padding: 7px 6px;
}

.errormsg > p {
  text-align: center;
  border: 1px dashed #d70000;
  display: block;
  padding: 20px;
  color: #d70000;
  border-radius: 10px;
  box-shadow: 0px 5px 5px #ddd;
  word-break: break-all;
}

:root {
  --primary: #fff;
  --secondary: #aaaaaa;
}
/* Scrollbar styles */
/* Firefox */

.scrl {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--secondary);
}
/* Chrome, Edge, and Safari */

.scrl::-webkit-scrollbar {
  width: 12px;
}

.scrl::-webkit-scrollbar-track {
  background: var(--primary);
}

.scrl::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  /*   background: repeating-linear-gradient(
    45deg,
    var(--secondary),
    var(--secondary) 5px,
    var(--primary) 5px,
    var(--primary) 10px
  ); */
  border-radius: 20px;
  border: 3px solid var(--primary);
}

.topslt .searchin button {
  padding: 6px;
  box-shadow: inherit !important;
  background: #fff;
}

.pdbtn_all {
  height: 33px;
}

.flextop_d {
  display: flex;
  column-gap: 8px;
  justify-content: center;
  padding: 0 10px;
  background: #eee;
  padding-bottom: 10px;
}
.flextop_d label {
  color: #000;
  font-size: 13px;
}
.flextop_d .datepd {
  width: 160px;
  height: 33px !important;
  padding: 4px 10px !important;
}

.flextop_d input {
  height: 33px !important;
  padding: 3px 10px !important;
}

.pophd {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #0d46ed;
  padding-bottom: 10px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #0d46ed;
}

.tablepop tr th,
.tablepop tr td {
  text-transform: capitalize;
  padding: 10px 10px;
}
.tablepop tr th {
  background: #eee;
  font-size: 14px;
}
.tablepop tr td {
  font-size: 13px;
}

.tbchnge .css-1wf8b0h-MuiTabs-flexContainer {
  justify-content: start;
  overflow-y: auto;
}

.tbchnge .css-1wf8b0h-MuiTabs-flexContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}

.tbchnge .css-1wf8b0h-MuiTabs-flexContainer::-webkit-scrollbar:vertical {
  width: 11px;
}

.tbchnge .css-1wf8b0h-MuiTabs-flexContainer::-webkit-scrollbar:horizontal {
  height: 8px;
}

.tbchnge .css-1wf8b0h-MuiTabs-flexContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.tbchnge .css-1aquho2-MuiTabs-indicator {
  left: 0;
}
.tbchnge .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 13px;
  padding: 12px 11px;
}
.manualr_50 {
  width: 50%;
  margin-bottom: 10px;
}
.manualr_50 input {
  padding: 10px 10px !important;
}
.rewardbox_s {
  margin-top: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.rewardbox_s > h3 {
  background: #cbe7ff;
  padding: 10px 10px;
  border-radius: 5px 5px 0px 0px;
  font-weight: 600;
  color: #252525;
}
.listreward {
  padding: 5px 10px;
}
.listreward > span {
  display: block;
  border-bottom: 1px solid #ddd;
  padding: 10px 0px;
  font-size: 13px;
  font-weight: 400;
}
.listreward > span:last-child {
  border-bottom: none;
}

.txthome h1{    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }
.txthome span{    display: block; text-align: right;}

@media only screen and (max-width: 768px) {
.md100{width:100% !important;}
.md-pd0{padding: 0 !important;}
.bl0{border-left: 0 !important;}
.xspd-2{padding: 20px !important;}
.sm-block,.sm-flex{display: block !important;}
.mdpd8{padding:8px;}
}